import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, Fragment, useEffect } from "react";
import { BiCheck } from 'react-icons/bi';
import { BsArrowDown, BsArrowUp, BsInfoCircle } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Button, Card, Checkbox, Spinner, TextInput } from '../../../../components';
import tacoConstants from "../../../../constants/taco/index";
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import wrapProviderHOC from "../../../../layouts/WrapProvider";
import { combzStore } from "./controller";

interface Props {
    session: any;
    member: any;
}
const wait = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

const CombzScreen: FC<Props> = observer(({ ...props }) => {
    const store: any = getSnapshot(combzStore);
    useEffect(() => {
        if (props?.session) {
            combzStore.markLoading(true);
            combzStore.loadCfgData().then(() => {
                // combzStore.loadUserData("wp.ba.wam");
                combzStore.loadUserData(String(props.session.actor));
            });
            if (props?.member?.isActive) {
                combzStore.setIsActiveMember(true);
            } else {
                combzStore.setIsActiveMember(false);
            }
        }

    }, [props.session, props.member.isActive]);
    let isAuto: boolean = store.isAuto;

    useEffect(() => {
        let intervalId: any;
        let working = false;
        if (props?.session) {

            const startCronJob = () => {
                intervalId = setInterval(async () => {
                    if (!working) {
                        // Your cron job logic goes here
                        working = true;
                        // console.log("run auto process")
                        await combzStore.autoCombz(props.session);
                        await wait(15000);
                        working = false;
                    } else {
                        // console.log("Skip");
                    }
                }, 5000); // Adjust the interval as needed (e.g., 1000 ms = 1 second)
            };

            if (isAuto) {
                startCronJob();
            } else {
                clearInterval(intervalId);
            }

        }
        return () => {
            clearInterval(intervalId); // Clean up the interval when the component unmounts
        };
    }, [isAuto, props.session, props.member.isActive]);

    useEffect(() => {

        // Start the countdown only once when the component mounts
        if (!store.countdown) {
            combzStore.setEndsIn();
        }

    }, []);

    let isLoading: boolean = store.isLoading;
    let user: any = store.user;
    let ONI_balance: number = store.ONI_balance;
    let chainfields: any = store.chainfields;
    let spacecombs: any = store.spacecombs;
    let log: string = store.log;
    let isAutoCollectFreeRes: boolean = store.isAutoCollectFreeRes;
    let is_all_auto_production: boolean = store.is_all_auto_production;
    let is_all_auto_sponsor: boolean = store.is_all_auto_sponsor;
    let is_all_auto_repair: boolean = store.is_all_auto_repair;
    let min_tx_delay_seconds: number = store.min_tx_delay_seconds;
    let max_tx_delay_seconds: number = store.max_tx_delay_seconds;


    return (
        <SecondaryRightContent>
            <div className="">
                <p className='text-2xl text-yellow-400 font-bold  text-center m-1 mb-6'>Combz Auto</p>
                <div className='flex flex-row mb-2 '>
                    <div className="flex-none w-10"></div>
                    <div className='flex-1'>
                        {props?.session && (<Button
                            className=''
                            disabled={isLoading}
                            color={!isAuto ? "info" : "failure"}
                            onClick={() => {
                                combzStore.setIsAuto(!isAuto);
                            }}
                        >{!isAuto ? "Start" : "Stop"}</Button>)}
                        {isAuto && (<div className='place-self-center ml-2 text-gray-700 dark:text-white'>{log}</div>)}
                    </div>
                    <div className='grow'></div>
                    <div className='flex-none'>
                        {props?.session && (
                            <div className="flex flex-row">

                                <div className="flex items-center mr-4">
                                    <img src={require("../../../../resources/images/taco/combz/oni.png")} alt="ONI" className="w-12 h-12" />
                                    <div className='flex flex-col ml-1'>
                                        <span className="text-pink-500  ">ONI</span>
                                        <span className="text-stone-50  font-bold">{ONI_balance}</span>
                                    </div>
                                </div>

                                <div className="flex items-center mr-4">
                                    <img src={require("../../../../resources/images/taco/combz/hyperium.png")} alt="HYPER" className="w-12 h-12" />
                                    <div className='flex flex-col ml-1'>
                                        <span className="text-orange-500  ">HYPERIUM</span>
                                        <span className="text-stone-50  font-bold">{(user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.HYPERIUM })
                                            ? user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.HYPERIUM }).value : 0)}</span>
                                    </div>
                                </div>

                                <div className="flex items-center mr-4">
                                    <img src={require("../../../../resources/images/taco/combz/oxenorite.png")} alt="OXEN" className="w-12 h-12" />
                                    <div className='flex flex-col ml-1'>
                                        <span className="text-lime-500  ">OXENORITE</span>
                                        <span className="text-stone-50  font-bold">{(user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.OXENORITE })
                                            ? user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.OXENORITE }).value : 0)}</span>
                                    </div>
                                </div>

                                <div className="flex items-center mr-4">
                                    <img src={require("../../../../resources/images/taco/combz/novacomb.png")} alt="NOVA" className="w-12 h-12" />
                                    <div className='flex flex-col ml-1'>
                                        <span className="text-cyan-500  ">NOVACOMB</span>
                                        <span className="text-stone-50  font-bold">{(user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.NOVACOMB })
                                            ? user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.NOVACOMB }).value : 0)}</span>
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <img src={require("../../../../resources/images/taco/combz/hon.png")} alt="HON" className="w-12 h-12" />
                                    <div className='flex flex-col ml-1'>
                                        <span className="text-amber-200  ">HON</span>
                                        <span className="text-stone-50  font-bold">{(user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.HON })
                                            ? user.materials.find((item: any) => { return item.material_id === tacoConstants.COMBZ.MATERIAL_IDS.HON }).value : 0)}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-none w-10"></div>
                </div>

                {props?.session ? (
                    <div className="flex ">
                        <div className="flex-none w-10">

                        </div>
                        <Card className={`grow `}>
                            <div className="flex flex-row border-b-2 mb-1 mt-1 items-center">
                                <div className="text-lg text-gray-700 dark:text-teal-200 font-bold ">
                                    Your Chainfields
                                </div>
                                <div className='grow'></div>
                                <div className='flex flex-row items-center mr-4 relative group'>
                                    <div className="relative inline-block">
                                        <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50 top-[200%] left-0 ">
                                            The system will automatically add a random time delay between min and max value (seconds) to each transaction. Default is no delay.
                                        </div>
                                    </div>
                                    <div className='text-lg text-gray-700 dark:text-teal-200 font-bold ml-1'>
                                        Tx delay (s):
                                    </div>

                                    <TextInput
                                        className='ml-2 w-24'
                                        disabled={isAuto}
                                        size={4}
                                        placeholder="min"
                                        value={min_tx_delay_seconds}
                                        type='number'
                                        classNameInputText="text-center"
                                        onChange={(e) => {
                                            if (e.target.value && parseInt(e.target.value) >= 0) {
                                                combzStore.setMinTxDelay(parseInt(e.target.value));
                                            }
                                        }}
                                    />

                                    <TextInput
                                        className='ml-2 w-24'
                                        disabled={isAuto}
                                        size={4}
                                        placeholder="max"
                                        value={max_tx_delay_seconds}
                                        type='number'
                                        classNameInputText="text-center"
                                        onChange={(e) => {
                                            if (e.target.value && parseInt(e.target.value) >= 0) {
                                                combzStore.setMaxTxDelay(parseInt(e.target.value));
                                            }
                                        }}
                                    />

                                </div>
                                <div className='text-lg text-gray-700 dark:text-teal-200 font-bold '>
                                    <Checkbox
                                        disabled={isAuto}
                                        className='mr-2'
                                        checked={isAutoCollectFreeRes}
                                        onChange={(v) => {
                                            combzStore.setAutoCollectFreeRes(v.target.checked)
                                        }}
                                    />
                                    Auto Collect Free Resources
                                </div>
                            </div>
                            {isLoading ? <Spinner color={"success"} /> : (<table className="table-auto text-left text-gray-700 dark:text-white border border-dashed overflow-x: auto">
                                <thead >
                                    <tr>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Priority</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed relative group'>
                                            <Checkbox
                                                disabled={isAuto}
                                                className='mr-2'
                                                checked={is_all_auto_production}
                                                onChange={(v) => {
                                                    combzStore.setAutoProduction(-1, v.target.checked);
                                                }}
                                            />
                                            Production
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50 left-[-1000%]">
                                                    Tick to active.
                                                    Production Tank will be filled automatically base on your Duration settings.
                                                </div>
                                            </div>
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Label</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed '>Level</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Slots</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Usage</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed relative group'>MOT (H)
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Max Operation Time By Hours
                                                </div>
                                            </div>
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Status</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Ends In</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Spacecombs</th>
                                        {/* <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed relative group'>
                                            Repair Cost
                                        </th> */}
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed relative group'>
                                            Duration
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Capped by MOT. Set to 0 to run full MOT.
                                                </div>
                                            </div>
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed relative group'>
                                            ONI Boost
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    0.1 - 5
                                                    <br />
                                                    Only apply if you have enough ONI
                                                </div>
                                            </div>
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed relative group'>
                                            <Checkbox
                                                disabled={isAuto}
                                                className='mr-2'
                                                checked={is_all_auto_sponsor}
                                                onChange={(v) => {
                                                    combzStore.setAutoSponsor(-1, v.target.checked);
                                                }}
                                            />
                                            Sponsor
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Auto select optimal sponsor. (Beta testing, please use with caution)
                                                </div>
                                            </div>

                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed relative group'>
                                            <Checkbox
                                                disabled={isAuto}
                                                className='mr-2'
                                                checked={is_all_auto_repair}
                                                onChange={(v) => {
                                                    combzStore.setAutoRepair(-1, v.target.checked);
                                                }}
                                            />
                                            Repair
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50 left-[-200%]">
                                                    Chainfield will be repaired automatically when it reaches max usage.
                                                </div>
                                            </div>

                                        </th>

                                        <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Last Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chainfields.map((item: any, index: any) => {
                                        return (
                                            <tr key={item.asset_id}>
                                                <td >
                                                    <button
                                                        disabled={isAuto}
                                                        type="button"
                                                        className="-m-2 p-2 text-lime-500 ml-0"
                                                        onClick={() => { combzStore.moveChainfieldUp(index); }}
                                                    >
                                                        <BsArrowUp className="h-6 w-6" />
                                                    </button>
                                                    <button
                                                        disabled={isAuto}
                                                        type="button"
                                                        className="-m-2 p-2 text-rose-500"
                                                        onClick={() => { combzStore.moveChainfieldDown(index); }}
                                                    >
                                                        <BsArrowDown className="h-6 w-6" />
                                                    </button>
                                                </td>
                                                <td >
                                                    {(!props.member?.isActive && index !== 0) ?
                                                        (<span className='text-red-400 ml-1 relative group'>Disabled
                                                            <div className="w-32 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                                Only the first chainfield is available for free. Please upgrade your membership to use this feature.
                                                            </div>
                                                        </span>) : (<Checkbox
                                                            disabled={isAuto || (!props.member?.isActive && index !== 0)}
                                                            checked={item.is_auto_production}
                                                            onChange={(v) => {
                                                                if (item.spacecombs.length > 0) {
                                                                    combzStore.setAutoProduction(index, v.target.checked);
                                                                } else {
                                                                    toast.warn(`Please select at least one spacecomb`, {
                                                                        position: "top-right",
                                                                        autoClose: 2000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                        theme: "dark",
                                                                    });
                                                                }
                                                            }}
                                                            name="auto-upgrade"
                                                            className='ml-2'
                                                        ></Checkbox>)}

                                                </td>
                                                <td >
                                                    {item.metadata.label} #{item.template_mint}
                                                </td>
                                                <td>{item.metadata.level}</td>
                                                <td>{item.metadata.slots.length}</td>
                                                <td>{item.uses}/{item.metadata.max_usage}</td>
                                                <td>{item.metadata.max_operating_time / 3600}</td>
                                                <td>
                                                    <span className='text-red-400 ml-1 relative group'>{item.work.chainfield_id !== 0 ? (new Date(item.work.end) > new Date(Date.now()) ? (<span className='text-green-400'>Active</span>) : (<span className='text-sky-400'>Claimable</span>)) : (<span className='text-yellow-400'>Inactive</span>)}
                                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                            Start: {new Date(item.work.start).toLocaleDateString() + " " + new Date(item.work.start).toLocaleTimeString()}
                                                            <br />
                                                            End: {new Date(item.work.end).toLocaleDateString() + " " + new Date(item.work.end).toLocaleTimeString()}
                                                        </div>
                                                    </span>
                                                </td>
                                                <td className='w-28'>
                                                    {item.ends_in}
                                                </td>
                                                <td >
                                                    <Menu as="div" className="relative inline-block text-left" >
                                                        <div>
                                                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-700 px-3 py-2 text-sm font-semibold text-white-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-500"
                                                            >
                                                                Options
                                                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-white-500" aria-hidden="true" />
                                                            </Menu.Button>
                                                        </div>

                                                        <Transition
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 max-h-72 overflow-y-auto origin-top-right rounded-md bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                {spacecombs.length > 0 ? (
                                                                    <div >
                                                                        {/* <div className='divide-y divide-red-300'></div> */}
                                                                        {item.work.chainfield_id !== 0 ? (
                                                                            <div >
                                                                                {/* If Chainfield is active => only show attached spacecombs and not able to select*/}
                                                                                {spacecombs.filter((spacecomb: any) => {
                                                                                    return spacecomb.work.chainfield_id === item.asset_id;
                                                                                }).sort((a: any, b: any) => { return a.metadata.level - b.metadata.level }).map((spacecomb: any, spacecomb_index: any) => {
                                                                                    return (
                                                                                        <Menu.Item key={`option-${spacecomb_index}`}>
                                                                                            {({ active }) => (
                                                                                                <div className={`${`text-white-500 ${spacecomb.metadata.level === 1 ? 'bg-gray-600' : spacecomb.metadata.level === 2 ? 'bg-sky-600' : spacecomb.metadata.level === 3 ? 'bg-yellow-600 ' : 'bg-fuchsia-600'}`
                                                                                                    } flex flex-row group flex rounded-md items-center w-full px-2 py-2 text-sm`}>
                                                                                                    <div>
                                                                                                        {spacecomb.metadata.label}
                                                                                                    </div>
                                                                                                    <div className='grow'></div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        ) : (
                                                                            <div > {/* If Chainfield is inactive => show not working spacecombs and able to select */}
                                                                                {spacecombs.filter((spacecomb: any) => {
                                                                                    return !spacecomb.working && spacecomb?.operating_time < spacecomb.metadata.max_operating_time;
                                                                                }).sort((a: any, b: any) => { return a.metadata.level - b.metadata.level }).map((spacecomb: any, spacecomb_index: any) => {
                                                                                    return (
                                                                                        <Menu.Item key={`option-${spacecomb_index}`} >
                                                                                            {({ active }) => (
                                                                                                <div className={`${active ? 'bg-blue-500 text-white' : `text-white-500 ${spacecomb.metadata.level === 1 ? 'bg-gray-600' : spacecomb.metadata.level === 2 ? 'bg-sky-600' : spacecomb.metadata.level === 3 ? 'bg-yellow-600 ' : 'bg-fuchsia-600'}`
                                                                                                    } flex flex-row group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                                                                                    onClick={() => { if (!isAuto) { combzStore.selectSpaceComb(index, spacecomb) } }}>
                                                                                                    <div>
                                                                                                        {spacecomb.metadata.label}
                                                                                                    </div>
                                                                                                    <div className='grow'></div>
                                                                                                    {/* only show Bicheck if spacecomb asset_id is in item.spacecomb under id key */}
                                                                                                    {item.spacecombs.find((ins: any) => {
                                                                                                        return ins.id === spacecomb.asset_id;
                                                                                                    }) && (< BiCheck className='self-center' />)}
                                                                                                </div>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    )
                                                                                })}</div>
                                                                        )}




                                                                    </div>) : (<div className="py-1 opacity-50">No Available Spacecomes</div>)}

                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </td>
                                                {/* <td>
                                                    {item.metadata.repair_cost}
                                                </td> */}

                                                <td className='w-24'>
                                                    <div className=" items-center">
                                                        <TextInput
                                                            disabled={isAuto}
                                                            size={4}
                                                            placeholder="0"
                                                            value={item.duration}
                                                            type='number'
                                                            classNameInputText="text-center"
                                                            onChange={(e) => {
                                                                if (parseInt(e.target.value)) {
                                                                    if (parseInt(e.target.value) > item.metadata.max_operating_time / 3600) {
                                                                        combzStore.setDuration(index, item.metadata.max_operating_time / 3600);

                                                                    } else if (parseInt(e.target.value) <= 0) {
                                                                        combzStore.setDuration(index, 0);
                                                                    }
                                                                    else {
                                                                        combzStore.setDuration(index, parseInt(e.target.value));
                                                                    }
                                                                } else {
                                                                    combzStore.setDuration(index, 0);
                                                                }

                                                            }}
                                                        />
                                                    </div>

                                                </td>

                                                <td className='w-32'>
                                                    <div className=" items-center">
                                                        <TextInput
                                                            disabled={isAuto}
                                                            size={4}
                                                            placeholder="0"
                                                            value={item.boost_amount / 10000}
                                                            type='number'
                                                            classNameInputText="text-center"
                                                            onChange={(e) => {
                                                                // value from 0.1 to 5
                                                                if (parseFloat(e.target.value)) {
                                                                    if (parseFloat(e.target.value) > 5) {
                                                                        combzStore.setBoostAmount(index, 5);

                                                                    } else if (parseFloat(e.target.value) <= 0) {
                                                                        combzStore.setBoostAmount(index, 0);
                                                                    }
                                                                    else {
                                                                        combzStore.setBoostAmount(index, parseFloat(e.target.value));
                                                                    }
                                                                } else {
                                                                    combzStore.setBoostAmount(index, 0);
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                </td>

                                                <td >
                                                    <Checkbox
                                                        disabled={isAuto}
                                                        checked={item.is_sponsor_boost}
                                                        onChange={(v) => {
                                                            combzStore.setAutoSponsor(index, v.target.checked);
                                                        }}
                                                        name="auto-sponsor"
                                                        className='ml-2'
                                                    ></Checkbox>
                                                </td>
                                                <td >
                                                    <Checkbox
                                                        disabled={isAuto}
                                                        checked={item.is_auto_repair}
                                                        onChange={(v) => {
                                                            combzStore.setAutoRepair(index, v.target.checked);
                                                        }}
                                                        name="auto-upgrade"
                                                        className='ml-2'
                                                    ></Checkbox>
                                                </td>

                                                <td>
                                                    {item.last_tx ? (<a className='text-green-400 ml-1' href={`https://wax.bloks.io/transaction/${item.last_tx}`} target='_blank' rel="noreferrer" >{item.last_action} (Tx)</a>) : (<span className='text-red-400 ml-1'>No action</span>)}

                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {chainfields.length === 0 && (
                                        <tr>
                                            <td colSpan={15} className="text-center text-gray-700 dark:text-white">
                                                No Chainfields
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>)}
                        </Card>

                        <div className="flex-none w-10">

                        </div>

                    </div>) :
                    (<div className="flex justify-center items-center h-96 text-gray-700 dark:text-white">
                        Please login to use this feature
                    </div>)}


            </div>
        </SecondaryRightContent >
    );
});

const Combz = wrapProviderHOC(CombzScreen);
export { Combz };

