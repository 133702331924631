import { types } from "mobx-state-tree";

export const Item = types.model("Item", {
    name: types.string,
    full_name: types.string,
    type: types.string,
    level: types.number,
    upgrade_cost: types.string,
    start_at: types.Date,
    finish_at: types.Date,
    is_auto_upgrade: types.boolean,
    claim_frequency: types.number,
    last_claim_at: types.Date,
    last_tx: types.string,
    last_action: types.string,
    is_auto_claim: types.boolean,
    fails: types.number,
    pause_upgrade_until: types.Date,
    pause_minutes: types.number, // default 60 mins
    cap_upgrade_level: types.number
})
