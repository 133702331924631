import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Card } from '../../components';
import { DefaultContent } from "../../layouts/DefaultContent";

const data = [
    {
        id: "mars",
        label: "Mars Engine",
        icon: "AiFillDashboard",
        link: "/mars",
        content: "",
        logo_name: "mars"
    },
    {
        id: "sellbot",
        label: "NFT Auto Seller",
        icon: "AiFillDashboard",
        link: "/sellbot",
        content: "",
        logo_name: "atomichub"
    },
    {
        id: "parsec",
        label: "Parsec Game",
        icon: "AiFillDashboard",
        link: "/parsec",
        content: "",
        logo_name: "parsec"
    },
    {
        id: "taco-kiosks",
        label: "Taco Kiosks",
        icon: "AiFillDashboard",
        link: "/taco/kiosks",
        content: "",
        logo_name: "taco"

    },
    {
        id: "taco-combz",
        label: "Taco Combz",
        icon: "AiFillDashboard",
        link: "/taco/combz",
        content: "",
        logo_name: "combz"

    }, {
        id: "farmingtales",
        label: "Farming Tales",
        icon: "AiFillDashboard",
        link: "/farmingtales",
        content: "",
        logo_name: "farming-tales"
    },
    {
        id: "waximus",
        label: "Subscription",
        icon: "AiFillDashboard",
        link: "/prime-membership",
        content: "",
        logo_name: "waximus"
    },
]
const DashBoard: React.FC = React.memo(() => {
    const navigate = useNavigate();

    return <DefaultContent>


        <div className="grid grid-cols-1 md:grid-cols-6 gap-12">
            {data.map((item, index) => {
                return <Link
                    key={index}
                    className="lg:max-w-sm relative"
                    to={item.link}
                >
                    <Card
                        imgSrc={require(`../../resources/images/project/${item.logo_name}.png`)}
                    >
                        <div className="text-lg text-gray-700 dark:text-white font-bold text-center">
                            <div>
                                <span
                                    aria-hidden="true"
                                    className="absolute inset-0"
                                ></span>
                                {item.label}
                            </div>
                            <div></div>
                        </div>
                    </Card>
                </Link>
            })}
        </div>
    </DefaultContent >
})
export {
    DashBoard
};

