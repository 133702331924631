const farmingtales = {
    FARMINGGAMES: "farminggames",
    FARMINGGAME2: "farminggame2",
    FARMINGGAME3: "farminggame3",
    USERS_TABLE_GAME2: "users",
    RECIPES_TABLE_GAME2: "recipes",
    BUILDINGS_TABLE_GAME2: "buildings",
    CONFCHEST_TABLE_GAME3: "confchest",
    CONFTASK_TABLE_GAME3: "conftask",
    ANIMAL_TABLE_GAME1: "animal",
    BUILDOUT_TABLE_GAME1: "buildout",
    FLOWER_TABLE_GAME1: "flower",
    PLANT_TABLE_GAME1: "plant",
    CONFANIMAL_TABLE_GAME1: "confanimal",
    CONFBUILDOUT_TABLE_GAME1: "confbuildout",
    CONFFLOWER_TABLE_GAME1: "confflower",
    CONFPLANT_TABLE_GAME1: "confplant",
    RESOURCES_TABLE_GAME1: "resources",
    WALLET_TABLE_GAME1: "wallet",
    CONFRESOURCE_TABLE_GAME1: "confresource",
    PRODUCTS_TABLE_GAME2: "products",
};
export default farmingtales;