import { values } from "mobx";
import { cast, flow, types } from "mobx-state-tree";
import ftConstants from "../../../constants/farmingtales/index";
import constants from "../../../constants/index";
import { getName } from "../../../constants/farmingtales/products";
import { getAllTableRows, getTableRow } from "../../../services/api";
import { BarnProduct } from "../domain/barnProduct";
import { ConfChest } from "../domain/confChest";
import { Ingredient } from "../domain/ingredient";
import { ModalInfo } from "../domain/modalInfo";
import { Recipe } from "../domain/recipe";
import { Task } from "../domain/task";

const FarmingTales = types
    .model("FarmingTales", {
        isLoading: true,
        barnProducts: types.array(BarnProduct),
        recipes: types.array(Recipe),
        productRecipes: types.array(Recipe),
        confChest: types.array(ConfChest),
        tasks: types.array(Task),
        sellList: types.array(Ingredient),
        modalInfo: ModalInfo
    })
    .views((self) => ({
        get listBarnProducts() {
            console.log("self.barnProducts", self.barnProducts);
            return values(self.barnProducts)
        },
        get listRecipes() {
            console.log("self.recipes", self.recipes);
            return values(self.recipes)
        }
    }))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        function updateBarnProducts(products: any) {
            let confchest = self.confChest;
            let wood_value = products.find((product: any) => product.key === "wood")?.value || 0;
            for (let i = 0; i < products.length; i++) {
                products[i].sell = false;
                products[i].craft1 = false;
                products[i].craft2 = false;
                products[i].craft3 = false;
                let recipe_index = self.recipes.findIndex((recipe) => recipe.result === products[i].key)
                if (recipe_index !== -1) {
                    products[i].sell = true;
                }
                let confchest_index = confchest.findIndex((conf: any) => conf.product === products[i].key)
                if (confchest_index !== -1) {
                    if (products[i].value >= confchest[confchest_index].allowed_quantity[0] && wood_value >= confchest[confchest_index].wood_cost[0]) {
                        products[i].craft1 = true;
                    }
                    if (products[i].value >= confchest[confchest_index].allowed_quantity[1] && wood_value >= confchest[confchest_index].wood_cost[1]) {
                        products[i].craft2 = true;
                    }
                    if (products[i].value >= confchest[confchest_index].allowed_quantity[2] && wood_value >= confchest[confchest_index].wood_cost[2]) {
                        products[i].craft3 = true;
                    }
                }
            }
            self.barnProducts = products;
        }

        function updateRecipes(recipes: any, buildings: any) {
            let newRecipes = [];
            for (let recipe of recipes.rows) {
                let building = buildings.rows.find((value: any) => {
                    return value.recipes.includes(recipe.result);
                });
                if (!building) {
                    continue;
                    // newRecipes.push({
                    //     result: recipe.result,
                    //     ingredients: cast(recipe.ingredients),
                    //     building_asset_id: -1,
                    // });
                } else {
                    newRecipes.push({
                        result: recipe.result,
                        ingredients: cast(recipe.ingredients),
                        building_asset_id: Number(building.asset_id),
                    });
                }
            }

            let productRecipes: any = []
            for (let recipe of newRecipes) {
                let hasProduct = true;
                for (let ingredient of recipe.ingredients) {
                    let prod_index = self.barnProducts.findIndex((value: any) => {
                        return value.key === ingredient.key;
                    })
                    if (prod_index === -1) {
                        hasProduct = false;
                        break;
                    }
                }
                if (hasProduct) {
                    productRecipes.push(recipe);
                }
            }

            self.recipes = cast(newRecipes);
            self.productRecipes = cast(productRecipes);
            updateBarnProducts(self.barnProducts);
        }

        function updateConfChest(confchest: any) {
            self.confChest = confchest;
        }

        function updateTasks(tasks: any) {
            tasks = tasks.map((task: any) => {
                return {
                    task: task.task,
                    label: task.label,
                    requirements: task.requirements,
                }
            });

            self.tasks = cast(tasks);
        }

        const loadBarnProducts = flow(function* (account: string) {
            try {
                console.log("loadBarnProducts");
                const json = yield getTableRow({
                    table: ftConstants.USERS_TABLE_GAME2,
                    code: ftConstants.FARMINGGAME2,
                    scope: ftConstants.FARMINGGAME2,
                    lower_bound: account
                })

                if (json.rows.length > 0) {
                    updateBarnProducts(json.rows[0].products)
                }
                markLoading(false)
            } catch (err) {
                console.log("err", err);
            }
        })

        const loadRecipes = flow(function* () {
            try {
                const json = yield getAllTableRows({
                    table: ftConstants.RECIPES_TABLE_GAME2,
                    code: ftConstants.FARMINGGAME2,
                    scope: ftConstants.FARMINGGAME2,
                    limit: 200
                })

                const buildings = yield getAllTableRows({
                    table: ftConstants.BUILDINGS_TABLE_GAME2,
                    code: ftConstants.FARMINGGAME2,
                    scope: ftConstants.FARMINGGAME2,
                    limit: 100
                })

                updateRecipes(json, buildings)
                markLoading(false)
            } catch (err) {
                console.log("err", err);
            }
        })

        const loadConfChest = flow(function* () {
            try {
                const configChest = yield getAllTableRows({
                    table: ftConstants.CONFCHEST_TABLE_GAME3,
                    code: ftConstants.FARMINGGAME3,
                    scope: ftConstants.FARMINGGAME3,
                    limit: 200
                });

                updateConfChest(configChest.rows)
                updateBarnProducts(self.barnProducts)
                markLoading(false)
            } catch (err) {
                console.log("err", err);
            }
        });

        const loadTasks = flow(function* () {
            try {
                const task = yield getAllTableRows({
                    table: ftConstants.CONFTASK_TABLE_GAME3,
                    code: ftConstants.FARMINGGAME3,
                    scope: ftConstants.FARMINGGAME3,
                    limit: 200
                });

                updateTasks(task.rows)
                // updateBarnProducts(self.barnProducts)
                markLoading(false)
            } catch (err) {
                console.log("err", err);
            }
        })

        const getIngredientsString = (ingredients: any) => {
            let ingredientsString = "";
            let count = 0;
            for (let ingredient of ingredients) {
                ingredientsString += `${getName(ingredient.key)} - ${ingredient.value}${count < ingredients.length - 1 ? ", " : ""}`;
                count++;
            }
            return ingredientsString;
        }

        const addFarmProductsArray = (array: any, ingredient: any, quantity: number) => {
            let index = array.findIndex((value: any) => value.key === ingredient.key);
            if (index !== -1) {
                array[index].value += (ingredient.value * quantity);
            } else {
                array.push({ key: ingredient.key, value: ingredient.value * quantity });
            }
        }

        const getTaskFarmProductsBreakdown: any = (requirements: any) => {
            let onlyFarmProduct = true;
            let recipes = self.recipes;
            let tier1: any = [];
            for (let requirement of requirements) {
                let recipe = recipes.find((recipe: any) => recipe.result === requirement.key);
                if (recipe) {
                    onlyFarmProduct = false;
                    for (let ingredient of recipe.ingredients) {
                        addFarmProductsArray(tier1, ingredient, requirement.value);
                    }
                } else {
                    addFarmProductsArray(tier1, requirement, 1);
                }
            }
            if (!onlyFarmProduct) {
                return getTaskFarmProductsBreakdown(tier1);
            } else {
                return tier1;
            }
        }

        const getProductRecipe = (product: any) => {
            let recipe = self.recipes.find((recipe: any) => recipe.result === product.key);
            if (recipe) {
                return recipe.ingredients.map((ingredient: any) => {
                    return {
                        key: ingredient.key,
                        value: ingredient.value * product.value
                    }
                });
            } else {
                return [];
            }
        }

        const getProductInBarn = (product: any) => {
            let barnProduct = self.barnProducts.find((barnProduct: any) => barnProduct.key === product.key);
            if (barnProduct) {
                return barnProduct.value;
            } else {
                return 0;
            }
        }

        const getTaskProgress = (task: any) => {
            let done = 0;
            let total = 0;
            for (let requirement of task.requirements) {
                let inBarn = getProductInBarn(requirement);
                done += inBarn >= requirement.value ? requirement.value : inBarn;
                total += requirement.value;
            }
            // return [done, total];
            return `${done}/${total}`;
        }

        const isRequirementMet = (requirement: any) => {

        }

        const getTotalTasksProgress = (tasks: any) => {
            let done = 0;
            let total = 0;
            let requirements = [];
            for (let task of tasks) {
                for (let requirement of task.requirements) {
                    let index = requirements.findIndex((value: any) => value.key === requirement.key);
                    if (index !== -1) {
                        requirements[index].value += requirement.value;
                    } else {
                        requirements.push({ key: requirement.key, value: requirement.value });
                    }
                }
            }
            for (let requirement of requirements) {
                let inBarn = getProductInBarn(requirement);
                done += inBarn >= requirement.value ? requirement.value : inBarn;
                total += requirement.value;
            }
            return `${done}/${total}`;
        }

        const getCompletableTasks = () => {
            let completableTasks: any = [];
            for (let task of self.tasks) {
                let completable = true;
                for (let requirement of task.requirements) {
                    let inBarn = getProductInBarn(requirement);
                    if (inBarn < requirement.value) {
                        completable = false;
                        break;
                    }
                }
                if (completable) {
                    completableTasks.push(task);
                }
            }
            return completableTasks;
        }

        const getCouldCreateProductQuantity = (recipe: any) => {
            let array: number[] = [];
            for (let ingredient of recipe.ingredients) {
                let inBarn = getProductInBarn(ingredient);
                let couldCreate = Math.floor(inBarn / ingredient.value);
                array.push(couldCreate);
            }

            let couldCreateProductQuantity = Math.min(...array);
            return couldCreateProductQuantity;
        }

        const handleSellList = (product: any) => {
            let newSellList: any = self.sellList;
            let index = newSellList.findIndex((t: any) => t.key === product.key);
            if (index === -1) {
                newSellList.push(product);
            } else {
                if (product.value === 0) {
                    newSellList.splice(index, 1);
                } else {
                    newSellList[index].value = product.value;
                }
            }
            self.sellList = newSellList;
        };

        const findSeletedSellListValue = (product: any) => {
            let item: any = self.sellList.find((t: any) => t.key === product.key);
            if (item) {
                return item.value;
            } else {
                return 0;
            }
        };

        const clearSellList = () => {
            self.sellList = cast([]);
        }

        const setModalInfo = (modal: any) => {
            self.modalInfo = modal;
        };

        const getListSellAll = () => {
            let listSellAll: any = [];
            for (let product of self.barnProducts) {
                let item: any = self.recipes.find((t: any) => t.result === product.key);
                if (item) {
                    listSellAll.push({ key: product.key, value: product.value });
                }
            }
            return listSellAll;
        }

        const getCreateBoxRecipe = (product: any, size: number) => {
            // size: 0,1,2
            let confchest = self.confChest.find((conf: any) => conf.product === product.key)
            if (confchest) {
                return [{
                    key: product.key,
                    value: confchest.allowed_quantity[size]
                }, {
                    key: "Wood cost",
                    value: confchest.wood_cost[size]
                }]
            } else {
                return [];
            }
        }

        const getBuildingId = (recipeKey: string) => {
            let recipe = self.recipes.find((t: any) => t.result === recipeKey);
            if (recipe) {
                return recipe.building_asset_id;
            } else {
                return null;
            }
        }

        const getTodo: any = (requirements: any, barnProducts: any) => {
            let todo: any = [];
            let _barnProducts: any = barnProducts.map((p: any) => { return { key: p.key, value: p.value } });
            let onlyFarmProduct = true;
            for (let requirement of requirements) {
                let inBarn = 0;
                let barnProductIndex = _barnProducts.findIndex((barnProduct: any) => barnProduct.key === requirement.key);
                if (barnProductIndex !== -1) {
                    inBarn = _barnProducts[barnProductIndex].value;
                }
                if (inBarn >= requirement.value) {
                    if (barnProductIndex !== -1) {
                        _barnProducts[barnProductIndex].value -= requirement.value;
                    }
                    let recipe = self.recipes.find((t: any) => t.result === requirement.key);
                    if (!recipe) {
                        addFarmProductsArray(todo, requirement, 0);
                    } else {
                        onlyFarmProduct = false;
                        for (let ingredient of recipe.ingredients) {
                            addFarmProductsArray(todo, ingredient, 0);
                        }
                    }
                } else {
                    let recipe = self.recipes.find((t: any) => t.result === requirement.key);
                    let quantity = requirement.value - inBarn;
                    if (barnProductIndex !== -1) {
                        _barnProducts[barnProductIndex].value = 0;
                    }
                    if (recipe) {
                        onlyFarmProduct = false;
                        for (let ingredient of recipe.ingredients) {
                            addFarmProductsArray(todo, ingredient, quantity);
                        }
                    } else {
                        addFarmProductsArray(todo, { key: requirement.key, value: quantity }, 1);
                    }
                }
            }

            console.log({ onlyFarmProduct, todo })

            if (onlyFarmProduct) {
                return todo;
            } else {
                return getTodo(todo, _barnProducts);
            }
        }

        const getTaskBreakdown = (requirements: any) => {
            let list: any = [];
            let todo = getTodo(requirements, self.barnProducts);
            let breakdown = getTaskFarmProductsBreakdown(requirements);
            console.log(todo.length, breakdown.length)
            for (let i = 0; i < todo.length; i++) {
                list.push({
                    key: todo[i].key,
                    progress: breakdown[i].value - todo[i].value,
                    value: breakdown[i].value
                })
            }

            return list.sort((a: any, b: any) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
        };

        const clearData = () => {
            self.barnProducts = cast([]);
            self.recipes = cast([]);
            self.productRecipes = cast([]);
            self.confChest = cast([]);
            self.tasks = cast([]);
        }

        const getMemberActive = flow(function* (account: string) {
            try {
                let member_data = yield getTableRow({
                    code: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                    scope: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                    table: constants.WAXIMUS_CENTRAL.MEMBER_TABLE,
                    lower_bound: account,
                });

                if (
                    member_data &&
                    member_data.rows &&
                    member_data.rows.length > 0 &&
                    member_data.rows[0].wallet === account
                ) {
                    // get member tier and check active status
                    member_data.rows[0].expired_at = member_data.rows[0].expired_at + "Z";
                    let
                        isActive =
                            Date.parse(member_data.rows[0].expired_at) > Date.now()
                                ? true
                                : false
                    return isActive;
                } else {
                    return false;
                }
            } catch (err) {
                console.log("err", err);
            }
        })

        return {
            loadBarnProducts,
            loadRecipes,
            loadConfChest,
            loadTasks,
            getIngredientsString,
            getTaskFarmProductsBreakdown,
            getProductRecipe,
            getProductInBarn,
            getTaskProgress,
            getTotalTasksProgress,
            getCompletableTasks,
            getCouldCreateProductQuantity,
            handleSellList,
            findSeletedSellListValue,
            clearSellList,
            setModalInfo,
            getListSellAll,
            getCreateBoxRecipe,
            getBuildingId,
            getTodo,
            getTaskBreakdown,
            markLoading,
            clearData,
            getMemberActive
        }
    })

export const farmingTalesStore = FarmingTales.create({
    isLoading: false,
    barnProducts: [],
    recipes: [],
    productRecipes: [],
    confChest: [],
    tasks: [],
    sellList: [],
    modalInfo: { show: false, title: '', text: "", content: [], type: '' },
});