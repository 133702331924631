import { types } from "mobx-state-tree";

export const Confarm = types.model("Confarm", {
    element: types.string,
    template_id: types.number,
    mint_cost: types.string,
    tit_cost: types.number,
    rut_cost: types.number,
    he_usage: types.number,
    food_usage: types.number,
    speed: types.array(types.number),
    attack: types.number,
    defence: types.number,
    cargo: types.number,
})
