import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';
import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { TextInput } from '../../../components';
import { SecondaryRightContent } from '../../../layouts/SecondaryRightContent';
import { marsStore } from './controller';
interface Props {
  session: any;
}

const MarsEngine: FC<Props> = observer(({ ...props }) => {
  const store: any = getSnapshot(marsStore);

  useEffect(() => {
    if (props?.session?.actor) {
      marsStore.loadUser(String(props.session.actor));
    }
  }, [props?.session?.actor]);

  const wax_balance: any = store.wax_balance;
  const delegated_quantity: any = store.delegated_quantity;
  const delegate_quantity: any = store.delegate_quantity;
  const is_registered: any = store.is_registered;
  const last_reward: any = store.last_reward;
  const total_reward: any = store.total_reward;
  const martia_balance: any = store.martia_balance;
  const swap_quantity: any = store.swap_quantity;

  const handleDelegate = async () => {
    if (props.session) {
      let actions = [];
      if (!is_registered) {
        actions.push({
          account: 'waximusmarpg',
          name: 'regstaker',
          authorization: [props.session.permissionLevel],
          data: {
            account: String(props.session.actor),
          },
        });
      }
      await props.session
        .transact(
          {
            actions: [
              ...actions,
              {
                account: 'eosio',
                name: 'delegatebw',
                authorization: [props.session.permissionLevel],
                data: {
                  from: String(props.session.actor),
                  receiver: 'waximusmarpg',
                  stake_cpu_quantity: `${delegate_quantity.toFixed(8)} WAX`,
                  stake_net_quantity: `0.00000000 WAX`,
                  transfer: false,
                },
              },
            ],
          },
          {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 120,
          }
        )
        .then((response: any) => {
          toast.success(
            <div>
              Executed:{' '}
              <a
                href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}
              >{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a>
            </div>,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })
        .catch((err: any) => {
          console.log('err', err);
          toast.warn(`${err}`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        });
    } else {
      toast.warn('Please login to delegate!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  const handleSwap = async () => {
    if (props.session) {
      let actions = [];
      if (!is_registered) {
        actions.push({
          account: 'waximusmarpg',
          name: 'regstaker',
          authorization: [props.session.permissionLevel],
          data: {
            account: String(props.session.actor),
          },
        });
      }
      await props.session
        .transact(
          {
            actions: [
              ...actions,
              {
                account: 'eosio',
                name: 'delegatebw',
                authorization: [props.session.permissionLevel],
                data: {
                  from: String(props.session.actor),
                  receiver: 'waximusmarpg',
                  stake_cpu_quantity: `${delegate_quantity.toFixed(8)} WAX`,
                  stake_net_quantity: `0.00000000 WAX`,
                  transfer: false,
                },
              },
            ],
          },
          {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 120,
          }
        )
        .then((response: any) => {
          toast.success(
            <div>
              Executed:{' '}
              <a
                href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}
              >{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a>
            </div>,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })
        .catch((err: any) => {
          console.log('err', err);
          toast.warn(`${err}`, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        });
    } else {
      toast.warn('Please login to delegate!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };

  return (
    <SecondaryRightContent>
      <div className="">
        <p className="text-2xl text-yellow-400 font-bold  text-center m-1">
          Waximus On Mars Operator Program:
        </p>
        <div className="flex-row flex justify-center">
          <ul className="list-disc list-inside">
            <li className="text-xl text-gray-700 dark:text-white text-left m-1">
              Delegate CPU to our Operator and start earning{' '}
              <span className="text-rose-500">MARTIA</span>
            </li>
            <li className="text-xl text-gray-700 dark:text-white text-left m-1">
              You will still get your <span className="text-rose-500">WAX</span>{' '}
              from the Voting reward system
            </li>
            <li className="text-xl text-gray-700 dark:text-white text-left m-1">
              Our system distributes{' '}
              <span className="text-rose-500">MARTIA</span> to your account
              daily at 0h00 UTC
            </li>
            <li className="text-xl text-gray-700 dark:text-white text-left m-1">
              <span className="text-rose-500">90%</span> reward goes to your
              account. Earn up to <span className="text-rose-500">95%</span> if
              you are{' '}
              <span className="text-rose-500">Waximus Prime Member</span>
            </li>
          </ul>
        </div>
        {props.session ? (
          <div className="mt-5">
            <p className="text-2xl text-gray-700 dark:text-white font-bold  text-center m-1">
              Delegated to Waximus Operator:{' '}
              <span className="text-rose-500">{delegated_quantity}</span>
            </p>
            <p className="text-2xl text-gray-700 dark:text-white font-bold  text-center m-1">
              Your available balance:{' '}
              <span className="text-rose-500">{wax_balance} WAX</span>
            </p>
            <p className="text-2xl text-gray-700 dark:text-white font-bold  text-center m-1">
              Your MARTIA balance:{' '}
              <span className="text-rose-500">{martia_balance} MARTIA</span>
            </p>

            <div className="flex justify-center">
              <TextInput
                size={4}
                placeholder="WAX Quantity"
                value={delegate_quantity}
                type="number"
                classNameInputText="text-center"
                onChange={(e) => {
                  // if (e.target.value !== "") {
                  let value = parseFloat(e.target.value);
                  marsStore.onChangeDelegateQuantity(value);
                  // }
                }}
              />
              <p className="text-2xl text-gray-700 dark:text-white font-bold  text-center m-1">
                WAX
              </p>
              <button
                className="bg-yellow-600 hover:bg-yellow-800 text-white font-bold py-2 px-4 rounded ml-1"
                onClick={() => {
                  handleDelegate();
                }}
              >
                Delegate CPU
              </button>
              {/* <Button
                            className='w-32 ml-1'
                            style={{ backgroundColor: "rgba(17,24,39,1)", }}
                            onClick={() => { handleDelegate() }}>
                            <div className='bg-yellow-600 hover:bg-yellow-800 text-white font-bold py-2 px-4 rounded '>
                                <div className=''>
                                    Delegate CPU
                                </div>
                            </div>
                        </Button> */}
            </div>
            <div className="flex justify-center mt-3">
              <button
                className="bg-yellow-600 hover:bg-yellow-800 text-white font-bold py-2 px-4 rounded ml-1"
              // onClick={() => {
              //   handleSwap();
              // }}
              >
                <a
                  href="https://alcor.exchange/swap?input=MARTIA-martia&output=WAX-eosio.token"
                  target="blank"
                >
                  Swap MARTIA to WAX
                </a>
                {/* Swap MARTIA to WAX */}
              </button>
            </div>

            <div className="container mx-auto border-dashed border-2 border-indigo-50 mt-5">
              {/* Show last and total reward */}
              <p className="text-2xl text-gray-700 dark:text-white font-bold  text-center m-1">
                Last Reward:{' '}
                <span className="text-rose-500">{last_reward}</span>
              </p>
              <p className="text-2xl text-gray-700 dark:text-white font-bold  text-center m-1">
                Total Reward:{' '}
                <span className="text-rose-500">{total_reward}</span>
              </p>
            </div>

            <div className=" container mx-auto mt-5 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg ">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">How to Delegate Manually (Outside of our website):</h3>
              <ul className="list-disc list-inside text-gray-700 dark:text-gray-300">
                <li>Visit <a href="https://waxblock.io/wallet/resources" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">https://waxblock.io/wallet/resources</a></li>
                <li>Receiver of Stake: <span className="font-bold">waximusmarpg</span></li>
                <li>Amount of WAX to Stake for CPU: <span className="font-semibold">The amount of WAX you want to stake</span></li>
                <li>Amount of WAX to Stake for NET: <span className="font-semibold">0</span></li>
                <li>Click the <button disabled={true} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline" type="button">STAKE</button> button</li>
                <li>Then go to our Operator Smart Contract: <a href="https://waxblock.io/account/waximusmarpg?action=regstaker#contract-actions" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline" >https://waxblock.io/account/waximusmarpg?action=regstaker#contract-actions</a> </li>
                <li>Fill in your account to account field then Click the <button disabled={true} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded focus:outline-none focus:shadow-outline" type="button">SUBMIT TRANSACTION</button> button to <span className='font-bold text-yellow-300'>register as staker</span> in our system</li>

              </ul>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-96 text-gray-700 dark:text-white">
            Please login to join this program
          </div>
        )}
      </div>
    </SecondaryRightContent>
  );
});
export { MarsEngine };
