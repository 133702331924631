import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, useEffect, useState } from "react";
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import { botFTStore } from "./controllers";
import { Card } from "../../../../components";
import { getName } from "../../../../constants/farmingtales/products";
import { FcCheckmark, FcEngineering } from "react-icons/fc";

interface FTProps {
  ual: any;
}

const wait = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const Recipes: FC<FTProps> = observer(({ ...props }) => {
  let store: any = getSnapshot(botFTStore);
  const ready: boolean = store.ready;
  const tier1: any = store.tier1;
  const selectedProducts: any = store.selectedProducts;
  const filteredTier2: any = store.filteredTier2;
  const filteredTier3: any = store.filteredTier3;
  const filteredTier4: any = store.filteredTier4;
  const filteredTier5: any = store.filteredTier5;
  const filteredTier6: any = store.filteredTier6;
  const filteredTier7: any = store.filteredTier7;
  const filteredTier8: any = store.filteredTier8;
  const filteredTier9: any = store.filteredTier9;
  const filteredTier10: any = store.filteredTier10;
  const selectedPs: any = store.selectedPs;

  useEffect(() => {
    botFTStore.loadRecipesProducts();
  }, []);

  const renderTier1 = (tier: any, tierName: string) => {
    return (
      <>
        {tier.length > 0 && (
          <Card>
            <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
              <div>{tierName}</div>
            </div>
            <div className="text-gray-700 dark:text-white">
              {tier.map((prod: any) => (
                <div
                  key={Math.random()}
                  // className="p-3 border border-dashed"
                  className={`p-3 border border-dashed ${
                    selectedProducts.includes(prod.product_name) &&
                    "bg-fuchsia-800"
                  } cursor-pointer`}
                  onClick={() => {
                    botFTStore.setSelectedProducts(prod.product_name);
                  }}
                >
                  <div className="grid grid-cols-2">
                    {getName(prod.product_name)}
                    {/* <div className="flex items-center justify-end">
                      {botFTStore.checkProductInBarn(prod) && <FcCheckmark />}
                      {botFTStore.checkProductInProducing(prod) && (
                        <FcEngineering />
                      )}
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </Card>
        )}
      </>
    );
  };

  const renderTier = (tier: any, tierName: string) => {
    return (
      <>
        {tier.length > 0 && (
          <Card>
            <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
              <div>{tierName}</div>
            </div>
            <div className="text-gray-700 dark:text-white">
              {tier.map((prod: any) => (
                <div
                  key={Math.random()}
                  className={`p-3 border border-dashed ${
                    selectedPs.includes(prod.product_name) && "bg-amber-800"
                  } cursor-pointer`}
                  onClick={() => {
                    botFTStore.getProductIngredients(prod.product_name);
                  }}
                >
                  <div>
                    {getName(prod.product_name)} - {prod.sest_value / 10000}{" "}
                    SEST
                  </div>
                  <div className="">
                    ({botFTStore.getIngredientsString(prod.recipe.ingredients)})
                  </div>
                </div>
              ))}
            </div>
          </Card>
        )}
      </>
    );
  };

  return (
    <SecondaryRightContent>
      {/* <div className="text-white mb-4">Auto Recipes</div> */}
      {/* <div className="text-white mb-4 flex">
        Selected Products:{" "}
        {selectedProducts.map(
          (prod: any, index: number) =>
            `${getName(prod)}${index < selectedProducts.length - 1 ? ", " : ""}`
        )}
      </div> */}
      <div className="grid grid-cols-5 gap-4">
        {renderTier1(tier1, "Tier 1")}
        {renderTier(filteredTier2, "Tier 2")}
        {renderTier(filteredTier3, "Tier 3")}
        {renderTier(filteredTier4, "Tier 4")}
        {renderTier(filteredTier5, "Tier 5")}
        {renderTier(filteredTier6, "Tier 6")}
        {renderTier(filteredTier7, "Tier 7")}
        {renderTier(filteredTier8, "Tier 8")}
        {renderTier(filteredTier9, "Tier 9")}
        {renderTier(filteredTier10, "Tier 10")}
      </div>
    </SecondaryRightContent>
  );
});

export { Recipes };
