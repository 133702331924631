
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";
import App from './App';
import { Waximus } from './components';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { blockchains } from "./resources/data/blockchains";

const runningChain = blockchains[0];
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
//Initialize GA4
ReactGA.initialize("G-503ZQSJ8Z5");
root.render(

  <React.StrictMode>
    <Waximus theme={{ dark: true }}>
      <App

        rpcEndpoints={runningChain.rpcEndpoints}
        aaEndpoints={runningChain.aaEndpoints} />
    </Waximus>
  </React.StrictMode>

);
// const SendAnalytics = () => {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
