// declare TierItem mobx-state-tree model

import { types } from "mobx-state-tree";

const ExtendedAsset = types.model("ExtendedAsset", {
    contract: types.string,
    quantity: types.string,
});

export const TierItem = types.model("TierItem", {
    tier_name: types.string,
    fee_per_month: types.array(ExtendedAsset),
});

