import parsecConstants from "../../../constants/parsec";
export const startUpgrade = async (session: any, position: any, type: any, action_name: any) => {
    try {

        return await session.transact(
            {
                actions: [
                    {
                        account: parsecConstants.GAME_CONTRACT,
                        name: action_name,
                        authorization: [session.permissionLevel],
                        data: {
                            account: session.actor,
                            position,
                            type,
                        },
                    },
                ],
            }
        );
    } catch (error) {
        console.log("Error", error);
    }
}

export const finishUpgrade = async (session: any, position: any, type: any, action_name: any) => {
    try {
        return await session.transact(
            {
                actions: [
                    {
                        account: parsecConstants.GAME_CONTRACT,
                        name: action_name,
                        authorization: [session.permissionLevel],
                        data: {
                            account: session.actor,
                            position,
                            type,
                        },
                    },
                ],
            }
        );
    } catch (error) {
        console.log("Error", error);
    }
}

export const claimResource = async (session: any, position: any, type: any) => {
    try {
        return await session.transact(
            {
                actions: [
                    {
                        account: parsecConstants.GAME_CONTRACT,
                        name: parsecConstants.CLAIM_RESOURCE_ACTION,
                        authorization: [session.permissionLevel],
                        data: {
                            account: session.actor,
                            position,
                            type,
                        },
                    },
                ],
            }
        );
    } catch (error) {
        console.log("Error", error);
    }
}