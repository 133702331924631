import { observer } from 'mobx-react-lite';
import { getSnapshot } from 'mobx-state-tree';
import { FC, useEffect } from 'react';
import LazyLoad from 'react-lazy-load';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Modal, TextInput } from '../../../../components';
import mainConstants from "../../../../constants/index";
import tacoConstants from "../../../../constants/taco/index";
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import wrapProviderHOC from '../../../../layouts/WrapProvider';
import { MultiKiosksStore } from '../multiKiosks/multiKiosks.controller';
import { SupplyIngredientStore } from "./supplyIngredient.controller";

const Loading = () => (
    <div className="post loading">
        <h5>Loading...</h5>
    </div>
);
interface SupplyIngredientProps {
    session: any;
    member: any;
}

const SupplyIngredientScreen: FC<SupplyIngredientProps> = observer(({ ...props }) => {
    const store: any = getSnapshot(SupplyIngredientStore);
    const multiStore: any = getSnapshot(MultiKiosksStore);
    let params: any = useParams();

    useEffect(() => {
        if (params?.kiosk_id) {
            SupplyIngredientStore.loadIngredients(params?.kiosk_id);
        }
        if (props.session) {
            // setInterval(() => {
            SupplyIngredientStore.loadSupplyItems(String(props.session.actor));
            // SupplyIngredientStore.loadSupplyItems("chuuo.wam");
            // SupplyIngredientStore.loadAAItems("chuuo.wam");
            SupplyIngredientStore.loadAAItems(String(props.session.actor));
            // }, 5000);
        }
        MultiKiosksStore.loadKiosks().then(() => {
            if (params?.kiosk_id && (params?.kiosk_id !== multiStore.selectedKiosk?.kiosk_id)) {
                MultiKiosksStore.setSelectedKioskById(params?.kiosk_id);
            }
        });

    }, [props.session, params?.kiosk_id, multiStore.selectedKiosk, props?.member?.isActive]);

    let items: any = store.renderItems;
    let selectedKiosk: any = multiStore.selectedKiosk;


    const handleSupply = async () => {
        

        if (props.session) {

            let isDeposit = store.supplyActionModal.isDeposit;
            if (props?.member?.isActive === false && isDeposit === true) {
                toast.warn("Please subscribe to use this feature!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: "z-50",
                    theme: "dark",
                });
                return;
            }
            const response = await props.session.transact({
                actions: [
                    {
                        account: isDeposit ? mainConstants.ATOMICASSETS_CONTRACT : tacoConstants.AUTO_KIOSK_CONTRACT,
                        name: isDeposit ? 'transfer' : 'withdraw',
                        authorization: [props.session.permissionLevel],
                        data: isDeposit ? {
                            from: String(props.session.actor),
                            to: tacoConstants.AUTO_KIOSK_CONTRACT,
                            asset_ids: store.supplyActionModal.available_asset_ids.slice(0, store.supplyActionModal.quantity),
                            memo: `deposit_ingredients;${selectedKiosk.kiosk_id}`,
                        } : {
                            user: String(props.session.actor),
                            kiosk_id: selectedKiosk.kiosk_id,
                            template_id: store.supplyActionModal.template_id,
                            amount: store.supplyActionModal.quantity,
                        },
                    }
                ]
            }, {
                broadcast: true,
                blocksBehind: 3,
                expireSeconds: 120,
            })
                .then((response: any) => {

                    toast.success(<div>Executed: <a href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}>{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a></div>, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    // refresh data
                    if (props.session) {
                        SupplyIngredientStore.loadSupplyItems(String(props.session.actor));
                        SupplyIngredientStore.loadAAItems(String(props.session.actor));
                    }
                    // close modal
                    SupplyIngredientStore.closeModal();
                    // window.location.reload();
                })
                .catch((err: any) => {
                    console.log("err", err);
                    toast.warn(`${err}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    // alert(err)
                });
        } else {
            // alert("Please login to supply ingredients!");
            toast.warn("Please login to supply ingredients!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    return <SecondaryRightContent>
        <p className="text-sm text-yellow-300 font-bold  text-center"> Fee Structure</p>
        <p className="text-sm text-yellow-300 font-semibold  text-center"> Supplier profit = Listing Price - Crafting Cost (200,000SHING) - Service Fee (10% Profit)</p>
        <p className="text-sm text-yellow-300 font-semibold  text-center"> All transactions are automated by smart contracts</p>
        <p className="text-2xl text-amber-500 font-bold text-center mt-5">You're supplying for: <span className='decoration-slate-400 underline decoration-dash'>{selectedKiosk?.kiosk_name}</span></p>
        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-5 gap-4 md:p-16 md:pt-8">
            {items.map((item: any) => {
                return (
                    <LazyLoad
                        key={item?.template_id}
                        height={500}
                    >
                        <div className="lg:max-w-sm" key={item?.template_id}>
                            <Card imgSrc={`https://atomichub-ipfs.com/ipfs/${item.media}`}>
                                <div className="text-lg text-gray-700 dark:text-white font-bold">
                                    {item.item_name}
                                </div>
                                <div className=' hidden md:inline'>
                                    <div className="flex flex-row justify-between">
                                        <p className="text-slate-500 dark:text-slate-400 text-sm self-center">
                                            Listing Price:
                                        </p>
                                        <p className="text-slate-500 dark:text-white dark:font-bold text-sm self-center">
                                            {SupplyIngredientStore.removeShingPrecision(item.unit_price)}
                                        </p>
                                    </div>
                                </div>
                                <div className="md:hidden inline">
                                    <p className="text-slate-500 dark:text-slate-400 text-sm self-center">
                                        Listing Price:
                                    </p>
                                    <p className="text-slate-500 dark:text-white dark:font-bold text-sm self-center">
                                        {SupplyIngredientStore.removeShingPrecision(item.unit_price)}
                                    </p>
                                </div>

                                <div className="flex justify-between">
                                    <p className="text-slate-500 dark:text-slate-400 text-sm self-center">
                                        {/* Your Inventory: <span className='dark:text-white font-bold'>{item.available}</span> */}
                                        Deposited:
                                    </p>
                                    <p className="text-slate-500 dark:text-slate-400  text-sm self-center">
                                        {/* Deposited: <span className='dark:text-white font-bold'>{item.deposited}</span> */}
                                        <span className='dark:text-white font-bold'>{item.deposited}</span>
                                    </p>
                                </div>
                                <div className="flex justify-between flex-row ">
                                    <Button color={"gray"}
                                        className='basis-2/5 dark:bg-gray-900 font-bold text-red text-base  dark:border-amber-600'
                                        outline
                                        style={{ backgroundColor: "rgba(17,24,39,1)", }}
                                        fullSized
                                        onClick={() => {
                                            if (props?.member?.isActive === false) {
                                                toast.warn("Please subscribe to use this feature!", {
                                                    position: "top-right",
                                                    autoClose: 2000,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    progress: undefined,
                                                    className: "z-50",
                                                    theme: "dark",
                                                });

                                            } else {
                                                SupplyIngredientStore.showModal(item, String(props.session.actor), true)
                                            }
                                        }}>
                                        <div className='flex justify-center content-center  text-center text-amber-600 font-bold'>
                                            <div className=''>
                                                Deposit
                                            </div>
                                        </div>
                                    </Button>
                                    <div className='basis-1/5'></div>
                                    <Button
                                        className='basis-2/5 font-bold text-base  dark:bg-amber-600'
                                        onClick={() => SupplyIngredientStore.showModal(item, String(props.session.actor), false)}>
                                        <div className='flex justify-center content-center  text-center text-white font-bold '>
                                            <div className=''>
                                                Withdraw
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            </Card>

                        </div>

                    </LazyLoad>

                );
            })}
            <Modal
                size={"sm"}
                show={store.supplyActionModal.isShow}
                onClose={() => { SupplyIngredientStore.closeModal() }}>
                <Modal.Header >
                    {store.supplyActionModal.title}
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-between ">
                        <p className="text-slate-500 dark:text-slate-400 text-sm self-center">
                            Quantity:
                        </p>
                        <div className='flex flex-row'>
                            <TextInput
                                classNameHelperText='self-center mt-0 ml-1'
                                helperText={`/${store.supplyActionModal.max_quantity}`}
                                size={4}
                                id="quantity"
                                placeholder="0"
                                value={store.supplyActionModal.quantity}
                                max={store.supplyActionModal.max_quantity}
                                onChange={(e) => SupplyIngredientStore.changeQuantityModal(Number(e.target.value))}
                            />   </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-center'>
                    <Button className='mr-2 font-bold text-base' onClick={handleSupply}>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
        <p className='text-2xl text-yellow-400 font-bold  text-center m-1'>Waximus Prime Membership now has the following benefits:</p>
        {/* A list of benefit HTML */}
        <div className='flex-row flex justify-center'>
            <ul className='list-disc list-inside'>
                <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Ingredient buyer: refund kiosk vendor profit (7% profit)</li>
                <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Ingredient supplier: refund kiosk system profit (3% profit)
                </li>
            </ul>
        </div>
    </SecondaryRightContent>
});

const SupplyIngredient = wrapProviderHOC(SupplyIngredientScreen);
export {
    SupplyIngredient
};

