import { Dialog, Transition } from "@headlessui/react";
import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, Fragment, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BsDiscord } from "react-icons/bs";
import { FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';
import { HiOutlineShoppingCart } from "react-icons/hi";
import LazyLoad from 'react-lazy-load';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, TextInput } from "../../../../components";
import tacoConstants from "../../../../constants/taco/index";
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import wrapProviderHOC from '../../../../layouts/WrapProvider';
import { AppColors } from "../../../../utils/colors";
import { MultiKiosksStore } from '../multiKiosks/multiKiosks.controller';
import { kioskStore } from "./kiosk.controller";

const Loading = () => (
    <div className="post loading">
        <h5>Loading...</h5>
    </div>
);

interface KioskProps {
    session: any;
    member: any;
}

const descriptions: any = {
    1099863961700: {
        text: `Community Kiosk for Cozy's.
    Only accessible to CozyClub and CZC Discord members`,
        links: { discord: "https://discord.gg/ZTr8xPk5aS", }
    },
    1099862463248: {
        text: `Nostalg33k's Arcade Hall - Your #1 Chicken Venue in the Taco Universe.`,
        links: {
            twitter: "https://twitter.com/NostalTw33t",
            instagram: "https://www.instagram.com/g33kstagraph",
            donation: "https://ko-fi.com/nostalg33k"
        }
    },
};

const KioskScreen: FC<KioskProps> = observer(({ ...props }) => {
    const store: any = getSnapshot(kioskStore);
    const multiStore: any = getSnapshot(MultiKiosksStore);
    const [open, setOpen] = useState(false);
    let params: any = useParams();

    useEffect(() => {
        if (params?.kiosk_id) {
            kioskStore.loadStocks(params?.kiosk_id);
        }
        MultiKiosksStore.loadKiosks().then(() => {
            if (params?.kiosk_id && params?.kiosk_id !== multiStore.selectedKiosk?.kiosk_id) {
                MultiKiosksStore.setSelectedKioskById(params?.kiosk_id);
            }
        });

    }, [multiStore.selectedKiosk, params?.kiosk_id, props?.member?.isActive]);
    let items: any = store.stocks;
    let cart: any = store.cart;
    let selectedKiosk: any = multiStore.selectedKiosk;

    const buy = async () => {
        setOpen(false)
        if (props?.session) {
            if (cart.length <= 0) {
                toast.warn("Please add items to your cart!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                return;
            }
            try {
                let memo = kioskStore.getMemo();
                const response = await props?.session.transact(
                    {
                        actions: [
                            {
                                account: tacoConstants.TOKEN_CONTRACT,
                                name: "transfer",
                                authorization: [
                                    props.session.permissionLevel
                                ],
                                data: {
                                    from: String(props.session.actor),
                                    to: tacoConstants.AUTO_KIOSK_CONTRACT,
                                    quantity: `${kioskStore.getTotalPrice()}.0000 SHING`,
                                    memo,
                                },
                            },
                        ],
                    },
                    {
                        broadcast: true,
                        blocksBehind: 3,
                        expireSeconds: 120,
                    }
                );
                toast.success(<div>Executed: <a href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}>{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a></div>, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setOpen(false)

                kioskStore.clearCart();
                if (params?.kiosk_id) {
                    kioskStore.loadStocks(params?.kiosk_id);
                }
            } catch (e) {
                console.log({ e });
                toast.warn(`${e}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                // alert(e);
            }
        } else {
            toast.warn("Please login to buy ingredients!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "z-50",
                theme: "dark",
            });
            // toast.success('🦄 Wow so easy!', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "dark",
            // });
            // toast.info('🦄 Wow so easy!', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "dark",
            // });
            // toast.error('🦄 Wow so easy!', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "dark",
            // });
        }
    };

    return (
        <SecondaryRightContent>
            <div className="">
                <div className="c">
                    <p className='text-2xl text-yellow-400 font-bold  text-center m-1'>Waximus Prime Membership now has the following benefits:</p>
                    {/* A list of benefit HTML */}
                    <div className='flex-row flex justify-center'>
                        <ul className='list-disc list-inside'>
                            <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Access to all features of the <span className='text-rose-500'>Auto Taco Kiosk</span></li>
                            <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Ingredient buyer: refund kiosk vendor profit (7% profit)</li>
                            <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Ingredient supplier: refund kiosk system profit (3% profit)</li>
                        </ul>
                    </div>
                    <div className='flex-row flex justify-self-center'>
                        <div className="basis-1/5 "></div>
                        <div className="flex-auto text-2xl text-amber-500 font-bold text-center">
                            {descriptions[selectedKiosk?.kiosk_id] ?
                                <div >
                                    <div>{descriptions[selectedKiosk?.kiosk_id].text}</div>
                                    <div className='flex flex-row justify-center mt-2 space-x-2'>
                                        {descriptions[selectedKiosk?.kiosk_id].links.discord &&
                                            (
                                                <a href={descriptions[selectedKiosk?.kiosk_id].links.discord} target="_blank" rel="noreferrer" >
                                                    <BsDiscord></BsDiscord>
                                                </a>
                                            )}
                                        {descriptions[selectedKiosk?.kiosk_id].links.twitter &&
                                            (
                                                <a href={descriptions[selectedKiosk?.kiosk_id].links.twitter} target="_blank" rel="noreferrer" >
                                                    <FaTwitterSquare></FaTwitterSquare>
                                                </a>
                                            )}
                                        {descriptions[selectedKiosk?.kiosk_id].links.instagram &&
                                            (
                                                <a href={descriptions[selectedKiosk?.kiosk_id].links.instagram} target="_blank" rel="noreferrer" >
                                                    <FaInstagramSquare></FaInstagramSquare>
                                                </a>
                                            )}
                                    </div>
                                </div> :
                                <div>{selectedKiosk?.kiosk_name}</div>}
                        </div>
                        
                            <Link className="basis-1/5 pl-12 pr-24 "
                                to={`/taco/supply-ingredients/${selectedKiosk?.kiosk_id}`}>
                                <Button color={"gray"}
                                    className='dark:bg-gray-900 font-bold text-red text-base  dark:border-amber-600'
                                    outline
                                    style={{ backgroundColor: "rgba(17,24,39,1)", }}
                                    fullSized
                                >
                                    <div className='flex justify-center content-center  text-center text-amber-600 font-bold'>
                                        <div className=''>
                                            Supply Ingredients
                                        </div>
                                    </div>
                                </Button>
                            </Link>
                        
                    </div>
                    <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8 md:p-16 p-4">
                        {items.map((item: any) => {
                            return (
                                <LazyLoad

                                    key={item?.template_id}

                                >
                                    <div className="lg:max-w-sm relative" key={item?.template_id}>
                                        {item.quantity === 0 && (
                                            <div className=" absolute top-0 right-0 left-0 bottom-0 z-50 bg-red-500/[.08] rounded-large p-4">
                                                <p className="text-rose-600 font-extrabold text-xl">
                                                    SOLD OUT
                                                </p>
                                            </div>
                                        )}
                                        <Card
                                            imgSrc={`https://atomichub-ipfs.com/ipfs/${item.media}`}
                                        >

                                            <div className="text-lg text-gray-700 dark:text-white font-bold">
                                                {item.item_name}
                                            </div>
                                            <div className="flex flex-row ">
                                                <p className="text-slate-500 dark:text-slate-400 text-sm self-center">
                                                    In Stock:
                                                </p>
                                                <p className="text-slate-500 dark:text-white dark:font-bold text-sm self-center pl-5">
                                                    {item.quantity}
                                                </p>
                                            </div>

                                            <div className="flex-row flex justify-between ">
                                                <p className="text-slate-500 dark:text-slate-400 text-sm font-bold self-center ">
                                                    {kioskStore.removeShingPrecision(item.unit_price)}
                                                </p>

                                                <div className="flex items-center justify-center ">
                                                    {/* <div
                                                        className="rounded-full border-amber-600 mr-2 bg-slate-800 border h-8 w-8 md:h-6 md:w-6 alight-center items-center justify-center flex z-50"
                                                        onClick={() => kioskStore.removeFromCart(item)}
                                                    >
                                                        <AiOutlineMinus color={AppColors.white} />
                                                    </div>

                                                    <TextInput
                                                        helperText={`/${item.quantity}`}
                                                        size={4}
                                                        // id="id"
                                                        placeholder="0"
                                                        value={`${kioskStore.getItemQuantityOnCart(item)}`}
                                                        max={item.quantity}
                                                        classNameInputText="text-center"
                                                        classNameHelperText="mt-0 ml-1"
                                                        onChange={(e) =>
                                                            kioskStore.changeQuantityItem(
                                                                item,
                                                                Number(e.target.value),
                                                                item.quantity
                                                            )
                                                        }
                                                    /> */}
                                                    <div
                                                        className="rounded-full bg-amber-600 h-8 w-8 md:h-8 md:w-8 alight-center items-center justify-center flex z-50"
                                                        onClick={() => {
                                                            if (props?.session) {
                                                                setOpen(true)
                                                                if (item.quantity > 0 && kioskStore.getItemQuantityOnCart(item) <
                                                                    item.quantity) kioskStore.addToCart(item)
                                                            } else {
                                                                toast.warn("Please login to buy ingredients!", {
                                                                    position: "top-right",
                                                                    autoClose: 2000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true,
                                                                    progress: undefined,
                                                                    className: "z-50",
                                                                    theme: "dark",
                                                                });
                                                            }

                                                        }}
                                                    >
                                                        <HiOutlineShoppingCart color={AppColors.white} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </LazyLoad>
                            );
                        })}
                    </div>
                </div>


            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-1" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-slate-900 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-300 sm:duration-500"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-300 sm:duration-500"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-slate-900 shadow-xl">
                                            <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white ">Shopping cart</Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <AiOutlineClose />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-8">
                                                    <div className="flow-root">
                                                        <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                            {cart.map((item: any) => (
                                                                <li key={item.template_id} className="flex py-6">
                                                                    <div className="h-24 w-24 flex-shrink-0 overflow-hidden">
                                                                        <img
                                                                            src={`https://atomichub-ipfs.com/ipfs/${item.media}`}
                                                                            alt={"product"}
                                                                            className="h-full w-full object-cover object-center"
                                                                        />
                                                                    </div>

                                                                    <div className="ml-4 flex flex-1 flex-col">
                                                                        <div>
                                                                            <div className="flex justify-between text-base font-medium text-gray-900">
                                                                                <h3>
                                                                                    <a href={"#"} className="dark:text-white">{item.item_name}</a>
                                                                                </h3>
                                                                                <p className="ml-4 dark:text-white">{`${Number(item.unit_price.replace(".0000 SHING", "")) *
                                                                                    item.quantity
                                                                                    }`}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-1 items-end justify-between text-sm">
                                                                            <div className="flex items-center justify-center mt-2">
                                                                                <div
                                                                                    className="rounded-full border-amber-600 mr-2 bg-slate-800 border h-8 w-8 md:h-6 md:w-6 alight-center items-center justify-center flex z-50"
                                                                                    onClick={() => kioskStore.removeFromCart(item, 1)}
                                                                                >
                                                                                    <AiOutlineMinus color={AppColors.white} />
                                                                                </div>

                                                                                <TextInput
                                                                                    helperText={`/${kioskStore.getMaxQuantityByIng(item)}`}
                                                                                    size={4}
                                                                                    // id="id"
                                                                                    placeholder="0"
                                                                                    value={`${kioskStore.getItemQuantityOnCart(item)}`}
                                                                                    max={kioskStore.getMaxQuantityByIng(item)}
                                                                                    classNameInputText="text-center"
                                                                                    classNameHelperText="mt-0 ml-1"
                                                                                    onChange={(e) =>
                                                                                        kioskStore.changeQuantityItem(
                                                                                            item,
                                                                                            Number(e.target.value),
                                                                                            kioskStore.getMaxQuantityByIng(item)
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <div
                                                                                    className="rounded-full bg-amber-600 ml-2 h-8 w-8 md:h-6 md:w-6 alight-center items-center justify-center flex z-50"
                                                                                    onClick={() => {
                                                                                        if (item.quantity > 0 && kioskStore.getItemQuantityOnCart(item) <
                                                                                            kioskStore.getMaxQuantityByIng(item)) kioskStore.addToCart(item)
                                                                                    }}
                                                                                >
                                                                                    <AiOutlinePlus color={AppColors.white} />
                                                                                </div>
                                                                            </div>

                                                                            <div className="flex mt-4 self-center">
                                                                                <button
                                                                                    type="button"
                                                                                    className="font-medium text-indigo-600 hover:text-indigo-500"
                                                                                    onClick={() => kioskStore.removeFromCart(item, kioskStore.getItemQuantityOnCart(item))}
                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                                <div className="flex justify-between text-base font-medium text-gray-900 dark:text-white">
                                                    <p>Subtotal</p>
                                                    <p>{kioskStore.getTotalPrice()} SHING</p>
                                                </div>
                                                <Button
                                                    fullSized
                                                    className="basis-2/5 font-bold text-base  dark:bg-amber-600 mt-2"
                                                    onClick={() => buy()}
                                                >
                                                    <div className="flex justify-center content-center  text-center text-white font-bold ">
                                                        <div className="">Buy</div>
                                                    </div>
                                                </Button>
                                                <Button
                                                    color={"gray"}
                                                    className="basis-2/5 dark:bg-gray-900 font-bold text-red text-base  dark:border-amber-600 mt-2"
                                                    outline
                                                    style={{ backgroundColor: "rgba(17,24,39,1)" }}
                                                    fullSized
                                                    onClick={() => {
                                                        kioskStore.clearCart();
                                                    }}
                                                >
                                                    <div className="flex justify-center content-center  text-center text-amber-600 font-bold">
                                                        <div className="">Clear</div>
                                                    </div>
                                                </Button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            {props?.session && <div className="absolute right-8 bottom-20">
                <div>
                    <Button
                        gradientDuoTone="purpleToBlue"
                        outline={true}
                        pill={true}
                        onClick={() => setOpen(true)}
                    >
                        <HiOutlineShoppingCart className="h-6 w-6" />
                    </Button>
                </div>
            </div>}
        </SecondaryRightContent>
    );
});

const Kiosk = wrapProviderHOC(KioskScreen);
export { Kiosk };
