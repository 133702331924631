import { types } from "mobx-state-tree";

export const Accstat = types.model("Accstat", {
    account: types.string,
    total_wax_earned: types.string,
    total_sold: types.number,
    total_returned: types.number,
    total_items: types.number,
    active_sales: types.number,
})
