interface BlockchainProps {
    chainId: string,
    name: string,
    rpcEndpoints: ApiProps[],
    aaEndpoints: ApiProps[],
}
interface ApiProps {
    protocol: string,
    host: string,
    port: number,
}

const blockchains: BlockchainProps[] = [
    {
        chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
        name: 'WAX',
        rpcEndpoints: [
            {
                protocol: "https",
                port: 443,
                host: 'wax.greymass.com',
            }, {
                protocol: "https",
                port: 443,
                host: 'api.waxsweden.org',
            }, {
                protocol: "https",
                port: 443,
                host: 'wax.eosdac.io',
            }, {
                protocol: "https",
                port: 443,
                host: 'wax-history.eosdac.io',
            }, {
                protocol: "https",
                port: 443,
                host: 'wax.eu.eosamsterdam.net',
            }, {
                protocol: "https",
                port: 443,
                host: 'wax.eosrio.io',
            }, {
                protocol: "https",
                port: 443,
                host: 'eu.wax.eosrio.io',
            }, {
                protocol: "https",
                port: 443,
                host: 'wax.api.eosnation.io',
            }, {
                protocol: "https",
                port: 443,
                host: 'wax.dfuse.eosnation.io',
            }, {
                protocol: "https",
                port: 443,
                host: 'api.waxsweden.org',
            }, {
                protocol: "https",
                port: 443,
                host: 'wax.eosusa.io',
            }, {
                protocol: "https",
                port: 443,
                host: 'api.wax.alohaeos.com',
            }],
        aaEndpoints: [{
            protocol: "https",
            port: 443,
            host: 'wax.api.atomicassets.io',
        },
        {
            protocol: "https",
            port: 443,
            host: 'wax-atomic-api.eosphere.io',
        },
        // {
        //     protocol: "https",
        //     port: 443,
        //     host: 'aa-wax-public1.neftyblocks.com',
        // },
        {
            protocol: "https",
            port: 443,
            host: 'aa.dapplica.io',
        },
        // {
        //     protocol: "https",
        //     port: 443,
        //     host: 'api.atomic.greeneosio.com',
        // },
        {
            protocol: "https",
            port: 443,
            host: 'atomic.wax.eosrio.io',
        },
        // {
        //     protocol: "https",
        //     port: 443,
        //     host: 'wax-aa.eu.eosamsterdam.net',
        // },
        {
            protocol: "https",
            port: 443,
            host: 'atomic-wax-mainnet.wecan.dev',
        },
            // {
            //     protocol: "https",
            //     port: 443,
            //     host: 'atomic.sentnl.io',
            // },
            // {
            //     protocol: "https",
            //     port: 443,
            //     host: 'atomic.ledgerwise.io',
            // },
            // {
            //     protocol: "https",
            //     port: 443,
            //     host: 'api-wax-aa.eosarabia.net',
            // },
            // {
            //     protocol: "https",
            //     port: 443,
            //     host: 'api.wax-aa.bountyblok.io',
            // }
        ]
    },
    {
        chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
        name: 'WAX (Testnet)',
        rpcEndpoints: [{
            protocol: "https",
            port: 443,
            host: 'waxtestnet.greymass.com',
        }],
        aaEndpoints: [{
            protocol: "https",
            port: 443,
            host: 'test.wax.api.atomicassets.io',
        }]
    }
]

export {
    blockchains
}

