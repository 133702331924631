const taco = {
    TOKEN_CONTRACT: "t.taco",
    TOKEN_TRANSFER_ACTION: "transfer",
    COLLECTION_NAME: "taco",
    SHING_SYMBOL: "SHING",
    ONI_SYMBOL: "ONI",
    AUTO_KIOSK_CONTRACT: "autotacokios",
    STOCKS_TABLE: "stocks",
    KIOSKS_TABLE: "kiosks",
    SUPPLIERS_TABLE: "suppliers",
    INGREDIENTS_TABLE: "ingredients",
    MAIN_KIOSK_ID: "1099847847495",
    VENUE: {
        VENUE_CONTRACT: "v.taco",
        VENUES_TABLE: "venues",
        SPONSORSHIPS_TABLE: "sponsorships",
    },
    COMBZ: {
        COMBZ_CONTRACT: "combz.taco",
        MATERIAL_IDS: {
            HON: 0,
            HYPERIUM: 1,
            NOVACOMB: 2,
            OXENORITE: 3,
        },
        CONFSC_TABLE: "confsc",
        CONFCF_TABLE: "confcf",
        CHAINFIELDS_TABLE: "chainfields",
        USERS_TABLE: "users",
        SPACECOMBS_TABLE: "spacecombs",
        WORKS_TABLE: "works",
        CLAIM_FREE_RESOURCES_ACTION: "claimfaucet",
        CHAINFIELD_REPAIR_ACTION: "repair",
        CHAINFIELD_CLAIM_ACTION: "claim",
        CHAINFIELD_STARTWORK_ACTION: "startwork",
    },
    localStorageKeys: {
        CHAINFIELD_ITEMS: "CHAINFIELD_ITEMS",
        COMBZ_USER: "COMBZ_USER",
    }
};
export default taco;