import axios from "axios";
import { JsonRpc } from "enf-eosjs";
import constants from "../constants/index";
import localStore from "../utils/localStore";
import { GetInventoriesProps } from "./interfaces/bot";
import { AARequestProps, TableRowProps } from "./interfaces/index";

const getTableRows = async ({
    next_key = null,
    table = "",
    key_type = "i64",
    code = "",
    scope = "",
    limit = 100,
    reverse = false,
    show_payer = false,
}: TableRowProps) => {
    let current_RPC: any = localStore.getItem(constants.localStorageKeys.current_RPC);
    const rpc = new JsonRpc(current_RPC);
    return await rpc.get_table_rows({
        json: true, // Get the response as json
        code, // Contract that we target
        scope, // scope
        table, // Tablename
        key_type,
        limit,
        reverse, // Optional: Get reversed data
        show_payer,
        lower_bound: next_key // Optional: Show ram payer
    })
};

const getAllTableRows = async ({
    table = "",
    key_type = "i64",
    code = "",
    scope = "",
    limit = 100,
    reverse = false,
    show_payer = false
}: any) => {
    let current_RPC: any = localStore.getItem(constants.localStorageKeys.current_RPC);
    const rpc = new JsonRpc(current_RPC);
    let items = await rpc.get_table_rows({
        json: true, // Get the response as json
        code, // Contract that we target
        scope, // scope
        table, // Tablename
        key_type,
        limit, // Here we limit to 1 to get only the single row with primary key equal to 'testacc'
        reverse, // Optional: Get reversed data
        show_payer,
    })
    let { rows: data, next_key } = items;
    while (next_key) {
        let items = await rpc.get_table_rows({
            json: true, // Get the response as json
            code, // Contract that we target
            scope, // scope
            table, // Tablename
            key_type,
            limit, // Here we limit to 1 to get only the single row with primary key equal to 'testacc'
            lower_bound: next_key,
            reverse, // Optional: Get reversed data
            show_payer,
        })
        next_key = items.next_key;
        data = [...data, ...items.rows];
    }
    return { rows: data }
};

const getTableRow = async ({
    table = "",
    key_type = "i64",
    code = "",
    scope = "",
    lower_bound = "",
}: TableRowProps) => {
    let current_RPC: any = localStore.getItem(constants.localStorageKeys.current_RPC);
    const rpc = new JsonRpc(current_RPC);
    return await rpc.get_table_rows({
        json: true, // Get the response as json
        code, // Contract that we target
        scope, // scope
        table, // Tablename
        key_type,
        limit: 1, // Here we limit to 1 to get only the single row with primary key equal to 'testacc'
        reverse: false, // Optional: Get reversed data
        show_payer: false,
        lower_bound: lower_bound, // Optional: Show ram payer
        upper_bound: lower_bound
    })
};

const getDataViaAA = async ({
    method = "GET",
    params = null,
    payload = null,
}: AARequestProps) => {
    let current_AA: any = localStore.getItem(constants.localStorageKeys.current_AA);
    return await axios({
        method,
        url: `${current_AA}/atomicassets/v1/${params}`,
        data: payload,
    });
};

const getAccountStats = async (wallet: string, collection_name: string) => {
    let params = `accounts/${wallet}/${collection_name}`;
    return await getDataViaAA({ params });
};

const getAssetsAAData = async (asset_ids: any) => {
    let params = `assets/?ids=${asset_ids.join(",")}&sort=asset_id`;
    return await getDataViaAA({ params });
};

const getAccountIngredients = async (wallet: string, template_id: number) => {
    let params = `assets?template_id=${template_id}&owner=${wallet}&limit=1000&container=1&sort=asset_id`;
    return await getDataViaAA({ params });
};

const getInventories = async ({ code, table, owner }: GetInventoriesProps) => {
    // animal, buildout, flower, plant
    let current_RPC: any = localStore.getItem(constants.localStorageKeys.current_RPC);
    const rpc = new JsonRpc(current_RPC);
    let items = await rpc.get_table_rows({
        json: true, // Get the response as json
        code: code, // Contract that we target
        scope: code, // scope
        table, // Tablename
        key_type: "i64",
        index_position: 2, // byname
        lower_bound: owner,
        limit: 1000, // Here we limit to 1 to get only the single row with primary key equal to 'testacc'
        reverse: false, // Optional: Get reversed data
        show_payer: false, // Optional: Show ram payer
    });
    let { rows: data, next_key } = items;
    while (next_key !== "" && data[data.length - 1].owner === owner) {
        let items = await rpc.get_table_rows({
            json: true, // Get the response as json
            code: code, // Contract that we target
            scope: code, // scope
            table, // Tablename
            key_type: "i64",
            index_position: 2, // byname
            lower_bound: next_key,
            limit: 1000, // Here we limit to 1 to get only the single row with primary key equal to 'testacc'
            reverse: false, // Optional: Get reversed data
            show_payer: false, // Optional: Show ram payer
        });
        next_key = items.next_key;
        data = [...data, ...items.rows];
    }
    return data.filter((item: any) => item.owner === owner);
};

export {
    getAccountIngredients, getAccountStats, getAllTableRows, getAssetsAAData, getDataViaAA, getInventories, getTableRow, getTableRows
};

