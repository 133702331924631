import { types } from "mobx-state-tree";

export const NFT = types.model("NFT", {
    asset_id: types.string,
    name: types.string,
    image: types.string,
    video: types.string,
    selected: types.boolean,
    template_mint: types.string,
    collection_name: types.string,
})
