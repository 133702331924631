import { types } from "mobx-state-tree";

export const ChainfieldConfigItem = types.model("ChainfieldConfigItem", {
    template_id: types.number,
    label: types.string,
    level: types.number,
    slots: types.array(types.model({
        slot_id: types.number,
        level: types.number
    })),
    max_usage: types.number,
    max_operating_time: types.number, // seconds
    repair_cost: types.number,
})
