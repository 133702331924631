import { types } from "mobx-state-tree";

export const SpacecombConfigItem = types.model("SpacecombConfigItem", {
    template_id: types.number,
    label: types.string,
    level: types.number,
    max_operating_time: types.number, // cap seconds
    input: types.array(types.model({
        material_id: types.number,
        quantity: types.number
    })),
    output: types.array(types.model({
        material_id: types.number,
        quantity: types.number
    })),
})
