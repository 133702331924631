import { types } from "mobx-state-tree";
import { ChainfieldConfigItem } from './chainfieldConfigItem';
import { WorkItem } from './workItem';

export const ChainfieldItem = types.model("ChainfieldItem", {
    asset_id: types.number,
    template_id: types.number,
    uses: types.number,
    metadata: ChainfieldConfigItem,
    ends_in: types.string,
    work: WorkItem,
    duration: types.number,
    boost_amount: types.number,
    input: types.array(types.model({
        first: types.number,
        second: types.number,
    })),
    spacecombs: types.array(types.model({
        id: types.number,
        slot_id: types.number,
        level: types.number,
    })),
    sponsor: types.array(types.model({
        first: types.number,
        second: types.array(types.number)
    })),
    is_sponsor_boost: types.boolean,
    is_auto_repair: types.boolean,
    is_auto_production: types.boolean,
    last_tx: types.string,
    last_action: types.string,
    template_mint: types.number,
})
