const map = [
    {
        key: "straw",
        name: "Straw",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Straw&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "drdhmp",
        name: "Dried Hemp",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Dried%20Hemp&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "drdhp",
        name: "Dried Hop",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Dried%20Hop&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "snflwrsds",
        name: "Sunfl. Seeds",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Sunflower%20Seeds&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "drdsffrn",
        name: "Dried Saffron",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Dried%20Saffron&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "drdwht",
        name: "Dried Wheat",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Dried%20Wheat&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "whitesugar",
        name: "White Sugar",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=White%20Sugar&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "brownsugar",
        name: "Brown Sugar",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Brown%20Sugar&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "whtsgrpwdr",
        name: "White Powder",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=White%20Powder&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "brwnsgrpwdr",
        name: "Brown Powder",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Brown%20Powder&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "crnsyrp",
        name: "Corn Syrup",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Corn%20Syrup&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "sgrsyrp",
        name: "Sugar Syrup",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Sugar%20Syrup&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "hm",
        name: "Ham",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Ham&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "prkgrndmt",
        name: "Ground Meat",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Ground%20Meat&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "mrntdhm",
        name: "Marinated Ham",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Marinated%20Ham&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "mrntdtndrlns",
        name: "Tenderloins",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Tenderloins&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "bcn",
        name: "Bacon",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Bacon&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "slm",
        name: "Salami",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Salami&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "prkcrcklngs",
        name: "Cracklings",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cracklings&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "redwine",
        name: "Red Wine",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Red%20Wine&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "whitewine",
        name: "White Wine",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=White%20Wine&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "grappa",
        name: "Grappa",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Grappa&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "whsky",
        name: "Whiskey",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Whiskey&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "blbrrybrggt",
        name: "Berry Braggot",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Barry%20Braggot&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "tmtsc",
        name: "Tomato Sauce",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Tomato%20Sauce&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "pchmrmld",
        name: "Peach Jam",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Peach%20Jam&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "blbrrymrmld",
        name: "Blueberry Jam",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Blueberry%20Jam&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "royaljelly",
        name: "Royal Jelly",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Royal%20Jelly&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "pchcnds",
        name: "Peach Candie",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Peach%20Candie&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cake",
        name: "Cake",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cake&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cbdblbrrysmt",
        name: "CBD Smoothie",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=CBD%20Smoothie&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "beer",
        name: "Beer",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Beer&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "mead",
        name: "Mead",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Mead&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "scrmbldgsggs",
        name: "Scrambled Egg",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Scrambled%20Eggs&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "bldgsggs",
        name: "Boiled Eggs",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Boiled%20Eggs&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "omltt",
        name: "Omelette",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Omelette&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "crls",
        name: "Cereals",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cereals&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cwchsomltt",
        name: "Ch. Omelette",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cheese%20Omelette&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "goatcheese",
        name: "Goat Cheese",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Goat%20Cheese&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cowcheese",
        name: "Cow Cheese",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cow%20Cheese&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "yogurt",
        name: "Yogurt",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Yogurt&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "butter",
        name: "Butter",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Butter&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "rylllyygrt",
        name: "Jelly Yogurt",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Jelly%20Yogurt&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "sffrnccrm",
        name: "Ice Cream II",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Ice%20Cream%20II&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "pchccrm",
        name: "Ice Cream I",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Ice%20Cream%20I&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "bllfwl",
        name: "Ball of Wool",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Ball%20of%20Wool&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "shirt",
        name: "Shirt",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Shirt&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "worktrousers",
        name: "Work Trousers",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Work%20Trousers&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "strawhat",
        name: "Straw Hat",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Straw%20Hat&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "frmngclths",
        name: "Clothes",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Clothes&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "scrcrw",
        name: "Scarecrow",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Scarecrow&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "gelatine",
        name: "Gelatine",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Gelatine&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "fishfillet",
        name: "Fish Fillet",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Fish%20Fillet&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "tncn",
        name: "Tuna Can",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Tuna%20Can&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "tnsld",
        name: "Tuna Salad",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Tuna%20Salad&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "ckdfshfllt",
        name: "Cooked Fillet",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cooked%20Fillet&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "zcchnfshfllt",
        name: "Zucc Fillet",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Zucchini%20Fillet&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "filletpasta",
        name: "Fillet Pasta",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Fillet%20Pasta&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "glzdfshfllt",
        name: "Glazed Fillet",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Glazed%20Fillet&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "glzdshrmp",
        name: "Glazed Shrimp",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Glazed%20Shrimp&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "bread",
        name: "Bread",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Bread&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "chstrts",
        name: "Cheese Tarts",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cheese%20Tarts&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cookies",
        name: "Cookies",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cookies&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "donuts",
        name: "Donuts",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Donuts&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "crssnts",
        name: "Croissant",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Croissant&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "pchmffns",
        name: "Peach Muffin",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Peach%20Muffin&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "blbrryscns",
        name: "Berry Scones",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Berry%20Scones&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "nail",
        name: "Nail",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Nail&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "farmtools",
        name: "Farm Tools",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Farm%20Tools&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "tools",
        name: "Tools",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Tools&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "gt",
        name: "Gate",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Gate&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "hinge",
        name: "Hinge",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Hinge&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "woodenboard",
        name: "Wooden Board",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Wooden%20Board&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "smllfnc",
        name: "Small Fence",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Small%20Fence&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "lrgfnc",
        name: "Large Fence",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Large%20Fence&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "toys",
        name: "Toys",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Toys&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "wagon",
        name: "Wagon",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Wagon&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "oliveoil",
        name: "Olive Oil",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Olive%20Oil&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "seedsoil",
        name: "Seeds Oil",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Seeds%20Oil&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "crnl",
        name: "Corn Oil",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Corn%20Oil&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cbdoil",
        name: "CBD Oil",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=CBD%20Oil&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "sltsnflwrsds",
        name: "Salty Seeds",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Salty%20Seeds&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "mynns",
        name: "Mayonnaise",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Mayonnaise&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "frznblbrry",
        name: "Frozen Berries",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Frozen%20Berries&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "mxdsnck",
        name: "Mixed Snack",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Mixed%20Snack&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "prkjrky",
        name: "Pork Jerky",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Pork%20Jerky&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "pst",
        name: "Pasta",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Pasta&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "nstntcrrtsp",
        name: "Instant Soup",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Instant%20Soup&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "ppprsld",
        name: "Pepper Salad",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Pepper%20Salad&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "vegetablenut",
        name: "Vegetable Nut",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Vegetable%20Nut&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "iron",
        name: "Iron",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Iron&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "steel",
        name: "Steel",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Steel&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "glzdhm",
        name: "Glazed Ham",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Glazed%20Ham&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "pzzslm",
        name: "Pizza Salami",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Pizza%20Salami&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "zcchnndls",
        name: "Zucchini Noodles",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Zucchini%20Noodles&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "stffdpppr",
        name: "Stuffed Pepper",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Stuffed%20Pepper&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "deviledeggs",
        name: "Deviled Eggs",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Deviled%20Eggs&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "flour",
        name: "Flour",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Flour&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cornflakes",
        name: "Corn Flakes",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Corn%20Flakes&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "lumber",
        name: "Lumber",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Lumber&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "chlpwdr",
        name: "Chili Powder",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Chili%20Powder&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "saffronpowdr",
        name: "Saffron Powder",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Saffron%20Powder&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "rp",
        name: "Rope",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Rope&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "redwine",
        name: "Red Wine",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Red%20Wine&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "whitewine",
        name: "White Wine",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=White%20Wine&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "grappa",
        name: "Grappa",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Grappa&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "whsky",
        name: "Whiskey",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Whiskey&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "blbrrybrggt",
        name: "Berry Braggot",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Barry%20Braggot&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "nail",
        name: "Nail",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Nail&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "farmtools",
        name: "Farm Tools",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Farm%20Tools&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "tools",
        name: "Tools",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Tools&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "gt",
        name: "Gate",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Gate&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "hinge",
        name: "Hinge",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Hinge&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "blueberry",
        name: "Blueberry",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Blueberry&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "carrot",
        name: "Carrot",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Carrot&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "coal",
        name: "Coal",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Coal&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "corn",
        name: "Corn",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Corn&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "cowmilk",
        name: "Cow Milk",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Cow%20Milk&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "egg",
        name: "Egg",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Egg&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "fish",
        name: "Fish",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Fish&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "goatmilk",
        name: "Goat Milk",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Goat%20Milk&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "gooseegg",
        name: "Goose Egg",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Goose%20Egg&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "grapes",
        name: "Grapes",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Grapes&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "grass",
        name: "Grass",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Grass&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "hemp",
        name: "Hemp",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Hemp&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "honey",
        name: "Honey",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Honey&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "hop",
        name: "Hop",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Hop&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "hotpepper",
        name: "Hot Pepper",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Hot%20Pepper&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "ironchunk",
        name: "Iron Chunk",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Iron%20Chunk&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "milk",
        name: "Milk",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Milk&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "olives",
        name: "Olives",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Olives&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "onion",
        name: "Onion",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Onion&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "peach",
        name: "Peach",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Peach&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "pepper",
        name: "Pepper",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Pepper&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "porkmeat",
        name: "Pork Meat",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Pork%20Meat&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "saffron",
        name: "Saffron",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Saffron&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "sugarbeet",
        name: "Sugar Beet",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Sugar%20Beet&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "sugarcane",
        name: "Sugar Cane",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Sugar%20Cane&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "sunflower",
        name: "Sunflower",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Sunflower&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "tomato",
        name: "Tomato",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Tomato&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "wheat",
        name: "Wheat",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Wheat&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "wood",
        name: "Wood",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Wood&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "wool",
        name: "Wool",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Wool&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    },
    {
        key: "zucchini",
        name: "Zucchini",
        atomichub: "https://wax.atomichub.io/market?collection_name=farmingtales&immutable_data:text.product=Zucchini&order=desc&schema_name=game.boxes&sort=created&state=1&symbol=WAX"
    }
]

export const getName = (key: string) => {
    let item = map.find(item => item.key === key)
    if (item) {
        return item.name;
    } else {
        return key;
    }
}

export const getUrl = (key: string) => {
    let item = map.find(item => item.key === key)
    if (item) {
        return item.atomichub;
    } else {
        return "https://wax.atomichub.io/market?collection_name=farmingtales&order=asc&sort=price&symbol=WAX";
    }
}

export default map;