import { types } from "mobx-state-tree";
import { SpacecombConfigItem } from './spacecombConfigItem';
import { WorkItem } from './workItem';

export const SpacecombItem = types.model("SpacecombItem", {
    asset_id: types.number,
    template_id: types.number,
    operating_time: types.number, // used seconds
    working: types.boolean,
    selected: types.boolean,
    metadata: SpacecombConfigItem,
    work: WorkItem
})
