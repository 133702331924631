import { cast, flow, types } from "mobx-state-tree";
import SELL_BOT_CONSTANTS from "../../../../constants/sellbot";
import * as ContractKit from '../../../../services/contractKit';
import { DepositedNFT } from "../../domain/depositedNFT";

const wait = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

const getNFTStatus = (nft: any) => {
    if (Date.parse(nft.sold_at + "Z") > 0) {
        return 1;
    }
    if (nft.asset_returned) {
        return 2;
    }
    return 0;
};

const SellbotWithdraw = types
    .model("SellbotWithdraw", {
        isLoading: true,
        nfts: types.array(DepositedNFT),
        isSelectedAll: false,
    })
    .views((self) => ({}))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        function selectItem(asset_id: number) {
            self.nfts = cast(self.nfts.map((nft: any) => {
                if (nft.asset_id === asset_id) {
                    nft.selected = !nft.selected;
                }
                return nft;
            }));
        }

        function selectAllItems() {
            self.isSelectedAll = !self.isSelectedAll;
            self.nfts = cast(self.nfts.map((nft: any) => {
                if (nft.status === 0) {
                    nft.selected = self.isSelectedAll;
                }
                return nft;
            }));
        }

        const loadItems = flow(function* (owner: string) {
            try {
                markLoading(true);
                const itemsCursor: any = yield ContractKit.getMultiDataCursor(
                    SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                    SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                    SELL_BOT_CONSTANTS.ITEMS_TABLE,
                    {
                        maxRows: 1000,
                        index_position: 4,
                        key_type: "i64",
                        from: owner,
                        to: owner,
                    }
                );

                let items: any = yield itemsCursor.all();

                self.nfts = items.map((item: any) => DepositedNFT.create({
                    asset_id: parseInt(item.asset_id),
                    owner: String(item.owner),
                    sale_id: parseInt(item.sale_id),
                    price_updated_at: Date.parse(item.price_updated_at + "Z"),
                    sold_at: Date.parse(item.sold_at + "Z"),
                    listed_price: String(item.listed_price),
                    wax_returned: String(item.wax_returned),
                    asset_returned: item.asset_returned,
                    asset_returned_at: Date.parse(item.asset_returned_at + "Z"),
                    price_edited_amount: parseInt(item.price_edited_amount),
                    default_price: String(item.default_price),
                    exist_until: Date.parse(item.exist_until + "Z"),
                    asset_name: String(item.asset_name),
                    asset_media: String(item.asset_media),
                    status: getNFTStatus(item),
                }));
                self.isSelectedAll = false;
                markLoading(false);

            } catch (err) {
                console.log("err", err);
            }
        })

        const withdraw = flow(function* (session: any) {
            try {
                let asset_ids = self.nfts.filter((nft: any) => nft.selected).map((nft: any) => nft.asset_id);

                let res = yield session.transact(
                    {
                        actions: [
                            {
                                account: SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                                name: SELL_BOT_CONSTANTS.WITHDRAWNFT_ACTION,
                                authorization: [session.permissionLevel],
                                data: {
                                    asset_ids
                                },
                            },
                        ],
                    }
                );
                return res;

            }
            catch (err) {
                console.log("err", err);

            }
        });

        return {
            markLoading,
            loadItems,
            selectItem,
            selectAllItems,
            withdraw,
        }
    })

export const sellbotWithdrawStore = SellbotWithdraw.create({
    isLoading: true,
    nfts: [],
    isSelectedAll: false,
})