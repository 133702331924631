import { Chains, Session, SessionKit } from "@wharfkit/session";
import { TransactPluginAutoCorrect } from '@wharfkit/transact-plugin-autocorrect';
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCleos } from "@wharfkit/wallet-plugin-cleos";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WalletPluginTokenPocket } from "@wharfkit/wallet-plugin-tokenpocket";
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat";


import { WebRenderer } from "@wharfkit/web-renderer";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import constants from "./constants/index";
import { parsecStore } from './features/parsec/presentation/controller';
import { MainLayout } from './layouts/MainLayout';
import localStore from "./utils/localStore";

const appName = 'waximus';

let sessionKit = new SessionKit({
  appName: appName,
  chains: [Chains.WAX],
  ui: new WebRenderer(),
  walletPlugins: [
    new WalletPluginCloudWallet(),
    new WalletPluginAnchor(),
    new WalletPluginWombat(),
    new WalletPluginTokenPocket(),
    new WalletPluginCleos()]
}, { transactPlugins: [new TransactPluginAutoCorrect()] })


function App({ ...props }) {
  const [session, setSession]: [Session | undefined, Dispatch<SetStateAction<Session | undefined>>] = useState()
  const [walletSessions, setSessions]: [Session[], Dispatch<SetStateAction<Session[]>>] = useState<Session[]>([]);

  useEffect(() => {
    sessionKit.restoreAll().then((restored) => {
      setSessions(restored);
      setSession(restored[0]);
    })
  }, [])

  async function login() {
    const response = await sessionKit.login()
    setSession(response.session)
    setSessions([response.session])
  }

  async function logout() {
    sessionKit.logout();
    setSession(undefined);
    setSessions([]);
    // clear parsec store
    parsecStore.clearLocalStorage();
  }

  async function addAccount() {
    const response = await sessionKit.login();
    // add new session to the walletSessions list
    setSessions([...walletSessions, response.session]);
  }

  async function removeAccount(remove_session: Session) {
    sessionKit.logout(remove_session)
    // remove session from the walletSessions list
    let newWalletSessions = walletSessions.filter((s) => s !== remove_session);
    setSessions(newWalletSessions);

    // if walletSessions is empty, set the current session to undefined
    if (newWalletSessions.length === 0) {
      setSession(undefined);
      setSessions([]);
      parsecStore.clearLocalStorage();
    } else {
      // if the removed session is the current session, set the first session in the list as the current session
      if (remove_session.actor === session?.actor) {
        setSession(newWalletSessions[0]);
      }
      parsecStore.clearLocalStorage(String(remove_session.actor))
    }
  }

  async function switchAccount(session: Session) {
    setSession(session);
    // move the session to the first position in the list
    let newWalletSessions = walletSessions.filter((s) => s !== session);
    newWalletSessions.unshift(session);
    setSessions(newWalletSessions);
  }

  async function updateSessionKit(newRPC: string) {
    sessionKit = new SessionKit({
      appName: appName,
      chains: [{ id: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4", url: newRPC }],
      ui: new WebRenderer(),
      walletPlugins: [
        new WalletPluginCloudWallet(),
        new WalletPluginAnchor(),
        new WalletPluginWombat(),
        new WalletPluginTokenPocket(),
        new WalletPluginCleos()]
    }, { transactPlugins: [new TransactPluginAutoCorrect()] })
    await sessionKit.restoreAll().then((restored) => {
      setSessions(restored);
      setSession(restored[0]);
    });
  }


  let current_RPC = localStore.getItem(constants.localStorageKeys.current_RPC);
  if (!current_RPC) {
    // set default RPC
    current_RPC = `${props.rpcEndpoints[0].protocol}://${props.rpcEndpoints[0].host}`;
    localStore.setItem(constants.localStorageKeys.current_RPC, current_RPC);
  }

  let current_AA = localStore.getItem(constants.localStorageKeys.current_AA);
  if (!current_AA) {
    // set default AA
    current_AA = `${props.aaEndpoints[0].protocol}://${props.aaEndpoints[0].host}`;
    localStore.setItem(constants.localStorageKeys.current_AA, current_AA);
  }

  return (
    <BrowserRouter {...props} >
      <MainLayout {...props}
        session={session}
        walletSessions={walletSessions}
        login={login}
        logout={logout}
        updateSessionKit={updateSessionKit}
        addAccount={addAccount}
        removeAccount={removeAccount}
        switchAccount={switchAccount}
      />
    </BrowserRouter>

  );
}

export default App;
