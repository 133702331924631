import { types } from "mobx-state-tree";

export const Setting = types.model("Setting", {
    percent_cut: types.number,
    items_cap_per_user: types.number,
    sale_cap_per_user: types.number,
    price_update_cap: types.number,
    item_life_day: types.number,
    maintenance: types.boolean
})
