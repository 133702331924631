

import _ from 'lodash';
import { toJS, values } from 'mobx';
import { cast, flow, types } from "mobx-state-tree";
import tacoConstants from "../../../../constants/taco/index";
import { getAssetsAAData } from '../../../../services/api';
import { cursorAll, getMultiDataCursor, getSingleData } from '../../../../services/contractKit';
import localStore from '../../../../utils/localStore';
import { ChainfieldConfigItem } from "../../domain/combz/chainfieldConfigItem";
import { ChainfieldItem } from '../../domain/combz/chainfieldItem';
import { SpacecombConfigItem } from "../../domain/combz/spacecombConfigItem";
import { SpacecombItem } from '../../domain/combz/spacecombItem';
import { UserItem } from '../../domain/combz/userItem';
import { WorkItem } from '../../domain/combz/workItem';




const Combz = types
    .model("Combz", {
        isLoading: true,
        isAuto: false,
        isAutoCollectFreeRes: false,
        log: types.string,
        activeAccount: types.string,
        isActiveMember: types.boolean,
        chainfieldCfgItems: types.array(ChainfieldConfigItem),
        spacecombCfgItems: types.array(SpacecombConfigItem),

        ONI_balance: types.number,
        user: UserItem,
        chainfields: types.array(ChainfieldItem),
        spacecombs: types.array(SpacecombItem),
        works: types.array(WorkItem),
        is_all_auto_sponsor: types.boolean,
        is_all_auto_repair: types.boolean,
        is_all_auto_production: types.boolean,
        countdown: types.boolean,
        min_tx_delay_seconds: types.number,
        max_tx_delay_seconds: types.number,
    })
    .views((self: any) => ({

    }))
    .actions((self: any) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        function setActiveAccount(account: string) {
            self.activeAccount = account;
        }

        function retrieveLocalStorage() {
            let chainfield_items_local: any = (localStore.getItem(tacoConstants.localStorageKeys.CHAINFIELD_ITEMS));

            if (chainfield_items_local) {
                chainfield_items_local = JSON.parse(chainfield_items_local);
                if (chainfield_items_local[self.activeAccount]) {
                    // TODO: remove this after few versions
                    for (let chainfield of chainfield_items_local[self.activeAccount]) {
                        if (!chainfield.template_mint) {
                            chainfield.template_mint = 0;
                        }
                    }
                    self.chainfields = cast(chainfield_items_local[self.activeAccount]);
                }
            }

            let combz_user_local: any = (localStore.getItem(tacoConstants.localStorageKeys.COMBZ_USER));
            if (combz_user_local) {
                combz_user_local = JSON.parse(combz_user_local);
                if (combz_user_local[self.activeAccount]) {
                    self.isAutoCollectFreeRes = combz_user_local[self.activeAccount].isAutoCollectFreeRes;
                }
            }

        }

        function saveLocalStorage() {
            // save local storage

            let chainfield_items_local: any = (localStore.getItem(tacoConstants.localStorageKeys.CHAINFIELD_ITEMS));


            chainfield_items_local = chainfield_items_local ? JSON.parse(chainfield_items_local) : {};


            chainfield_items_local[self.activeAccount] = values(self.chainfields);


            localStore.setItem(tacoConstants.localStorageKeys.CHAINFIELD_ITEMS, JSON.stringify(chainfield_items_local));

            let combz_user_local: any = (localStore.getItem(tacoConstants.localStorageKeys.COMBZ_USER));
            combz_user_local = combz_user_local ? JSON.parse(combz_user_local) : {};
            combz_user_local[self.activeAccount] = {
                isAutoCollectFreeRes: self.isAutoCollectFreeRes,
            };
            localStore.setItem(tacoConstants.localStorageKeys.COMBZ_USER, JSON.stringify(combz_user_local));


        }

        function setAutoCollectFreeRes(flag: boolean) {
            self.isAutoCollectFreeRes = flag;
            saveLocalStorage();
        }

        function setIsAuto(is_auto: any) {
            self.isAuto = is_auto;
        }

        function setChainFieldCfgItems(items: any) {
            let chainfieldCfgItems: any = [];
            for (let item of items) {
                chainfieldCfgItems.push({
                    template_id: parseInt(item.template_id),
                    label: item.label,
                    level: parseInt(item.level),
                    slots: item.slots.map((slot: any) => {
                        return {
                            slot_id: parseInt(slot.slot_id),
                            level: parseInt(slot.level),
                        }
                    }),
                    max_usage: parseInt(item.max_usage),
                    max_operating_time: parseInt(item.max_operating_time),
                    repair_cost: parseFloat(item.repair_cost),

                });
            }
            self.chainfieldCfgItems = chainfieldCfgItems;
        }

        function setSpacecombCfgItems(items: any) {

            let spacecombCfgItems: any = [];
            for (let item of items) {
                spacecombCfgItems.push({
                    template_id: parseInt(item.template_id),
                    label: item.label,
                    level: parseInt(item.level),
                    max_operating_time: parseInt(item.max_operating_time),
                    input: item.input.map((input: any) => {
                        return {
                            material_id: parseInt(input.first),
                            quantity: parseFloat(input.second),
                        }
                    }),
                    output: item.output.map((output: any) => {
                        return {
                            material_id: parseInt(output.first),
                            quantity: parseFloat(output.second),
                        }
                    }),
                });
            }
            self.spacecombCfgItems = spacecombCfgItems;
        }

        function setUserMaterials(data: any) {
            let last_claim = new Date(parseInt(data.last_claim) * 1000);
            let materials: any = [];
            for (let material of data.materials) {
                materials.push({
                    material_id: parseInt(material.first),
                    value: parseFloat(material.second),
                });
            }
            self.user = {
                last_claim: last_claim,
                materials: materials,
            }
        }

        function setChainfields(data: any) {
            for (let item of data) {

                let config = toJS(self.chainfieldCfgItems.find((cfg_item: any) => {
                    return cfg_item.template_id === parseInt(item.template_id);
                }));
                let work = toJS(self.works.find((work: any) => {
                    return work.chainfield_id === parseInt(item.asset_id);
                }));

                if (!work) {
                    work = {
                        chainfield_id: 0,
                        spacecombs: [],
                        input: [],
                        output: [],
                        start: new Date(0),
                        end: new Date(0),
                        boost: 0,
                        sponsor: [],
                        event_ids: [],
                    }
                }
                let item_index = _.findIndex(self.chainfields, (chainfield: any) => { return chainfield.asset_id === parseInt(item.asset_id); });
                if (item_index === -1) {
                    self.chainfields.push({
                        asset_id: parseInt(item.asset_id),
                        template_id: parseInt(item.template_id),
                        uses: parseInt(item.uses),
                        metadata: config,
                        work,
                        is_sponsor_boost: false,
                        is_auto_repair: false,
                        is_auto_production: false,
                        duration: 0,
                        boost_amount: 0,
                        input: [],
                        spacecombs: [],
                        sponsor: [],
                        last_tx: "",
                        last_action: "",
                        ends_in: "00h 00m 00s",
                        template_mint: parseInt(item.template_mint),
                    });
                } else {
                    self.chainfields[item_index] = {
                        asset_id: parseInt(item.asset_id),
                        template_id: parseInt(item.template_id),
                        uses: parseInt(item.uses),
                        metadata: config,
                        work,
                        is_sponsor_boost: self.chainfields[item_index].is_sponsor_boost,
                        is_auto_repair: self.chainfields[item_index].is_auto_repair,
                        is_auto_production: self.chainfields[item_index].is_auto_production,
                        duration: self.chainfields[item_index].duration,
                        boost_amount: self.chainfields[item_index].boost_amount,
                        input: toJS(self.chainfields[item_index].input),
                        spacecombs: toJS(self.chainfields[item_index].spacecombs),
                        sponsor: toJS(self.chainfields[item_index].sponsor),
                        last_tx: self.chainfields[item_index].last_tx,
                        last_action: self.chainfields[item_index].last_action,
                        ends_in: self.chainfields[item_index].ends_in,
                        template_mint: parseInt(item.template_mint),
                    };
                }
            }

            // remove chainfields that are not in data
            self.chainfields = self.chainfields.filter((chainfield: any) => {
                return data.find((item: any) => {
                    return parseInt(item.asset_id) === chainfield.asset_id;
                });
            });
        }

        function setSpacecombs(data: any) {
            let spacecombs: any = [];
            for (let item of data) {
                let config = toJS(self.spacecombCfgItems.find((cfg_item: any) => {
                    return cfg_item.template_id === parseInt(item.template_id);
                }));
                let work = toJS(self.works.find((work: any) => {
                    return work.spacecombs.find((spacecomb: any) => {
                        return spacecomb.asset_id === parseInt(item.asset_id);
                    });
                }));
                if (!work) {
                    work = {
                        chainfield_id: 0,
                        spacecombs: [],
                        input: [],
                        output: [],
                        start: new Date(0),
                        end: new Date(0),
                        boost: 0,
                        sponsor: [],
                        event_ids: [],
                    }
                }
                spacecombs.push({
                    asset_id: parseInt(item.asset_id),
                    template_id: parseInt(item.template_id),
                    operating_time: parseInt(item.operating_time),
                    working: item.working,
                    selected: false,
                    metadata: config,
                    work,
                });
            }

            // Loop through chainfields and add/remove working spacecombs
            for (let chainfield of self.chainfields) {
                let working_spacecombs = spacecombs.filter((spacecomb: any) => {
                    return spacecomb.work.chainfield_id === chainfield.asset_id;
                });

                // if chainfield is working => update spacecombs
                if (chainfield.work.chainfield_id > 0) {
                    chainfield.spacecombs = working_spacecombs.map((spacecomb: any) => {
                        let work_spacecomb = spacecomb.work.spacecombs.find((slot: any) => {
                            return slot.asset_id === spacecomb.asset_id;
                        });
                        return {
                            id: spacecomb.asset_id,
                            slot_id: work_spacecomb.slot_id,
                            level: work_spacecomb.level,
                        }
                    });
                } else {
                    // filter out spacecombs that doesnt exist in spacecombs array
                    chainfield.spacecombs = chainfield.spacecombs.filter((spacecomb: any) => {
                        return spacecombs.find((item: any) => {
                            return item.asset_id === spacecomb.id;
                        });
                    });
                }

                // calculate input
                let input: any = [];
                for (let spacecomb of chainfield.spacecombs) {
                    let spacecomb_ins = spacecombs.find((ins: any) => {
                        return ins.asset_id === spacecomb.id;
                    });



                    // // replace spacecomb from chainfield.spacecombs if operating time >= max_operating_time with another spacecomb with same level, same template_id
                    // if (spacecomb_ins && spacecomb_ins.operating_time >= spacecomb_ins.metadata.max_operating_time) {

                    //     // find another spacecomb with same level and same template_id
                    //     let another_spacecomb = spacecombs.find((ins: any) => {
                    //         return ins.metadata.level === spacecomb_ins.metadata.level && ins.template_id === spacecomb_ins.template_id && ins.operating_time < ins.metadata.max_operating_time;
                    //     });

                    //     // if found => replace
                    //     if (another_spacecomb) {
                    //         chainfield.spacecombs = chainfield.spacecombs.map((item: any) => {
                    //             if (item.id === spacecomb.id) {
                    //                 return {
                    //                     id: another_spacecomb.asset_id,
                    //                     slot_id: item.slot_id,
                    //                     level: item.level,
                    //                 }
                    //             } else {
                    //                 return item;
                    //             }
                    //         });
                    //     } else {
                    //         // if not found => set chainfield auto production to false so user can select another spacecomb
                    //         chainfield.is_auto_production = false;
                    //         break;
                    //     }

                    // }

                    for (let item of spacecomb_ins.metadata.input) {
                        let input_item = input.find((input_item: any) => {
                            return input_item.first === item.material_id;
                        });
                        if (input_item) {
                            input_item.second += (item.quantity * (chainfield.duration > 0 ? chainfield.duration : chainfield.metadata.max_operating_time / 3600));
                        } else {
                            input.push({
                                first: item.material_id,
                                second: (item.quantity * (chainfield.duration > 0 ? chainfield.duration : chainfield.metadata.max_operating_time / 3600)),
                            });
                        }
                    }
                }
                chainfield.input = input;
            }
            self.spacecombs = spacecombs;
            saveLocalStorage();
        }

        function setWorks(data: any) {
            let works: any = [];
            for (let item of data) {
                works.push({
                    chainfield_id: parseInt(item.chainfield_id),
                    spacecombs: item.spacecombs.map((spacecomb: any) => {
                        return {
                            asset_id: parseInt(spacecomb.id),
                            slot_id: parseInt(spacecomb.slot_id),
                            level: parseInt(spacecomb.level),
                        }
                    }),
                    input: item.input.map((input: any) => {
                        return {
                            material_id: parseInt(input.first),
                            quantity: parseFloat(input.second),
                        }
                    }),
                    output: item.output.map((output: any) => {
                        return {
                            material_id: parseInt(output.first),
                            quantity: parseFloat(output.second),
                        }
                    }),
                    start: new Date(parseInt(item.start) * 1000),
                    end: new Date(parseInt(item.end) * 1000),
                    boost: parseInt(item.boost),
                    sponsor: item.sponsor.map((sponsor: any) => {
                        return {
                            first: parseInt(sponsor.first),
                            second: sponsor.second.map((second: any) => {
                                return parseInt(second);
                            })
                        }
                    }),
                    event_ids: item.event_ids.map((event_id: any) => {
                        return parseInt(event_id);
                    }),
                });
            }
            self.works = works;
        }

        const loadUserData = flow(function* (account: string) {
            try {
                setActiveAccount(account);
                retrieveLocalStorage();

                // load ONI balance
                const user_ONI_balance: any = yield getSingleData(
                    tacoConstants.TOKEN_CONTRACT,
                    account,
                    "accounts",
                    tacoConstants.ONI_SYMBOL,
                );
                if (user_ONI_balance) {
                    self.ONI_balance = parseFloat(user_ONI_balance.balance.value);
                }


                // load user works
                const works_cursor: any = yield getMultiDataCursor(
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    account,
                    tacoConstants.COMBZ.WORKS_TABLE,
                );
                const works = yield cursorAll(works_cursor);
                if (works.length > 0) {
                    setWorks(works);
                } else {
                    setWorks([]);
                }

                // load user materials

                const user_materials: any = yield getSingleData(
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    tacoConstants.COMBZ.USERS_TABLE,
                    account,
                );
                if (user_materials) {
                    setUserMaterials(user_materials);
                } else {
                    setUserMaterials({
                        last_claim: 0,
                        materials: [],
                    });
                }


                // load user chainfields

                const chainfields_cursor: any = yield getMultiDataCursor(
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    account,
                    tacoConstants.COMBZ.CHAINFIELDS_TABLE,
                );
                let chainfields = yield cursorAll(chainfields_cursor);
                if (chainfields.length > 0) {
                    const chainfield_ids = chainfields.map((chainfield: any) => {
                        return parseInt(chainfield.asset_id);
                    });
                    const chainfield_AA_data = yield getAssetsAAData(chainfield_ids);
                    for (let chainfield of chainfields) {
                        let chainfield_data = chainfield_AA_data.data.data.find((data: any) => {
                            return parseInt(data.asset_id) === parseInt(chainfield.asset_id);
                        });
                        chainfield.template_mint = chainfield_data.template_mint;
                    }
                    setChainfields(chainfields);
                } else {
                    setChainfields([]);
                }

                // load user spacecombs
                const spacecombs_cursor: any = yield getMultiDataCursor(
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    account,
                    tacoConstants.COMBZ.SPACECOMBS_TABLE,
                );
                const spacecombs = yield cursorAll(spacecombs_cursor);
                if (spacecombs.length > 0) {
                    setSpacecombs(spacecombs);
                } else {
                    setSpacecombs([]);
                }

                // save local storage
                saveLocalStorage();

                markLoading(false);
            } catch (err) {
                console.error("Failed to load Data", err)
            }
        });
        const loadCfgData = flow(function* () {
            try {

                // load chainfield config
                const chainfield_cfg_cursor: any = yield getMultiDataCursor(
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    tacoConstants.COMBZ.CONFCF_TABLE,
                );
                const chainfield_cfg = yield cursorAll(chainfield_cfg_cursor);
                setChainFieldCfgItems(chainfield_cfg);

                // load spacecombs config
                const spacecombs_cfg_cursor: any = yield getMultiDataCursor(
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    tacoConstants.COMBZ.COMBZ_CONTRACT,
                    tacoConstants.COMBZ.CONFSC_TABLE,
                );
                const spacecombs_cfg = yield cursorAll(spacecombs_cfg_cursor);
                setSpacecombCfgItems(spacecombs_cfg);

            } catch (err) {
                console.error("Failed to load Data", err)
            }
        });

        const loadVenueData = flow(function* () {
            try {

                // load sponsorships 
                const sponsorships_cursor: any = yield getMultiDataCursor(
                    tacoConstants.VENUE.VENUE_CONTRACT,
                    tacoConstants.VENUE.VENUE_CONTRACT,
                    tacoConstants.VENUE.SPONSORSHIPS_TABLE,
                );
                const sponsorships = yield cursorAll(sponsorships_cursor);

                // load venues 
                const venues_cursor: any = yield getMultiDataCursor(
                    tacoConstants.VENUE.VENUE_CONTRACT,
                    tacoConstants.VENUE.VENUE_CONTRACT,
                    tacoConstants.VENUE.VENUES_TABLE,
                );
                let venues: any = yield cursorAll(venues_cursor);
                // remove venues that user's not whitelisted 
                venues = venues.filter((venue: any) => {
                    return venue.whitelist.includes(self.activeAccount) || venue.whitelist.length === 0;
                });

                const active_venue = venues.filter((venue: any) => {
                    return venue.active_sponsorship.length > 0;
                });

                const active_venue_ids: any = active_venue.map((venue: any) => {
                    return venue.asset_id;
                });

                //load venue data from atomic api
                const venue_AA_data = active_venue_ids.length > 0 ? yield getAssetsAAData(active_venue_ids) : [];

                let sponsorItems: any = [];

                for (let item of active_venue) {
                    let venue_data = venue_AA_data.data.data.find((venue: any) => {
                        return parseInt(venue.asset_id) === parseInt(item.asset_id);
                    });
                    if (venue_data) {
                        const { name, img } = venue_data?.mutable_data;
                        let venue_sponsorships: any = [];
                        for (let active_sponsorship of item.active_sponsorship) {
                            let sponsorship = sponsorships.find((sponsorship: any) => {
                                return parseInt(sponsorship.id) === parseInt(active_sponsorship.key);
                            });
                            sponsorship.used = parseInt(active_sponsorship.value);
                            sponsorship.key = parseInt(active_sponsorship.key);
                            venue_sponsorships.push(sponsorship);
                        }
                        for (let item_sponsor of venue_sponsorships) {
                            sponsorItems.push({
                                asset_id: parseInt(item.asset_id),
                                key: item_sponsor.key,
                                name: name,
                                image: img,
                                max_uses: parseInt(item_sponsor.max_uses),
                                used: item_sponsor.used,
                                boost: item_sponsor.sponsorship,
                            });
                        }
                    }
                }
                return sponsorItems;


            } catch (err) {
                console.error("Failed to load Data", err)
            }
        });

        function moveChainfieldUp(index: number) {
            let chainfields = toJS(self.chainfields);
            let temp = chainfields[index];
            // ignore if first item
            if (index === 0) {
                return;
            }
            chainfields[index] = chainfields[index - 1];
            chainfields[index - 1] = temp;
            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        function moveChainfieldDown(index: number) {
            let chainfields = toJS(self.chainfields);
            let temp = chainfields[index];
            // ignore if last item
            if (index === chainfields.length - 1) {
                return;
            }
            chainfields[index] = chainfields[index + 1];
            chainfields[index + 1] = temp;
            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        function setDuration(index: number, duration: number) {
            let chainfields = toJS(self.chainfields);
            chainfields[index].duration = duration;
            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        function setBoostAmount(index: number, boost_amount: number) {
            let chainfields = toJS(self.chainfields);
            chainfields[index].boost_amount = boost_amount * 10000;
            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        function setAutoSponsor(index: number, flag: boolean) {
            let chainfields = toJS(self.chainfields);
            if (index === -1) {
                // set all
                chainfields = chainfields.map((chainfield: any) => {
                    chainfield.is_sponsor_boost = flag;
                    return chainfield;
                });
                self.is_all_auto_sponsor = flag;
            } else {
                chainfields[index].is_sponsor_boost = flag;
                if (!flag) {
                    self.is_all_auto_sponsor = false;
                }
            }
            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        function setAutoRepair(index: number, flag: boolean) {
            let chainfields = toJS(self.chainfields);
            if (index === -1) {
                // set all
                chainfields = chainfields.map((chainfield: any) => {
                    chainfield.is_auto_repair = flag;
                    return chainfield;
                });
                self.is_all_auto_repair = flag;
            } else {
                chainfields[index].is_auto_repair = flag;
                if (!flag) {
                    self.is_all_auto_repair = false;
                }
            }
            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        function setAutoProduction(index: number, flag: boolean) {
            let chainfields = toJS(self.chainfields);
            if (index === -1) {
                if (self.isActiveMember) {
                    // set all
                    chainfields = chainfields.map((chainfield: any) => {
                        if (chainfield.spacecombs.length > 0) {
                            chainfield.is_auto_production = flag;
                        }
                        return chainfield;
                    });
                } else {
                    chainfields = chainfields.map((chainfield: any, index: any) => {
                        if (chainfield.spacecombs.length > 0) {
                            chainfield.is_auto_production = index === 0 ? flag : false;
                        }
                        return chainfield;
                    });
                }
                self.is_all_auto_production = flag;
            } else {
                chainfields[index].is_auto_production = flag;
                if (!flag) {
                    self.is_all_auto_production = false;
                }
            }
            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        function setIsActiveMember(flag: boolean) {
            self.isActiveMember = flag;
        }

        function selectSpaceComb(chainfield_index: number, spacecomb: any) {
            let chainfields = toJS(self.chainfields);
            // calculate max slots by each level
            let max_slots_by_level: any = {};
            for (let slot of chainfields[chainfield_index].metadata.slots) {
                if (max_slots_by_level[slot.level]) {
                    max_slots_by_level[slot.level] += 1;
                } else {
                    max_slots_by_level[slot.level] = 1;
                }
            }

            // calculate current slots by each level
            let current_slots_by_level: any = {};
            for (let slot of chainfields[chainfield_index].spacecombs) {
                if (current_slots_by_level[slot.level]) {
                    current_slots_by_level[slot.level] += 1;
                } else {
                    current_slots_by_level[slot.level] = 1;
                }
            }

            // check if spacecomb is already selected => deselect
            let spacecomb_index = _.findIndex(chainfields[chainfield_index].spacecombs, (item: any) => {
                return item.id === spacecomb.asset_id;
            });

            if (spacecomb_index !== -1) {
                // deselect
                // reduce current slots by level
                current_slots_by_level[chainfields[chainfield_index].spacecombs[spacecomb_index].level] -= 1;
                chainfields[chainfield_index].spacecombs.splice(spacecomb_index, 1);

            } else {
                // if not selected => select
                // check if max slots reached
                if (current_slots_by_level[spacecomb.metadata.level] >= max_slots_by_level[spacecomb.metadata.level]) {
                    return;
                } else {
                    // find a slot with level = spacecomb level and not selected
                    let slot = chainfields[chainfield_index].metadata.slots.find((slot: any) => {
                        return slot.level === spacecomb.metadata.level && !chainfields[chainfield_index].spacecombs.find((spacecomb: any) => {
                            return spacecomb.slot_id === slot.slot_id;
                        });
                    });
                    // add to spacecombs
                    chainfields[chainfield_index].spacecombs.push({
                        id: spacecomb.asset_id,
                        slot_id: slot.slot_id,
                        level: spacecomb.metadata.level,
                    });
                    // increase current slots by level
                    current_slots_by_level[spacecomb.metadata.level] += 1;
                }

            }

            self.chainfields = cast(chainfields);
            saveLocalStorage();
        }

        const autoCombz = flow(function* (session: any) {
            try {


                // refresh data
                yield loadUserData(self.activeAccount);
                // auto claim free resources
                if (self.isAutoCollectFreeRes && new Date(self.user.last_claim.getTime() + 24 * 60 * 60 * 1000) < new Date(Date.now())) {
                    let res = yield session.transact({
                        actions: [
                            {
                                account: tacoConstants.COMBZ.COMBZ_CONTRACT,
                                name: tacoConstants.COMBZ.CLAIM_FREE_RESOURCES_ACTION,
                                authorization: [session.permissionLevel],
                                data: {
                                    user: self.activeAccount,
                                }
                            }
                        ]
                    });

                    if (res) {
                        self.log = "Collect free resources";
                        yield waitTxDelay();
                    }
                }

                for (let chainfield of self.chainfields) {
                    let item_index = _.findIndex(self.chainfields, (item: any) => { return chainfield.asset_id === parseInt(item.asset_id); });
                    // claim if end time is passed
                    if (new Date(chainfield.work.end.getTime()) < new Date(Date.now()) && new Date(chainfield.work.end.getTime()) > new Date(0) && chainfield.work.end !== chainfield.work.start) {
                        let res = yield session.transact({
                            actions: [
                                {
                                    account: tacoConstants.COMBZ.COMBZ_CONTRACT,
                                    name: tacoConstants.COMBZ.CHAINFIELD_CLAIM_ACTION,
                                    authorization: [session.permissionLevel],
                                    data: {
                                        user: self.activeAccount,
                                        chainfield_id: chainfield.asset_id,
                                    }
                                }
                            ]
                        });
                        if (res) {
                            self.log = `Claim chainfield ${chainfield.metadata.label}`;
                            self.chainfields[item_index].last_tx = res.response.transaction_id;
                            self.chainfields[item_index].last_action = "Claim";
                            yield waitTxDelay();
                        }
                    }

                    if (chainfield.is_auto_production && new Date(chainfield.work.end.getTime()) < new Date(Date.now())) {
                        // chainfield is not working => start working
                        // check if chainfield doesnt set full spacecombs => stop auto production
                        if (chainfield.spacecombs.length !== chainfield.metadata.slots.length) {
                            self.chainfields[item_index].is_auto_production = false;
                            continue;
                        }

                        // check if need to repair and is auto repair
                        if (chainfield.uses >= chainfield.metadata.max_usage && chainfield.is_auto_repair) {
                            // send repair action
                            let res = yield session.transact({
                                actions: [
                                    {
                                        account: tacoConstants.COMBZ.COMBZ_CONTRACT,
                                        name: tacoConstants.COMBZ.CHAINFIELD_REPAIR_ACTION,
                                        authorization: [session.permissionLevel],
                                        data: {
                                            user: self.activeAccount,
                                            chainfield: chainfield.asset_id,
                                        }
                                    }
                                ]
                            });
                            if (res) {
                                self.log = `Repair chainfield ${chainfield.metadata.label}`;
                                self.chainfields[item_index].last_tx = res.response.transaction_id;
                                self.chainfields[item_index].last_action = "Repair";
                                yield waitTxDelay();
                            }
                        }
                        // build actions
                        let actions: any = [];
                        // calculate input
                        let input: any = [];
                        let output_materials: any = [];
                        for (let spacecomb of chainfield.spacecombs) {
                            let spacecomb_ins = self.spacecombs.find((ins: any) => {
                                return ins.asset_id === spacecomb.id;
                            });

                            for (let item of spacecomb_ins.metadata.input) {
                                let input_item = input.find((input_item: any) => {
                                    return input_item.first === item.material_id;
                                });
                                if (input_item) {
                                    input_item.second += (item.quantity * (chainfield.duration > 0 ? chainfield.duration : chainfield.metadata.max_operating_time / 3600));
                                } else {
                                    input.push({
                                        first: item.material_id,
                                        second: (item.quantity * (chainfield.duration > 0 ? chainfield.duration : chainfield.metadata.max_operating_time / 3600)),
                                    });
                                }
                            }

                            for (let item of spacecomb_ins.metadata.output) {
                                let output_item = output_materials.find((output_item: any) => {
                                    return output_item === item.material_id;
                                });
                                if (!output_item) {
                                    output_materials.push(
                                        parseInt(item.material_id),
                                    );
                                }
                            }
                        }
                        self.chainfields[item_index].input = input;
                        // check if user has enough materials to start working
                        let has_enough_materials = true;
                        for (let item of chainfield.input) {
                            let material = self.user.materials.find((material: any) => {
                                return material.material_id === item.first;
                            });
                            if (!material || material.value < item.second) {
                                has_enough_materials = false;
                                break;
                            }
                            // reduce material
                            material.value -= item.second;
                        }
                        if (!has_enough_materials) {
                            // not enough materials => continue to next chainfield
                            continue;
                        }
                        // set sponsor if auto sponsor and available
                        const sponsorships = yield loadVenueData();
                        // find optimal sponsor base on these criteria:
                        // used < max_uses
                        // number of boost
                        // boost.key is available in output_materials
                        // boost.value is higher

                        let sponsor: any = [];
                        if (chainfield.is_sponsor_boost) {
                            let boost_values: any = {
                                0: 0,
                                1: 0,
                                2: 0,
                                3: 0,
                            };

                            let number_of_boost = 0;
                            let sponsor_index = -1;
                            for (let item of sponsorships) {
                                if (item.used < item.max_uses && item.boost.length >= number_of_boost) {
                                    number_of_boost = item.boost.length;
                                    // check if boost has at least 1 boost that is available in output_materials
                                    let has_boost = false;
                                    for (let boost of item.boost) {
                                        if (output_materials.includes(parseInt(boost.key))) {
                                            has_boost = true;
                                            break;
                                        }
                                    }
                                    if (has_boost) {
                                        for (let boost of item.boost) {
                                            if (boost.value > boost_values[parseInt(boost.key)]) {
                                                boost_values[parseInt(boost.key)] = parseFloat(boost.value);
                                                sponsor_index = item.key;
                                            }
                                        }
                                    }
                                }
                            }
                            if (sponsor_index !== -1) {
                                sponsor.push(sponsorships[sponsor_index]);
                            }
                        }

                        // check if ONI boost
                        let ONI_boostable = false;
                        if (chainfield.boost_amount > 0 && self.ONI_balance > chainfield.boost_amount / 10000) {
                            ONI_boostable = true;
                            // add transfer ONI token action
                            actions.push({
                                account: tacoConstants.TOKEN_CONTRACT,
                                name: tacoConstants.TOKEN_TRANSFER_ACTION,
                                authorization: [session.permissionLevel],
                                data: {
                                    from: self.activeAccount,
                                    to: tacoConstants.COMBZ.COMBZ_CONTRACT,
                                    quantity: `${(chainfield.boost_amount / 10000).toFixed(4)} ${tacoConstants.ONI_SYMBOL}`,
                                    memo: `boost|${self.activeAccount}`
                                },
                            });
                            // reduce ONI balance
                            self.ONI_balance -= (chainfield.boost_amount / 10000);

                        }

                        // new production action
                        actions.push({
                            account: tacoConstants.COMBZ.COMBZ_CONTRACT,
                            name: tacoConstants.COMBZ.CHAINFIELD_STARTWORK_ACTION,
                            authorization: [session.permissionLevel],
                            data: {
                                user: self.activeAccount,
                                chainfield_id: chainfield.asset_id,
                                boost_amount: ONI_boostable ? chainfield.boost_amount : 0,
                                input: toJS(chainfield.input),
                                spacecombs: toJS(chainfield.spacecombs),
                                sponsor: sponsor.length > 0 ? [{ first: sponsor[0].asset_id, second: [sponsor[0].key] }] : [],
                                worker_ids: []
                            }
                        });

                        // send transaction
                        let res = yield session.transact({
                            actions: actions
                        });

                        if (res) {
                            // update chainfield work start and end time
                            self.chainfields[item_index].work.start = new Date(new Date().getTime());
                            self.chainfields[item_index].work.end = new Date(new Date().getTime() + chainfield.metadata.max_operating_time * 1000);
                            self.log = `Start working chainfield ${chainfield.metadata.label}`;
                            self.chainfields[item_index].last_tx = res.response.transaction_id;
                            self.chainfields[item_index].last_action = "Startwork";
                            yield waitTxDelay();
                        }
                    }

                    if (!self.isActiveMember) {
                        break;
                    }
                }
                saveLocalStorage();
                // refresh data
                yield loadUserData(self.activeAccount);

            } catch (error) {

            }
        });

        const setEndsIn = flow(function* () {
            self.countdown = true;
            while (true) {
                // set ends in for chainfields
                for (let chainfield of self.chainfields) {
                    if (chainfield.work.end.getTime() > chainfield.work.start.getTime() && chainfield.work.end.getTime() > new Date().getTime()) {
                        let distance = chainfield.work.end.getTime() - new Date().getTime();
                        let hours = Math.floor(distance / (1000 * 60 * 60));
                        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        chainfield.ends_in = `${hours}h ${minutes}m ${seconds}s`;
                    } else {
                        chainfield.ends_in = "00h 00m 00s";
                    }
                }

                yield new Promise(resolve => setTimeout(resolve, 1000));
            }
        });

        function setMinTxDelay(min_tx_delay_seconds: number) {
            self.min_tx_delay_seconds = min_tx_delay_seconds;
        }

        function setMaxTxDelay(max_tx_delay_seconds: number) {
            self.max_tx_delay_seconds = max_tx_delay_seconds;
        }

        const waitTxDelay = flow(function* () {
            let min_tx_delay = self.min_tx_delay_seconds * 1000;
            let max_tx_delay = self.max_tx_delay_seconds * 1000;
            let tx_delay = Math.floor(Math.random() * (max_tx_delay - min_tx_delay + 1)) + min_tx_delay;
            yield new Promise(resolve => setTimeout(resolve, tx_delay));
        });


        return {
            markLoading,
            loadCfgData,
            loadVenueData,
            setIsAuto,
            loadUserData,
            setAutoCollectFreeRes,
            moveChainfieldUp,
            moveChainfieldDown,
            setDuration,
            setBoostAmount,
            setAutoSponsor,
            setAutoRepair,
            setAutoProduction,
            setIsActiveMember,
            selectSpaceComb,
            autoCombz,
            setEndsIn,
            setMinTxDelay,
            setMaxTxDelay,
        }
    })


export const combzStore = Combz.create({
    isLoading: false,
    isAuto: false,
    isAutoCollectFreeRes: false,
    log: "",
    activeAccount: "",
    isActiveMember: false,
    chainfieldCfgItems: [],
    spacecombCfgItems: [],

    ONI_balance: 0,
    user: {
        last_claim: new Date(0),
        materials: [],
    },
    chainfields: [],
    spacecombs: [],
    works: [],
    is_all_auto_sponsor: false,
    is_all_auto_repair: false,
    is_all_auto_production: false,
    countdown: false,
    min_tx_delay_seconds: 0,
    max_tx_delay_seconds: 0,
})