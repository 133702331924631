import { values } from "mobx";
import { flow, types } from "mobx-state-tree";
import tacoConstants from "../../../../constants/taco/index";
import { getTableRows } from "../../../../services/api";
import { Stock } from "../../domain/stock";
import { MultiKiosksStore } from '../multiKiosks/multiKiosks.controller';


const Kiosks = types
    .model("Kiosks", {
        isLoading: true,
        stocks: types.array(Stock),
        cart: types.array(Stock),
    })
    .views((self) => ({
        get listStocks() {
            console.log("self.stocks", self.stocks);
            return values(self.stocks)
        },
        get listCart() {
            return values(self.cart)
        },

    }))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        function updateStocks(json: any) {
            let items = json.rows;
            let instock: any = items.filter((item: any) => item.quantity > 0);
            let outofstock: any = items.filter((item: any) => item.quantity === 0);
            instock.sort((a: any, b: any) => {
                return (
                    Number(a.unit_price.replace(".0000 SHING", "")) -
                    Number(b.unit_price.replace(".0000 SHING", ""))
                );
            });
            outofstock.sort((a: any, b: any) => {
                return (
                    Number(a.unit_price.replace(".0000 SHING", "")) -
                    Number(b.unit_price.replace(".0000 SHING", ""))
                );
            });
            self.stocks = instock.concat(outofstock);
        }

        const loadStocks = flow(function* (kiosk_id) {

            try {
                const json = yield getTableRows({
                    table: tacoConstants.STOCKS_TABLE,
                    code: tacoConstants.AUTO_KIOSK_CONTRACT,
                    scope: kiosk_id,
                })

                updateStocks(json)
                markLoading(false)
            } catch (err) {
                console.error("Failed to load Stocks", err)
            }
        })

        const addToCart = (stock: any) => {
            console.log({ stock })
            let item = self.cart.findIndex((item: any) => item.item_name === stock.item_name);
            if (item === -1) {
                self.cart.push({ ...stock, quantity: 1 });
            } else {
                self.cart[item].quantity += 1;
            }
        }

        const removeFromCart = (cart: any, quantity: number) => {
            let item = self.cart.findIndex((item: any) => item.item_name === cart.item_name);
            if (item !== -1) {
                if (self.cart[item].quantity > quantity) {
                    self.cart[item].quantity -= quantity;
                } else {
                    self.cart.splice(item, 1);
                }
            }
        }

        const getItemQuantityOnCart = (cart: any) => {
            let item = self.cart.findIndex((item: any) => item.template_id === cart.template_id);
            if (item !== -1) {
                return self.cart[item].quantity;
            }
            return 0;
        }

        const getMaxQuantityByIng = (ingredient: any) => {
            let item = self.stocks.findIndex((item: any) => item.template_id === ingredient.template_id);
            if (item !== -1) {
                return self.stocks[item].quantity;
            }
            return 0;
        }

        const clearCart = () => {
            self.cart.length = 0;
        }

        const getTotalPrice = () => {
            let total = 0;
            self.cart.forEach((item: any) => {
                total += Number(item.unit_price.replace(".0000 SHING", "")) * item.quantity;
            })
            return total;
        }

        const removeShingPrecision = (price: string) => {
            return price.replace(".0000", '');
        }

        const getMemo = () => {
            let selectedKiosk: any = MultiKiosksStore.selectedKiosk;
            let memo = `buy_ingredients;${selectedKiosk.kiosk_id};`;
            for (let i = 0; i < self.cart.length; i++) {
                let item = self.cart[i];
                if (i !== self.cart.length - 1) {
                    memo += `${item.template_id};${item.quantity};`;
                } else {
                    memo += `${item.template_id};${item.quantity}`;
                }
            }
            return memo
        }

        const changeQuantityItem = (cart: any, quantity: number, max_quantity: number) => {
            let item = self.cart.findIndex((item: any) => item.item_name === cart.item_name);
            if (item !== -1) {
                if (quantity === 0) {
                    self.cart.splice(item, 1);
                } else {
                    if (quantity > max_quantity) {
                        self.cart[item].quantity = max_quantity;
                    } else {
                        self.cart[item].quantity = quantity;
                    }
                }
            } else {
                if (quantity > 0) {
                    self.cart.push({ ...cart, quantity: quantity > max_quantity ? max_quantity : quantity });
                }
            }
        }


        return {
            loadStocks,
            addToCart,
            removeFromCart,
            getItemQuantityOnCart,
            clearCart,
            getTotalPrice,
            removeShingPrecision,
            getMemo,
            changeQuantityItem,
            getMaxQuantityByIng
        }
    })


export const kioskStore = Kiosks.create({
    isLoading: true,
    stocks: [],
    cart: [],
})