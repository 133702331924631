import React from "react";
import constants from "../constants/index";
import { getTableRow } from "../services/api";
interface StateProps {
    member: {
        wallet: string;
        tier_name: string;
        expired_at: string;
        isActive: boolean;
    };
}

function wrapProviderHOC(ChildComp: React.ComponentType<any | string>) {
    return class Component extends React.Component<any, StateProps> {
        state = {
            member: { wallet: "", tier_name: "", expired_at: "", isActive: false },
        };

        getMemberInfo = async (account: string) => {
            if (!account) {
                let member = {
                    wallet: "",
                    tier_name: "",
                    expired_at: "",
                    isActive: false,
                };
                this.setState({ member: member });
                return;
            }
            let member_data = await getTableRow({
                code: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                scope: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                table: constants.WAXIMUS_CENTRAL.MEMBER_TABLE,
                lower_bound: account,
            });

            if (
                member_data &&
                member_data.rows &&
                member_data.rows.length > 0 &&
                member_data.rows[0].wallet === account
            ) {
                // get member tier and check active status
                member_data.rows[0].expired_at = member_data.rows[0].expired_at + "Z";
                let member: any = {
                    ...member_data.rows[0],
                    isActive:
                        Date.parse(member_data.rows[0].expired_at) > Date.now()
                            ? true
                            : false,
                };
                this.setState({ member: member });
            } else {
                let member = {
                    wallet: "",
                    tier_name: "",
                    expired_at: "",
                    isActive: false,
                };
                this.setState({ member: member });
            }
        };

        componentDidMount() {
            if (this.props?.session) {
                this.getMemberInfo(String(this.props.session.actor));
            } else {
                let member = {
                    wallet: "",
                    tier_name: "",
                    expired_at: "",
                    isActive: false,
                };
                this.setState({ member: member });
            }
        }
        componentDidUpdate(prevProps: any, prevState: any) {
            if (
                this.props?.session?.actor !==
                prevProps?.session?.actor
            ) {
                this.getMemberInfo(String(this.props?.session?.actor));
            }
        }

        render() {
            return (
                <>
                    <ChildComp {...this.props} member={this.state.member} />
                </>
            );
        }
    };
}
export default wrapProviderHOC;
