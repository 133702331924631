import classNames from 'classnames';
import type { ComponentProps, FC, PropsWithChildren } from 'react';
import { DeepPartial } from '..';
import { mergeDeep } from '../../utils/helpers/mergeDeep';
import { useTheme } from '../Waximus/ThemeContext';
import { WaximusBoolean } from '../Waximus/WaximusTheme';

export interface WaximusNavbarLinkTheme {
  base: string;
  active: WaximusBoolean;
  disabled: WaximusBoolean;
}

export interface NavbarLinkProps extends PropsWithChildren<ComponentProps<'a'>> {
  active?: boolean;
  disabled?: boolean;
  href?: string;
  theme?: DeepPartial<WaximusNavbarLinkTheme>;
}

export const NavbarLink: FC<NavbarLinkProps> = ({
  active,
  disabled,
  href,
  theme: customTheme = {},
  children,
  className,
  ...props
}) => {
  const theme = mergeDeep(useTheme().theme.navbar.link, customTheme);

  return (
    <li>
      <a
        href={href}
        className={classNames(
          theme.base,
          {
            [theme.active.on]: active,
            [theme.active.off]: !active && !disabled,
          },
          theme.disabled[disabled ? 'on' : 'off'],
          className,
        )}
        {...props}
      >
        {children}
      </a>
    </li>
  );
};
