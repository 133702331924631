import { RouteProps } from "../../routes";

export const mapRoute = (routes: RouteProps[]) => {
    let newRoutes: any[] = [];
    routes.forEach((route) => {
        if (route.children) {
            route.children.forEach((child) => {
                newRoutes.push({
                    ...child,
                    parent: route.path,
                    path: `${route.path}${child.path}`,
                })
            })
        }
        newRoutes.push(route)

    })
    return newRoutes
}