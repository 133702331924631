import classNames from 'classnames';
import { ComponentProps, FC, PropsWithChildren, useEffect, useState } from 'react';
import { useTheme } from '../Waximus/ThemeContext';
import { WaximusBoolean } from '../Waximus/WaximusTheme';
import SidebarCollapse from './SidebarCollapse';
import { SidebarContext } from './SidebarContext';
import SidebarCTA, { SidebarCTAColors } from './SidebarCTA';
import SidebarItem from './SidebarItem';
import SidebarItemGroup from './SidebarItemGroup';
import SidebarItems from './SidebarItems';
import SidebarLogo from './SidebarLogo';

export interface WaximusSidebarTheme {
  base: string;
  collapsed: WaximusBoolean;
  inner: string;
  collapse: {
    active?: string,
    button: string;
    icon: {
      active?: string,
      base: string;
      open: WaximusBoolean;
    };
    label: {
      base: string;
      icon: string;
    };
    list: string;
  };
  cta: {
    base: string;
    color: SidebarCTAColors;
  };
  item: {
    active: string;
    base: string;
    collapsed: {
      insideCollapse: string;
      noIcon: string;
    };
    content: {
      base: string;
    };
    label: string;
    icon: {
      base: string;
      active: string;
    };
  };
  items: string;
  itemGroup: string;
  logo: {
    base: string;
    collapsed: WaximusBoolean;
    img: string;
  };
}

export interface SidebarProps extends PropsWithChildren<ComponentProps<'div'>> {
  collapseBehavior?: 'collapse' | 'hide';
  collapsed?: boolean;
}

const SidebarComponent: FC<SidebarProps> = ({
  children,
  collapseBehavior = 'collapse',
  collapsed: isCollapsed = false,
  ...props
}): JSX.Element => {
  const theme = useTheme().theme.sidebar;
  const [isHover, setIsHover] = useState(false);
  useEffect(() => {
    setIsHover(false);
  }, [isCollapsed]);
  const onMouseEnterHandler = (e: { currentTarget: any; }) => {
    setIsHover(true);
  }
  const onMouseLeaveHandler = () => {
    setIsHover(false);
  }
  let checkCollapsed = isCollapsed;
  if (checkCollapsed) {
    if (isHover) {
      checkCollapsed = false
    } else {
      checkCollapsed = isCollapsed
    }
  }

  return (
    <SidebarContext.Provider value={{ isCollapsed: checkCollapsed }}>
      <aside
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        aria-label="Sidebar"
        className={classNames(theme.base, theme.collapsed[checkCollapsed ? 'on' : 'off'])}
        hidden={checkCollapsed && collapseBehavior === 'hide'}
        {...props}
      >
        <div className={theme.inner}>{children}</div>
      </aside>
    </SidebarContext.Provider>
  );
};

SidebarComponent.displayName = 'Sidebar';
export const Sidebar = Object.assign(SidebarComponent, {
  Collapse: SidebarCollapse,
  CTA: SidebarCTA,
  Item: SidebarItem,
  Items: SidebarItems,
  ItemGroup: SidebarItemGroup,
  Logo: SidebarLogo,
});
