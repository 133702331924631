import { types } from "mobx-state-tree";

export const DepositedNFT = types.model("DepositedNFT", {
    asset_id: types.number,
    owner: types.string,
    sale_id: types.number,
    price_updated_at: types.Date,
    sold_at: types.Date,
    listed_price: types.string,
    wax_returned: types.string,
    asset_returned: types.boolean,
    asset_returned_at: types.Date,
    price_edited_amount: types.number,
    default_price: types.string,
    exist_until: types.Date,
    asset_name: types.string,
    asset_media: types.string,
    status: types.number, // 0: listing, 1: sold, 2: returned
    selected: types.optional(types.boolean, false),
})
