import { FC, PropsWithChildren } from 'react';

const DefaultContent: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex flex-col flex-1 overflow-hidden">
                <main className="flex-1 overflow-x-hidden overflow-y-auto">{children}</main>
            </div>
        </div>
    );
};
export { DefaultContent };
