import _ from "lodash";
import { values } from "mobx";
import { cast, flow, types } from "mobx-state-tree";
import tacoConstants from "../../../../constants/taco/index";
import { getAccountIngredients, getAccountStats, getTableRows } from "../../../../services/api";
import { AvailableIngItem } from '../../domain/availableIngItem';
import { Stock } from '../../domain/stock';
import { supplyActionModalItem } from '../../domain/supplyActionModalItem';
import { SupplyItem } from "../../domain/supplyItem";
import { SupplyRenderItem } from '../../domain/supplyRenderItem';
import { MultiKiosksStore } from '../multiKiosks/multiKiosks.controller';


const SupplyIngredients = types
    .model("SupplyIngredients", {
        isLoading: true,
        supplyItems: types.array(SupplyItem),
        ingredients: types.array(Stock),
        availableIngItems: types.array(AvailableIngItem),
        renderItems: types.array(SupplyRenderItem),
        supplyActionModal: supplyActionModalItem
    })
    .views((self) => ({
        get listSupplyItems() {
            console.log("self.supplyItems", self.supplyItems);
            return values(self.supplyItems)
        },
        get listIngredients() {
            console.log("self.ingredients", self.ingredients);
            return values(self.ingredients)
        },
        get listAvailableIngItems() {
            console.log("self.availableIngItems", self.availableIngItems);
            return values(self.availableIngItems)
        },
    }))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        function updateSupplyItems(json: any) {
            // filter to get  items of selected kiosk
            let items = json.rows;
            let selectedKiosk: any = MultiKiosksStore.selectedKiosk;
            items = items.filter((item: any) => item.kiosk_id === selectedKiosk.kiosk_id);
            self.supplyItems = items;

        }

        function updateIngredients(json: any) {
            self.ingredients = json.rows;
        }

        function updateAvailableIngItems(data: any) {
            self.availableIngItems = data;
        }

        function updateRenderItems(data: any) {
            self.renderItems = data;
        }

        const loadIngredients = flow(function* (kiosk_id) {

            try {
                const json = yield getTableRows({
                    table: tacoConstants.STOCKS_TABLE,
                    code: tacoConstants.AUTO_KIOSK_CONTRACT,
                    scope: kiosk_id,
                })
                updateIngredients(json);
                cookData();
                markLoading(false);
            } catch (err) {
                console.error("Failed to load Kiosk Ingredients", err)
            }
        })

        const loadAAItems = flow(function* (wallet: string) {
            try {
                // load aa data
                let availableIngItemsData: any = [];
                const accountStatsResult: any = yield getAccountStats(wallet, tacoConstants.COLLECTION_NAME);

                for (let ingredient of accountStatsResult.data.data.templates) {
                    const accountIngredientsResult = yield getAccountIngredients(wallet, ingredient.template_id);

                    let ingItem = {
                        template_id: Number(ingredient.template_id),
                        quantity: accountIngredientsResult.data.data.length,
                        asset_ids: accountIngredientsResult.data.data.map((item: any) => item.asset_id)
                    }
                    availableIngItemsData.push(ingItem);
                };

                updateAvailableIngItems(availableIngItemsData);
                cookData();
                markLoading(false);
            } catch (err) {
                console.error("Failed to load Available Ingredients", err)
            }
        });

        const loadAAByWalletAndTemplate = flow(function* (wallet: string, template_id: number) {
            try {
                let asset_ids: any = [];

                const accountIngredientsResult = yield getAccountIngredients(wallet, template_id);

                asset_ids = accountIngredientsResult.data.data.map((item: any) => item.asset_id);

                return asset_ids;
            } catch (err) {
                console.error("Failed to load Available Ingredients", err)
                return [];
            }
        });

        const loadSupplyItems = flow(function* (wallet: string) {

            try {
                const json = yield getTableRows({
                    table: tacoConstants.SUPPLIERS_TABLE,
                    code: tacoConstants.AUTO_KIOSK_CONTRACT,
                    scope: wallet,
                })
                updateSupplyItems(json);
                cookData();
                markLoading(false)
            } catch (err) {
                console.error("Failed to load Supply Ingredients", err)
            }
        })

        const showModal = flow(function* (item: any, wallet: string, isDeposit: boolean) {
            let title = isDeposit ? "Deposit" : "Withdraw";
            try {
                if (isDeposit) {
                    let asset_ids = yield loadAAByWalletAndTemplate(wallet, item.template_id);
                    self.supplyActionModal = cast({
                        wallet,
                        title: `${title} ${item.item_name}`,
                        quantity: 0,
                        max_quantity: asset_ids.length,
                        isShow: true,
                        isDeposit: isDeposit,
                        template_id: item.template_id,
                        available_asset_ids: asset_ids
                    });
                } else {
                    self.supplyActionModal = cast({
                        wallet,
                        title: `${title} ${item.item_name}`,
                        quantity: 0,
                        max_quantity: item.deposited,
                        isShow: true,
                        isDeposit: isDeposit,
                        template_id: item.template_id,
                        available_asset_ids: []
                    });
                }


                markLoading(false)
            } catch (err) {
                console.error("Failed to load modal", err)
            }

        })

        const closeModal = () => {
            self.supplyActionModal = cast({ isShow: false, isDeposit: false, wallet: "", title: "", quantity: 0, max_quantity: 0, template_id: 0, available_asset_ids: [] });
        }

        const changeQuantityModal = (quantity: number) => {
            self.supplyActionModal.quantity = quantity;
        };

        const removeShingPrecision = (price: string) => {
            return price.replace(".0000", '');
        }

        const cookData = () => {
            let items: any = [];
            _.forEach(self.ingredients, (ingItem: any) => {
                let ingItemCopy = Object.assign({}, ingItem);
                delete ingItemCopy.quantity;
                let depositedItem: any = _.find(self.supplyItems, (supplyItem: any) => {
                    return supplyItem.template_id === ingItemCopy.template_id;
                });

                let availableIngItem = _.find(self.availableIngItems, (item: any) => {
                    return item.template_id === ingItemCopy.template_id;
                });

                let renderItem: any = {
                    ...ingItemCopy,
                    deposited: depositedItem ? depositedItem.quantity : 0,
                    available: availableIngItem ? availableIngItem.quantity : 0,
                    available_asset_ids: availableIngItem ? values(availableIngItem.asset_ids) : [],
                }

                items.push(renderItem);
            });
            items.sort((a: any, b: any) => {
                // return b.deposited - a.deposited || b.available - a.available || Number(a.unit_price.split(".0000 SHING")[0]) - Number(b.unit_price.split(".0000 SHING")[0]);
                return Number(a.unit_price.split(".0000 SHING")[0]) - Number(b.unit_price.split(".0000 SHING")[0]);
            });

            updateRenderItems(items);

            // return items;
        }

        return {
            loadSupplyItems,
            loadIngredients,
            loadAAItems,
            showModal,
            closeModal,
            changeQuantityModal,
            removeShingPrecision,
            cookData
        }
    })


export const SupplyIngredientStore = SupplyIngredients.create({
    isLoading: true,
    supplyItems: [],
    ingredients: [],
    availableIngItems: [],
    supplyActionModal: { wallet: "", title: "", quantity: 0, max_quantity: 0, isShow: false, isDeposit: false, template_id: 0 },
})