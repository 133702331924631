import { sellbotStore } from "./controller";

interface props {
  nft: {
    image: string;
    video: string;
    name: string;
    asset_id: string;
    selected: boolean;
    template_mint: string;
  };
}

// regex function to check if nft.image value is a url by checking if string included "http" 
// if true then return true, else return false
const isUrl = (str: string) => {
  const regex = new RegExp("http");
  return regex.test(str);
};

const NFT = (props: props) => {
  let { nft } = props;
  return (
    <div className={` ${nft.selected ? "border-yellow-500" : "border-gray-500"
      } bg-gray-800 col-span-1 rounded-lg border-2 flex flex-col justify-center items-center py-4`}
      onClick={() => { sellbotStore.onSelect(nft.asset_id, !nft.selected) }}
    >

      {nft.image ? (
        <img
          className="w-3/4"
          src={isUrl(nft.image) ? nft.image : `https://atomichub-ipfs.com/ipfs/${nft.image}`}
          alt={nft.name}
        />
      ) : (
        <video autoPlay loop playsInline className="w-3/4">
          <source src={isUrl(nft.video) ? nft.video : `https://ipfs.io/ipfs/${nft.video}`} />
        </video>
      )}
      <p className="text-white">{nft.name}</p>
      <p className="text-white">#{nft.asset_id}</p>
    </div>
  );
};

export default NFT;
