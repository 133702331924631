import { FC, useState } from "react";
import { Button, TextInput } from "../../../components";
import { farmingTalesStore } from "./controller";
import { FcExpand, FcCollapse, FcShop, FcSupport } from "react-icons/fc";
import { getName, getUrl } from "../../../constants/farmingtales/products";

interface FTProps {
  ual: any;
}

interface IngredientsProps {
  ingredient: any;
  setModalInfo: any;
}

interface CreateProductInputProps {
  recipe: any;
  isActive: boolean;
}

export const Ingredients: FC<IngredientsProps> = ({
  ingredient,
  setModalInfo,
}) => {
  const [selected, setSelected]: [string[], any] = useState([]);

  const handleSelect = (label: any) => {
    let index = selected.findIndex((l: any) => l === label);
    if (index === -1) {
      setSelected(selected.concat(label));
    } else {
      setSelected(selected.filter((l: any) => l !== label));
    }
  };

  return (
    <div className="grid grid-cols-4">
      <p
        className={`flex col-span-3 ${
          farmingTalesStore.getProductInBarn(ingredient) >= ingredient.value &&
          "text-green-600"
        }`}
      >
        * {getName(ingredient.key)}
        {": "}
        {farmingTalesStore.getProductInBarn(ingredient)}/{ingredient.value}
        {selected.includes(ingredient.key) ? (
          <FcCollapse
            className="ml-2 cursor-pointer"
            onClick={() => {
              handleSelect(ingredient.key);
            }}
          />
        ) : (
          <FcExpand
            className="ml-2 cursor-pointer"
            onClick={() => {
              handleSelect(ingredient.key);
            }}
          />
        )}
      </p>
      <div className="flex justify-self-end">
        {farmingTalesStore.getProductRecipe(ingredient).length > 0 && (
          <FcSupport
            className="mr-2 cursor-pointer"
            onClick={() => {
              let recipe = farmingTalesStore.recipes.find(
                (r: any) => r.result === ingredient.key
              );
              // console.log({ recipe });
              if (recipe) {
                setModalInfo({
                  show: true,
                  key: ingredient.key,
                  value: 0,
                  max: farmingTalesStore.getCouldCreateProductQuantity(recipe),
                });
              }
            }}
          />
        )}
        <a href={getUrl(ingredient.key)} target={"_blank"} rel={"noreferrer"}>
          <FcShop />
        </a>
      </div>
      <div className="col-span-4">
        {selected.includes(ingredient.key) && (
          <div className="ml-8">
            {farmingTalesStore
              .getProductRecipe(ingredient)
              .map((ingredient: any) => (
                <div
                  key={Math.random()}
                  className="border-dashed border-t border-slate-500 grid grid-cols-4"
                >
                  <div
                    className={`flex col-span-3 ${
                      farmingTalesStore.getProductInBarn(ingredient) >=
                        ingredient.value && "text-green-600"
                    }`}
                  >
                    {getName(ingredient.key)}
                    {": "}
                    {farmingTalesStore.getProductInBarn(ingredient)}/
                    {ingredient.value}
                  </div>
                  <div className="flex justify-self-end">
                    {farmingTalesStore.getProductRecipe(ingredient).length >
                      0 && (
                      <FcSupport
                        className="mr-2 cursor-pointer"
                        onClick={() => {
                          let recipe = farmingTalesStore.recipes.find(
                            (r: any) => r.result === ingredient.key
                          );
                          // console.log({ recipe });
                          if (recipe) {
                            setModalInfo({
                              show: true,
                              key: ingredient.key,
                              value: 0,
                              max: farmingTalesStore.getCouldCreateProductQuantity(
                                recipe
                              ),
                            });
                          }
                        }}
                      />
                    )}
                    <a
                      href={getUrl(ingredient.key)}
                      target={"_blank"}
                      rel={"noreferrer"}
                    >
                      <FcShop />
                    </a>
                  </div>
                  <div className="ml-8 col-span-4">
                    {farmingTalesStore
                      .getProductRecipe(ingredient)
                      .map((ingredient: any) => (
                        <div
                          key={Math.random()}
                          className="border-dashed border-t border-slate-500 grid grid-cols-4"
                        >
                          <div
                            className={`flex col-span-3 ${
                              farmingTalesStore.getProductInBarn(ingredient) >=
                                ingredient.value && "text-green-600"
                            }`}
                          >
                            {getName(ingredient.key)}
                            {": "}
                            {farmingTalesStore.getProductInBarn(ingredient)}/
                            {ingredient.value}
                          </div>
                          <div className="flex justify-self-end">
                            {farmingTalesStore.getProductRecipe(ingredient)
                              .length > 0 && (
                              <FcSupport
                                className="mr-2 cursor-pointer"
                                onClick={() => {
                                  let recipe = farmingTalesStore.recipes.find(
                                    (r: any) => r.result === ingredient.key
                                  );
                                  // console.log({ recipe });
                                  if (recipe) {
                                    setModalInfo({
                                      show: true,
                                      key: ingredient.key,
                                      value: 0,
                                      max: farmingTalesStore.getCouldCreateProductQuantity(
                                        recipe
                                      ),
                                    });
                                  }
                                }}
                              />
                            )}
                            <a
                              href={getUrl(ingredient.key)}
                              target={"_blank"}
                              rel={"noreferrer"}
                            >
                              <FcShop />
                            </a>
                          </div>
                          <div className="ml-8 col-span-4">
                            {farmingTalesStore
                              .getProductRecipe(ingredient)
                              .map((ingredient: any) => (
                                <div
                                  key={Math.random()}
                                  className="border-dashed border-t border-slate-500 grid grid-cols-4"
                                >
                                  <div
                                    className={`flex col-span-3 ${
                                      farmingTalesStore.getProductInBarn(
                                        ingredient
                                      ) >= ingredient.value && "text-green-600"
                                    }`}
                                  >
                                    {getName(ingredient.key)}
                                    {": "}
                                    {farmingTalesStore.getProductInBarn(
                                      ingredient
                                    )}
                                    /{ingredient.value}
                                  </div>
                                  <div className="flex justify-self-end">
                                    {farmingTalesStore.getProductRecipe(
                                      ingredient
                                    ).length > 0 && (
                                      <FcSupport
                                        className="mr-2 cursor-pointer"
                                        onClick={() => {
                                          let recipe =
                                            farmingTalesStore.recipes.find(
                                              (r: any) =>
                                                r.result === ingredient.key
                                            );
                                          // console.log({ recipe });
                                          if (recipe) {
                                            setModalInfo({
                                              show: true,
                                              key: ingredient.key,
                                              value: 0,
                                              max: farmingTalesStore.getCouldCreateProductQuantity(
                                                recipe
                                              ),
                                            });
                                          }
                                        }}
                                      />
                                    )}
                                    <a
                                      href={getUrl(ingredient.key)}
                                      target={"_blank"}
                                      rel={"noreferrer"}
                                    >
                                      <FcShop />
                                    </a>
                                  </div>
                                  <div className="ml-8 col-span-4">
                                    {farmingTalesStore
                                      .getProductRecipe(ingredient)
                                      .map((ingredient: any) => (
                                        <div
                                          key={Math.random()}
                                          className="border-dashed border-t border-slate-500 grid grid-cols-4"
                                        >
                                          <div
                                            className={`flex col-span-3 ${
                                              farmingTalesStore.getProductInBarn(
                                                ingredient
                                              ) >= ingredient.value &&
                                              "text-green-600"
                                            }`}
                                          >
                                            {getName(ingredient.key)}
                                            {": "}
                                            {farmingTalesStore.getProductInBarn(
                                              ingredient
                                            )}
                                            /{ingredient.value}
                                          </div>
                                          <div className="flex justify-self-end">
                                            {farmingTalesStore.getProductRecipe(
                                              ingredient
                                            ).length > 0 && (
                                              <FcSupport
                                                className="mr-2 cursor-pointer"
                                                onClick={() => {
                                                  let recipe =
                                                    farmingTalesStore.recipes.find(
                                                      (r: any) =>
                                                        r.result ===
                                                        ingredient.key
                                                    );
                                                  // console.log({ recipe });
                                                  if (recipe) {
                                                    setModalInfo({
                                                      show: true,
                                                      key: ingredient.key,
                                                      value: 0,
                                                      max: farmingTalesStore.getCouldCreateProductQuantity(
                                                        recipe
                                                      ),
                                                    });
                                                  }
                                                }}
                                              />
                                            )}
                                            <a
                                              href={getUrl(ingredient.key)}
                                              target={"_blank"}
                                              rel={"noreferrer"}
                                            >
                                              <FcShop />
                                            </a>
                                          </div>
                                          <div className="ml-8"></div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const CreateProductInput: FC<CreateProductInputProps> = ({
  recipe,
  isActive,
}) => {
  const [input, setInput]: [number, any] = useState(0);
  let max = farmingTalesStore.getCouldCreateProductQuantity(recipe);

  return (
    <div className="flex items-center justify-self-end">
      <Button
        className="mr-2"
        disabled={farmingTalesStore.getCouldCreateProductQuantity(recipe) === 0}
        onClick={() => {
          if (!isActive) {
            farmingTalesStore.setModalInfo({
              show: true,
              title: "Create Products",
              text: "Are you sure you want to Create these products?",
              content: [{ key: recipe.result, value: 1 }],
              type: "create",
            });
          } else {
            if (input > 0) {
              farmingTalesStore.setModalInfo({
                show: true,
                title: "Create Products",
                text: "Are you sure you want to Create these products?",
                content: [{ key: recipe.result, value: input }],
                type: "create",
              });
            }
          }
        }}
      >
        Create {!isActive ? 1 : ""}
      </Button>
      {isActive && (
        <TextInput
          helperText={`/${max}`}
          size={4}
          // id="id"
          placeholder="0"
          value={input === 0 ? "" : input}
          type="number"
          max={max}
          min={0}
          classNameInputText="text-center"
          classNameHelperText="mt-0 ml-1"
          onChange={(e) => {
            let value = Number(e.target.value);
            value > max ? setInput(max) : setInput(value);
          }}
        />
      )}
    </div>
  );
};

export const Swap: FC<FTProps> = ({ ual }) => {
  return (
    <div className="flex items-center text-white mb-2 ml-2">
      <p className="font-semibold mr-2">Withdraw/Deposit</p>
      <TextInput
        className="mr-2"
        helperText={``}
        size={10}
        // id="id"
        placeholder="SEST"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <TextInput
        helperText={``}
        size={10}
        // id="id"
        placeholder="CBIT"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <Button className="ml-2" color={"failure"}>
        Withdraw
      </Button>
      <Button className="ml-2" color={"success"}>
        Deposit
      </Button>
    </div>
  );
};

export const Stake: FC<FTProps> = ({ ual }) => {
  return (
    <div className="flex items-center text-white mb-2 ml-2">
      <p className="font-semibold mr-2">Stake</p>
      <TextInput
        className="mr-2"
        helperText={``}
        size={10}
        // id="id"
        placeholder="SEST"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <TextInput
        helperText={``}
        size={10}
        // id="id"
        placeholder="CBIT"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <Button className="ml-2" color={"failure"}>
        Stake SEST get CBIT
      </Button>
      <Button className="ml-2" color={"success"}>
        Stake SEST/CBIT get AUREUS
      </Button>
    </div>
  );
};

export const Transfer: FC<FTProps> = ({ ual }) => {
  return (
    <div className="flex items-center text-white mb-2 ml-2">
      <p className="font-semibold mr-2">Transfer</p>
      <TextInput
        className="mr-2"
        helperText={``}
        size={10}
        // id="id"
        placeholder="SEST"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <TextInput
        className="mr-2"
        helperText={``}
        size={10}
        // id="id"
        placeholder="CBIT"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <TextInput
        className="mr-2"
        helperText={``}
        size={10}
        // id="id"
        placeholder="AUREUS"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <TextInput
        helperText={``}
        size={10}
        // id="id"
        placeholder="Recipient"
        value={""}
        type=""
        classNameInputText="text-center"
        classNameHelperText="mt-0 ml-1"
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <Button className="ml-2">Send</Button>
    </div>
  );
};
