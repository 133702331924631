import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, useEffect, useState } from "react";
import { FcCollapse, FcExpand } from "react-icons/fc";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Modal,
  Spinner,
  TextInput,
} from "../../../../components";
import ftConstants from "../../../../constants/farmingtales/index";
import { getName } from "../../../../constants/farmingtales/products";
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import wrapProviderHOC from "../../../../layouts/WrapProvider";
import { CreateProductInput } from "../components";
import { farmingTalesStore } from "../controller";

interface FTProps {
  session: any;
  member: any;
}

const FarmingTalesScreen: FC<FTProps> = observer(({ ...props }) => {
  let store: any = getSnapshot(farmingTalesStore);
  const [expand, setExpand] = useState([]);
  const [searchAccountName, setSearchAccountName] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const handleEpand = (product: any) => {
    let index = expand.findIndex((t: any) => t.key === product.key);
    if (index === -1) {
      setExpand(expand.concat(product));
    } else {
      setExpand(expand.filter((t: any) => t.key !== product.key));
    }
  };

  const handleSubmitModal = async (modalInfo: any, activeUser: any) => {
    console.log("modalInfo", activeUser);
    try {
      // farmingTalesStore.setModalInfo({
      //   show: false,
      //   title: "",
      //   text: "",
      //   content: [],
      //   type: "",
      // });
      let { type, content, text } = modalInfo;
      if (activeUser) {
        // Sell Items
        if (type === "sell") {
          // sellsingprod
          let keys = content.map((t: any) => t.key);
          let values = content.map((t: any) => t.value);
          const response = await activeUser.transact(
            {
              actions: [
                {
                  account: ftConstants.FARMINGGAME2,
                  name: "sellsingprod",
                  authorization: [activeUser.permissionLevel],
                  data: {
                    amount: values,
                    keys: keys,
                    user: String(activeUser.actor),
                  },
                },
              ],
            },
            {
              broadcast: true,
              blocksBehind: 3,
              expireSeconds: 120,
            }
          );
          toast.success(
            <div>
              Executed:{" "}
              <a
                href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}
                target={"_blank"}
                rel={"noreferrer"}
              >{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          farmingTalesStore.clearSellList();
          setTimeout(() => {
            farmingTalesStore.loadBarnProducts(String(activeUser.actor));
            farmingTalesStore.loadRecipes();
          }, 3000);
          // farmingTalesStore.loadRecipes();
        }
        // Create Box
        if (type === "box") {
          // mintchest
          const response = await activeUser.transact(
            {
              actions: [
                {
                  account: ftConstants.FARMINGGAME3,
                  name: "mintchest",
                  authorization: [
                    activeUser.permissionLevel,
                  ],
                  data: {
                    account: String(activeUser.actor),
                    product: content[0].key,
                    quantity: content[0].value,
                  },
                },
              ],
            },
            {
              broadcast: true,
              blocksBehind: 3,
              expireSeconds: 120,
            }
          );
          toast.success(
            <div>
              Executed:{" "}
              <a
                href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}
                target={"_blank"}
                rel={"noreferrer"}
              >{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          setTimeout(() => {
            farmingTalesStore.loadBarnProducts(String(activeUser.actor));
            farmingTalesStore.loadRecipes();
          }, 3000);
          // farmingTalesStore.loadRecipes();
        }
        // Complete Task
        if (type === "task") {
          let taskName = text
            .match(/\(.*\)/g)[0]
            .replace("(", "")
            .replace(")", "");
          // completetask
          const response = await activeUser.transact(
            {
              actions: [
                {
                  account: ftConstants.FARMINGGAME3,
                  name: "completetask",
                  authorization: [
                    activeUser.permissionLevel,
                  ],
                  data: {
                    account: String(activeUser.actor),
                    task: taskName,
                  },
                },
              ],
            },
            {
              broadcast: true,
              blocksBehind: 3,
              expireSeconds: 120,
            }
          );
          toast.success(
            <div>
              Executed:{" "}
              <a
                href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}
                target={"_blank"}
                rel={"noreferrer"}
              >{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          setTimeout(() => {
            farmingTalesStore.loadBarnProducts(String(activeUser.actor));
            farmingTalesStore.loadRecipes();
          }, 3000);
        }
        // Create Product
        if (type === "create") {
          // createprod
          const response = await activeUser.transact(
            {
              actions: [
                {
                  account: ftConstants.FARMINGGAME2,
                  name: "createprod",
                  authorization: [
                    activeUser.permissionLevel,
                  ],
                  data: {
                    user: String(activeUser.actor),
                    product: content[0].key,
                    quantity: content[0].value,
                    building: farmingTalesStore.getBuildingId(content[0].key),
                  },
                },
              ],
            }
          );
          toast.success(
            <div>
              Executed:{" "}
              <a
                href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}
                target={"_blank"}
                rel={"noreferrer"}
              >{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          setTimeout(() => {
            farmingTalesStore.loadBarnProducts(String(activeUser.actor));
            farmingTalesStore.loadRecipes();
          }, 3000);
        }
        farmingTalesStore.setModalInfo({
          show: false,
          title: "",
          text: "",
          content: [],
          type: "",
        });
      } else {
        toast.warn("Please login to buy ingredients!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "z-50",
          theme: "dark",
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (props?.session) {
      setSearchAccountName("");
      setSearchInput("");
      farmingTalesStore.loadConfChest();
      // farmingTalesStore.loadBarnProducts("wp.ba.wam");
      farmingTalesStore.loadBarnProducts(String(props.session.actor));
      farmingTalesStore.loadRecipes();
      farmingTalesStore.loadTasks();
    }
  }, [props?.session?.actor]);

  let barnProducts: any = store.barnProducts;
  let productRecipes: any = store.productRecipes;
  let sellList: any = store.sellList;
  let modalInfo: any = store.modalInfo;
  let isLoading: boolean = store.isLoading;

  return (
    <SecondaryRightContent>
      <div className="text-lg text-gray-700 dark:text-white font-bold flex items-center mb-6">
        Account to be searched:{" "}
        <TextInput
          className="ml-2 mr-2"
          helperText=""
          // size={4}
          // id="id"
          placeholder="Wax account"
          value={searchInput}
          classNameInputText="text-center"
          classNameHelperText="mt-0 ml-1"
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        <Button
          disabled={isLoading}
          onClick={async () => {
            if (searchInput !== "") {
              setSearchAccountName(searchInput);
              farmingTalesStore.markLoading(true);
              farmingTalesStore.clearData();
              await farmingTalesStore.loadConfChest();
              // farmingTalesStore.loadBarnProducts("wp.ba.wam");
              await farmingTalesStore.loadBarnProducts(searchInput);
              await farmingTalesStore.loadRecipes();
              await farmingTalesStore.loadTasks();
              farmingTalesStore.markLoading(false);
            }
          }}
        >
          {isLoading ? <Spinner color={"success"} /> : "Search"}
        </Button>
        <Button
          className="ml-2"
          color={"warning"}
          onClick={() => {
            if (searchInput !== "") {
              setSearchInput("");
              if (searchAccountName !== "") {
                setSearchAccountName("");
                if (props?.session) {
                  farmingTalesStore.clearData();
                  farmingTalesStore.loadConfChest();
                  // farmingTalesStore.loadBarnProducts("wp.ba.wam");
                  farmingTalesStore.loadBarnProducts(
                    String(props.session.actor)
                  );
                  farmingTalesStore.loadRecipes();
                  farmingTalesStore.loadTasks();
                } else {
                  farmingTalesStore.clearData();
                }
              }
            }
          }}
        >
          Clear
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <Card>
          <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
            <div>
              Product list (
              {barnProducts.reduce((a: any, b: any) => a + b.value, 0)})
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button
              onClick={() => {
                if (sellList.length > 0) {
                  farmingTalesStore.setModalInfo({
                    show: true,
                    title: "Sell Products",
                    text: "Are you sure you want to sell these products?",
                    content: sellList,
                    type: "sell",
                  });
                }
              }}
            >
              Sell Selected
            </Button>
            <Button
              className="ml-2 mr-2"
              color={"warning"}
              onClick={() => {
                farmingTalesStore.clearSellList();
              }}
            >
              Clear Sell List
            </Button>
            <Button
              color={"failure"}
              onClick={() => {
                let sellList = farmingTalesStore.getListSellAll();
                if (sellList.length > 0) {
                  farmingTalesStore.setModalInfo({
                    show: true,
                    title: "Sell Products",
                    text: "Are you sure you want to sell these products?",
                    content: sellList,
                    type: "sell",
                  });
                }
              }}
            >
              Sell All
            </Button>
          </div>
          <div className="text-gray-700 dark:text-white">
            {barnProducts.map((product: any) => (
              <div
                key={Math.random()}
                className="p-3 border border-dashed grid grid-cols-3 gap-4"
              >
                <div className="flex">
                  {getName(product.key)} - {product.value}{" "}
                  {expand.findIndex((t: any) => t.key === product.key) !==
                    -1 ? (
                    <FcCollapse
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        handleEpand(product);
                      }}
                    />
                  ) : (
                    <FcExpand
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        handleEpand(product);
                      }}
                    />
                  )}
                </div>
                {expand.findIndex((t: any) => t.key === product.key) !== -1 && (
                  <div className="col-span-2 grid grid-cols-3 gap-1 justify-self-end">
                    <div className="col-span-3 flex items-center">
                      <p className="mr-2">Add to Sell List:</p>
                      <TextInput
                        disabled={!product.sell}
                        helperText={`/${product.value}`}
                        size={4}
                        placeholder="0"
                        value={
                          farmingTalesStore.findSeletedSellListValue(
                            product
                          ) === 0
                            ? ""
                            : farmingTalesStore.findSeletedSellListValue(
                              product
                            )
                        }
                        type={"number"}
                        max={product.value}
                        min={0}
                        classNameInputText="text-center"
                        classNameHelperText="mt-0 ml-1"
                        onChange={(e) => {
                          let value = Number(e.target.value);

                          farmingTalesStore.handleSellList({
                            key: product.key,
                            value:
                              value > product.value ? product.value : value,
                          });
                        }}
                      />
                    </div>
                    <Button
                      disabled={!product.craft1}
                      onClick={() => {
                        farmingTalesStore.setModalInfo({
                          show: true,
                          title: "Create Box",
                          text: "Are you sure you want to create Small Box for this product? Cost:",
                          content: farmingTalesStore.getCreateBoxRecipe(
                            product,
                            0
                          ),
                          type: "box",
                        });
                      }}
                    >
                      Box 1
                    </Button>
                    <Button
                      disabled={!product.craft2}
                      onClick={() => {
                        farmingTalesStore.setModalInfo({
                          show: true,
                          title: "Create Box",
                          text: "Are you sure you want to create Medium Box for this product? Cost:",
                          content: farmingTalesStore.getCreateBoxRecipe(
                            product,
                            1
                          ),
                          type: "box",
                        });
                      }}
                    >
                      Box 2
                    </Button>
                    <Button
                      disabled={!product.craft3}
                      onClick={() => {
                        farmingTalesStore.setModalInfo({
                          show: true,
                          title: "Create Box",
                          text: "Are you sure you want to create Large Box for this product? Cost:",
                          content: farmingTalesStore.getCreateBoxRecipe(
                            product,
                            2
                          ),
                          type: "box",
                        });
                      }}
                    >
                      Box 3
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Card>
        <Card>
          <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
            <div>Create Product</div>
          </div>
          <div className="text-gray-700 dark:text-white">
            {productRecipes.map((recipe: any) => (
              <div
                key={Math.random()}
                className="p-3 border border-dashed grid grid-cols-3 gap-4"
              >
                <div className="col-span-2">
                  {getName(recipe.result)} (
                  {farmingTalesStore.getIngredientsString(recipe.ingredients)})
                </div>
                <CreateProductInput
                  recipe={recipe}
                  isActive={props.member.isActive}
                />
              </div>
            ))}
          </div>
        </Card>
        <Card>
          <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
            <div>Completable Tasks</div>
          </div>
          <div className="text-gray-700 dark:text-white">
            {farmingTalesStore.getCompletableTasks().map((task: any) => (
              <div
                key={Math.random()}
                className="p-3 border border-dashed z-50"
              >
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3">{task.label}</div>
                  <div className="flex items-center justify-self-end">
                    <Button
                      onClick={() => {
                        farmingTalesStore.setModalInfo({
                          show: true,
                          title: "Complete Task",
                          text: `Are you sure you want to Complete this task (${task.task})?`,
                          content: task.requirements.map((req: any) => {
                            return { key: req.key, value: req.value };
                          }),
                          type: "task",
                        });
                      }}
                      className="mr-2"
                    >
                      Complete
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
      <Modal
        size={"sm"}
        show={modalInfo.show}
        onClose={() => {
          farmingTalesStore.setModalInfo({ ...modalInfo, show: false });
        }}
      >
        <Modal.Header>{modalInfo.title}</Modal.Header>
        <Modal.Body>
          <div className="flex justify-between ">
            <p className="text-slate-500 dark:text-slate-400 text-m self-center">
              {modalInfo.text}
            </p>
          </div>
          {modalInfo.content.map((content: any) => (
            <div
              key={Math.random()}
              className="text-slate-500 dark:text-slate-100 text-sm self-center"
            >
              * {getName(content.key)}: {content.value}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer className="justify-center">
          <Button
            className="mr-2 font-bold text-base"
            onClick={() => {
              if (
                (props.session && searchAccountName === "") ||
                String(props?.session?.actor) === searchAccountName
              ) {
                handleSubmitModal(modalInfo, props.session);
              } else {
                toast.warn("Please login first!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  className: "z-50",
                  theme: "dark",
                });
              }
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </SecondaryRightContent>
  );
});

const FarmingTales = wrapProviderHOC(FarmingTalesScreen);
export { FarmingTales };
