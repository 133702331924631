const parsec = {
    GAME_CONTRACT: "cntrc.parsec",
    ENERGY_TABLE: "energy",
    RESOURCES_TABLE: "resources",
    RESEARCH_TABLE: "research",
    TECHNOLOGY_TABLE: "technology",
    SPACEPORT_TABLE: "spaceport",
    SHIPYARD_TABLE: "spaceport_shipyard",
    WAREHOUSE_TABLE: "warehouse",
    COMMANDER_CONFIG_TABLE: "confcommand",
    COMMANDERS_TABLE: "commanders",
    USERS_TABLE: "users",
    DEFENCE_TABLE: "defence",
    CONFIG_TECH_COST_TABLE: "conftechcost",
    CONFIG_ARM_TABLE: "confarm",
    START_UPGRADE_ENERGY_ACTION: "startupene",
    START_UPGRADE_RESOURCE_ACTION: "startupres",
    START_UPGRADE_BUILD_ACTION: "startupbuild",
    START_UPGRADE_TECH_ACTION: "startuptech",
    FINISH_UPGRADE_ENERGY_ACTION: "endupene",
    FINISH_UPGRADE_RESOURCE_ACTION: "endupres",
    FINISH_UPGRADE_BUILD_ACTION: "endupbuild",
    FINISH_UPGRADE_TECH_ACTION: "enduptech",
    CLAIM_RESOURCE_ACTION: "claimres",
    START_BUILD_DEFENCE_ACTION: "startbddef",
    FINISH_BUILD_DEFENCE_ACTION: "endbddef",
    START_BUILD_SHIP_ACTION: "startbdship",
    FINISH_BUILD_SHIP_ACTION: "endbdship",
    UNIVERSE_SPEED: 1,
    PANEL_COSTS: {
        PANEL_COST_TIT: 70,
        PANEL_COST_RUT: 30
    },
    SOLAR_COOLDOWN_SECONDS: 600,
    FULL_NAME: {
        nuclear: "Nuclear Power Plant",
        solar: "Solar Panel",
        titanium: "Titanium Mine",
        ruthenium: "Ruthenium Mine",
        helium: "Helium Extractor",
        food: "Farm",
        androids: "Androids Factory",
        ministry: "Ministry of Defense",
        university: "University",
        shipyard: "Shipyard",
        sentec: "Sensor Teconology",
        soleng: "Solid Fuel Engine",
        impeng: "Impulse Engine",
        fuseng: "Fusion Engine",
        anteng: "Antimatter Engine",
        acceler: "Particle Accellerator",
        atofus: "Atomic Fusion",
        anttec: "Antimatter Technology",
        meacul: "Meat Cultivation",
        shitec: "Shield Technology",
        weatec: "Weapons Technology",
        terrafo: "Terraforming",
        warehouse: "Warehouse",
        spaceport: "Spaceport",
        fleets: "Spaceport",
        storages: "Warehouse",
        reactor: "Nuclear Reactor",
        missiles: "Missile Launcher",
        mines: "Mine",
        laser: "Laser Turret",
        "ion.cannon": "Ion Cannon",
        artillery: "Artillery Position",
        "force.field": "Force Field",
        "support.vess": "Support",
        "light.cargo": "Light Cargo",
        "heavy.cargo": "Heavy Cargo",
        probe: "Probe",
        recycler: "Recycler",
        fighter: "Fighter",
        corvette: "Corvette",
        cruiser: "Cruiser",
        battleship: "Battleship",
        terraformer: "Terraformer",
    },
    DEFENCE_TIME_INDEX: {
        "missiles": 0,
        "mines": 1,
        "laser": 2,
        "ion.cannon": 3,
        "artillery": 4,
        "force.field": 5,
    },
    SHIPYARD_TIME_INDEX: {
        "support.vess": 0,
        "light.cargo": 1,
        "heavy.cargo": 2,
        "probe": 3,
        "recycler": 4,
        "fighter": 5,
        "corvette": 6,
        "cruiser": 7,
        "battleship": 8,
        "terraformer": 9,
    },
    localStorageKeys: {
        VERSION: "1.0.0",
        WALLET_ENERGY_ITEMS: "WALLET_ENERGY_ITEMS",
        WALLET_RESOURCE_ITEMS: "WALLET_RESOURCE_ITEMS",
        WALLET_TECH_ITEMS: "WALLET_TECH_ITEMS",
        WALLET_SHIPYARD_ITEMS: "WALLET_SHIPYARD_ITEMS",
        WALLET_DEFENCE_ITEMS: "WALLET_DEFENCE_ITEMS",
    }
};
export default parsec;