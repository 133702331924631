import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, useEffect, useState } from "react";
import { FcShop } from "react-icons/fc";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Modal,
  Spinner,
  TextInput,
} from "../../../../components";
import ftConstants from "../../../../constants/farmingtales/index";
import { getName, getUrl } from "../../../../constants/farmingtales/products";
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import wrapProviderHOC from "../../../../layouts/WrapProvider";
import { Ingredients } from "../components";
import { farmingTalesStore } from "../controller";

interface FTProps {
  session: any;
  member: any;
}

const TaskTrackerFTScreen: FC<FTProps> = observer(({ ...props }) => {
  let store: any = getSnapshot(farmingTalesStore);

  const [selectedTask, setSelectedTask] = useState([]);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    key: "",
    value: 0,
    max: 0,
  });
  const [searchAccountName, setSearchAccountName] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const handleSubmitModal = async (modalInfo: any, activeUser: any) => {
    try {
      let { key, value } = modalInfo;
      if (activeUser) {
        // createprod
        const response = await activeUser.transact(
          {
            actions: [
              {
                account: ftConstants.FARMINGGAME2,
                name: "createprod",
                authorization: [
                  activeUser.permissionLevel,
                ],
                data: {
                  user: String(activeUser.actor),
                  product: key,
                  quantity: value,
                  building: farmingTalesStore.getBuildingId(key),
                },
              },
            ],
          },
          {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 120,
          }
        );
        toast.success(
          <div>
            Executed:{" "}
            <a
              href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}
              target={"_blank"}
              rel={"noreferrer"}
            >{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setTimeout(() => {
          farmingTalesStore.loadBarnProducts(String(activeUser.actor));
          farmingTalesStore.loadRecipes();
        }, 3000);
        setModalInfo({
          show: false,
          key: "",
          value: 0,
          max: 0,
        });
      } else {
        toast.warn("Please login to buy ingredients!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "z-50",
          theme: "dark",
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    farmingTalesStore.clearData();
    const process = async () => {
      setSelectedTask([]);
      setSearchAccountName("");
      setSearchInput("");
      let isActive = await farmingTalesStore.getMemberActive(
        String(props?.session?.actor)
      );
      if (isActive) {
        if (props?.session) {
          farmingTalesStore.clearData();
          farmingTalesStore.loadConfChest();
          // farmingTalesStore.loadBarnProducts("wp.ba.wam");
          farmingTalesStore.loadBarnProducts(
            String(props?.session?.actor)
          );
          farmingTalesStore.loadRecipes();
          farmingTalesStore.loadTasks();
        }
      }
    };
    process();
  }, [props.session]);

  let tasks: any = store.tasks;
  let isLoading: boolean = store.isLoading;

  return (
    <SecondaryRightContent>
      <div className="text-lg text-gray-700 dark:text-white font-bold flex items-center mb-6">
        Account to be searched:{" "}
        <TextInput
          className="ml-2 mr-2"
          helperText=""
          // size={4}
          // id="id"
          placeholder="Wax account"
          value={searchInput}
          classNameInputText="text-center"
          classNameHelperText="mt-0 ml-1"
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        <Button
          disabled={isLoading}
          onClick={async () => {
            if (searchInput !== "") {
              let isActive = await farmingTalesStore.getMemberActive(
                searchInput
              );
              if (isActive) {
                setSearchAccountName(searchInput);
                setSelectedTask([]);
                farmingTalesStore.markLoading(true);
                farmingTalesStore.clearData();
                await farmingTalesStore.loadConfChest();
                // farmingTalesStore.loadBarnProducts("wp.ba.wam");
                await farmingTalesStore.loadBarnProducts(searchInput);
                await farmingTalesStore.loadRecipes();
                await farmingTalesStore.loadTasks();
                farmingTalesStore.markLoading(false);
              } else {
                toast.warn("Please subscribe to use this feature!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  className: "z-50",
                  theme: "dark",
                });
              }
            }
          }}
        >
          {isLoading ? <Spinner color={"success"} /> : "Search"}
        </Button>
        <Button
          className="ml-2"
          color={"warning"}
          onClick={() => {
            if (searchInput !== "") {
              setSearchInput("");
              if (searchAccountName !== "") {
                setSearchAccountName("");
                setSelectedTask([]);
                if (props?.session) {
                  farmingTalesStore.clearData();
                  farmingTalesStore.loadConfChest();
                  // farmingTalesStore.loadBarnProducts("wp.ba.wam");
                  farmingTalesStore.loadBarnProducts(
                    String(props.session.actor)
                  );
                  farmingTalesStore.loadRecipes();
                  farmingTalesStore.loadTasks();
                } else {
                  farmingTalesStore.clearData();
                }
              }
            }
          }}
        >
          Clear
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <Card>
          <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
            <div>Tasks Tracker</div>
          </div>
          <div className="text-gray-700 dark:text-white">
            {tasks.map((task: any) => (
              <div
                key={Math.random()}
                className="p-3 border border-dashed z-50"
              >
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3">
                    {task.label} ({farmingTalesStore.getTaskProgress(task)})
                  </div>
                  <div className="flex items-center justify-self-end">
                    <Button
                      color={
                        selectedTask.findIndex(
                          (t: any) => t.label === task.label
                        ) === -1
                          ? "success"
                          : "warning"
                      }
                      onClick={() => {
                        let index = selectedTask.findIndex(
                          (t: any) => t.label === task.label
                        );
                        if (index === -1) {
                          setSelectedTask(selectedTask.concat(task));
                        } else {
                          setSelectedTask(
                            selectedTask.filter(
                              (t: any) => t.label !== task.label
                            )
                          );
                        }
                      }}
                    >
                      {selectedTask.findIndex(
                        (t: any) => t.label === task.label
                      ) === -1
                        ? "Track"
                        : "Untrack"}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
        <Card>
          <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
            <div>Requirements</div>
          </div>
          <div className="text-gray-700 dark:text-white">
            {selectedTask.length > 1 && (
              <div className="mb-4">
                <b>
                  Total Progress:{" "}
                  {farmingTalesStore.getTotalTasksProgress(selectedTask)}
                </b>
              </div>
            )}
            {selectedTask.map((task: any) => (
              <div
                key={Math.random()}
                className="p-3 border border-dashed z-50"
              >
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3">
                    {task.label} ({farmingTalesStore.getTaskProgress(task)})
                  </div>
                </div>
                <div className="ml-6 mt-3 divide-y divide-dotted divide-slate-500">
                  {task.requirements.map((requirement: any) => (
                    <Ingredients
                      key={Math.random()}
                      ingredient={requirement}
                      setModalInfo={setModalInfo}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Card>
        <Card>
          <div className="text-lg text-gray-700 dark:text-white font-bold border-b-2 mb-1">
            <div>Farm Products Breakdown</div>
          </div>
          <div className="text-gray-700 dark:text-white">
            {selectedTask.map((task: any) => (
              <div
                key={Math.random()}
                className="p-3 border border-dashed z-50"
              >
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3">{task.label}</div>
                </div>
                <div className="ml-6 mt-3 divide-y divide-dotted divide-slate-500">
                  {farmingTalesStore
                    .getTaskBreakdown(task.requirements)
                    .map((farmProduct: any) => (
                      <div
                        key={Math.random()}
                        className="grid grid-cols-4 gap-4"
                      >
                        <div
                          className={`col-span-3 ${farmProduct.value === farmProduct.progress &&
                            "text-green-600"
                            }`}
                        >
                          * {getName(farmProduct.key)}
                          {": "}
                          {/* {farmingTalesStore.getProductInBarn(farmProduct)}/ */}
                          {farmProduct.progress}/{farmProduct.value}
                        </div>
                        <div className="justify-self-end">
                          <a
                            href={getUrl(farmProduct.key)}
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            <FcShop />
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
      <Modal
        size={"sm"}
        show={modalInfo.show}
        onClose={() => {
          setModalInfo({ show: false, key: "", value: 0, max: 0 });
        }}
      >
        <Modal.Header>Create Product</Modal.Header>
        <Modal.Body>
          <div className="grid grid-cols-3">
            <p className="text-slate-500 dark:text-slate-400 text-m self-center col-span-2">
              {getName(modalInfo.key)}
            </p>
            <div className="flex justify-self-end">
              <TextInput
                // helperText={`/${modalInfo.max}`}
                size={4}
                // id="id"
                placeholder="0"
                value={modalInfo.value === 0 ? "" : modalInfo.value}
                type="number"
                max={modalInfo.max}
                min={0}
                classNameInputText="text-center"
                classNameHelperText="mt-0 ml-1"
                onChange={(e) => {
                  let value = Number(e.target.value);
                  value > modalInfo.max
                    ? setModalInfo({ ...modalInfo, value: modalInfo.max })
                    : setModalInfo({ ...modalInfo, value });
                }}
              />
              <p className="text-slate-500 dark:text-slate-400 text-m self-center">
                /{modalInfo.max}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-center">
          <Button
            className="mr-2 font-bold text-base"
            onClick={() => {
              if (
                (props.session && searchAccountName === "") ||
                String(props?.session?.actor) === searchAccountName
              ) {
                handleSubmitModal(modalInfo, props.session);
              } else {
                toast.warn("Please login first!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  className: "z-50",
                  theme: "dark",
                });
              }
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </SecondaryRightContent>
  );
});

const TaskTrackerFT = wrapProviderHOC(TaskTrackerFTScreen);
export { TaskTrackerFT };
