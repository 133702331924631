import { types } from "mobx-state-tree";

export const Confanimal = types.model("Recipes", {
    template_id: types.number,
    label: types.string,
    resource_type: types.string,
    resource_count: types.number,
    sest_amount: types.number,
    cooldown: types.number,
    food: types.number,
})

export const Confbuildout = types.model("Recipes", {
    template_id: types.number,
    label: types.string,
    resource_type: types.string,
    resource_count: types.number,
    sest_amount: types.number,
    cooldown: types.number,
    food: types.number,
    water: types.number,
});

export const Confflower = types.model("Recipes", {
    template_id: types.number,
    label: types.string,
    cbit_amount: types.number,
    cooldown: types.number,
});

export const Confplant = types.model("Recipes", {
    id: types.number,
    label: types.string,
    resource_type: types.string,
    resource_count: types.number,
    sest_amount: types.number,
    cooldown: types.number,
    water: types.number,
});

export const Animaljob = types.model("Recipes", {
    owner: types.string,
    asset_id: types.number,
    resource_count: types.number,
    resource_type: types.string,
    food: types.number,
    water: types.number,
    date: types.Date,
});

export const Buildoutjob = types.model("Recipes", {
    owner: types.string,
    asset_id: types.number,
    resource_count: types.number,
    resource_type: types.string,
    food: types.number,
    water: types.number,
    date: types.Date,
});

export const Flowerjob = types.model("Recipes", {
    owner: types.string,
    asset_id: types.number,
    date: types.Date,
});

export const Plantjob = types.model("Recipes", {
    owner: types.string,
    id: types.number,
    resource_count: types.number,
    resource_type: types.string,
    food: types.number,
    water: types.number,
    date: types.Date,
});

export const Ingredient = types.model({
    key: types.string,
    value: types.number,
});

export const Recipe = types.model("Recipes", {
    result: types.string,
    ingredients: types.array(Ingredient),
    building_asset_ids: types.array(types.number),
})

export const Product = types.model("Recipes", {
    product_name: types.string,
    sest_value: types.number,
})

export const Tier = types.model("Recipes", {
    product_name: types.string,
    sest_value: types.number,
    recipe: Recipe
})

export const BarnProduct = types.model("Recipes", {
    key: types.string,
    value: types.number,
})