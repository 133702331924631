import axios from 'axios';
import { blockchains } from '../resources/data/blockchains';

// List of base URLs
const runningChain = blockchains[0];
const baseUrls = runningChain.aaEndpoints.map(endpoint => `${endpoint.protocol}://${endpoint.host}`);

// Create an instance of Axios
const axiosInstance = axios.create();

// Interceptor to modify request configuration
axiosInstance.interceptors.request.use(config => {
    // Select a random baseURL from the list
    const randomBaseUrl = baseUrls[Math.floor(Math.random() * baseUrls.length)];

    // Update the baseURL in the request configuration
    config.baseURL = randomBaseUrl;

    return config;
});

axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    const { response, config } = error;
    const { baseURL } = config;

    // Handle other errors
    console.error(`Request to ${baseURL} failed.`);

    // Throw the error to propagate it
    throw error;
});

export default axiosInstance;