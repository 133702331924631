import { APIClient } from "@wharfkit/antelope";
import { ContractKit } from "@wharfkit/contract";
import constants from "../constants/index";
import localStore from "../utils/localStore";

const getSingleData = async (contract: string, scope: string, table: string, key_value: any = undefined, params: any = {}) => {
    try {
        let rpc = localStore.getItem(constants.localStorageKeys.current_RPC) || "https://wax.greymass.com";
        const contractKit = new ContractKit({
            client: new APIClient({ url: rpc }),
        });
        const contractInstance = await contractKit.load(contract);
        const tableInstance = contractInstance.table(table, scope);
        const data = await tableInstance.get(key_value, params);
        return data;
    } catch (error) {
        console.log("Error", error);
        return undefined;
    }
}

const getMultiDataCursor = async (contract: string, scope: string, table: string, params: any = {}) => {
    let rpc = localStore.getItem(constants.localStorageKeys.current_RPC) || "https://wax.greymass.com";
    const contractKit = new ContractKit({
        client: new APIClient({ url: rpc }),
    });
    const contractInstance = await contractKit.load(contract);
    return contractInstance.table(table, scope).query(params);
}

const cursorNext = async (cursor: any) => {
    return await cursor.next();
}
const cursorAll = async (cursor: any) => {
    return await cursor.all();
}
const cursorReset = async (cursor: any) => {
    return await cursor.reset();
}

export { cursorAll, cursorNext, cursorReset, getMultiDataCursor, getSingleData };

