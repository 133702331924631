import { types } from "mobx-state-tree";

export const supplyActionModalItem = types.model("supplyActionModalItem", {
    isShow: types.boolean,
    isDeposit: types.boolean,
    wallet: types.string,
    title: types.string,
    quantity: types.number,
    max_quantity: types.number,
    template_id: types.number,
    available_asset_ids: types.array(types.string),
})
