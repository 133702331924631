import { values } from "mobx";
import { flow, types, cast } from "mobx-state-tree";
import ftConstants from "../../../../constants/farmingtales/index";
import { getInventories, getAllTableRows, getTableRow } from "../../../../services/api";
import { Confanimal, Confbuildout, Confflower, Confplant, Animaljob, Buildoutjob, Flowerjob, Plantjob, Recipe, Product, Tier, BarnProduct } from "../../domain/recipes";
import { checkBarnResource, harvestAnimal, harvestBuildout, harvestFlower, harvestPlant, wait } from "./helpers";
import { getName } from "../../../../constants/farmingtales/products";

const Bot = types
    .model("Recipes", {
        confanimal: types.array(Confanimal),
        confbuildout: types.array(Confbuildout),
        confflower: types.array(Confflower),
        confplant: types.array(Confplant),
        animalJobs: types.array(Animaljob),
        buildoutJobs: types.array(Buildoutjob),
        flowerJobs: types.array(Flowerjob),
        plantJobs: types.array(Plantjob),
        ready: types.boolean,
        products: types.array(Product),
        recipes: types.array(Recipe),
        tier1: types.array(Tier),
        tier2: types.array(Tier),
        tier3: types.array(Tier),
        tier4: types.array(Tier),
        tier5: types.array(Tier),
        tier6: types.array(Tier),
        tier7: types.array(Tier),
        tier8: types.array(Tier),
        tier9: types.array(Tier),
        tier10: types.array(Tier),
        barnProducts: types.array(BarnProduct),
        producing: types.array(types.string),
        selectedProducts: types.array(types.string),
        filteredTier2: types.array(Tier),
        filteredTier3: types.array(Tier),
        filteredTier4: types.array(Tier),
        filteredTier5: types.array(Tier),
        filteredTier6: types.array(Tier),
        filteredTier7: types.array(Tier),
        filteredTier8: types.array(Tier),
        filteredTier9: types.array(Tier),
        filteredTier10: types.array(Tier),
        selectedPs: types.array(types.string)
    })
    .views((self) => ({}))
    .actions((self) => {
        const scheduleAnimals = (animals: any) => {
            let schedule: any = [];
            for (let animal of animals) {
                let conf = self.confanimal.find((c) => c.template_id === animal.template_id);
                if (conf) {
                    let nextHarvestAt = (animal.last_harvest + conf.cooldown) * 1000;
                    let date = new Date(nextHarvestAt);
                    schedule.push({
                        owner: animal.owner,
                        asset_id: Number(animal.asset_id),
                        resource_count: Number(conf.resource_count),
                        resource_type: conf.resource_type,
                        food: Number(conf.food),
                        water: 0,
                        date: date,
                    })
                }
            }
            self.animalJobs = cast(schedule);
        }

        const scheduleBuildouts = (buildouts: any) => {
            let schedule: any = [];
            for (let buildout of buildouts) {
                let conf = self.confbuildout.find((c) => c.template_id === buildout.template_id);
                if (conf) {
                    let nextHarvestAt = (buildout.last_harvest + conf.cooldown) * 1000;
                    let date = new Date(nextHarvestAt);
                    schedule.push({
                        owner: buildout.owner,
                        asset_id: Number(buildout.asset_id),
                        resource_count: Number(conf.resource_count),
                        resource_type: conf.resource_type,
                        food: Number(conf.food),
                        water: Number(conf.water),
                        date: date,
                    })
                }
            }
            self.buildoutJobs = cast(schedule);
        }

        const scheduleFlowers = (flowers: any) => {
            let schedule: any = [];
            for (let flower of flowers) {
                let conf = self.confflower.find((c) => c.template_id === flower.template_id);
                if (conf) {
                    let nextHarvestAt = (flower.last_harvest + conf.cooldown) * 1000;
                    let date = new Date(nextHarvestAt);
                    schedule.push({
                        owner: flower.owner,
                        asset_id: Number(flower.asset_id),
                        date: date,
                    })
                }
            }
            self.flowerJobs = cast(schedule);
        }

        const schedulePlants = (plants: any) => {
            let schedule: any = [];
            for (let plant of plants) {
                let conf = self.confplant.find((c) => c.id === plant.plant_id);
                if (conf) {
                    let nextHarvestAt = (plant.last_harvest + conf.cooldown) * 1000;
                    let date = new Date(nextHarvestAt);
                    schedule.push({
                        owner: plant.owner,
                        id: Number(plant.id),
                        resource_count: Number(conf.resource_count),
                        resource_type: conf.resource_type,
                        food: 0,
                        water: Number(conf.water),
                        date: date,
                    })
                }
            }
            self.plantJobs = cast(schedule);
        }

        const loadConf = flow(function* () {
            try {
                const confanimal = yield getAllTableRows({
                    table: ftConstants.CONFANIMAL_TABLE_GAME1,
                    code: ftConstants.FARMINGGAMES,
                    scope: ftConstants.FARMINGGAMES,
                });
                const confbuildout = yield getAllTableRows({
                    table: ftConstants.CONFBUILDOUT_TABLE_GAME1,
                    code: ftConstants.FARMINGGAMES,
                    scope: ftConstants.FARMINGGAMES,
                });
                const confflower = yield getAllTableRows({
                    table: ftConstants.CONFFLOWER_TABLE_GAME1,
                    code: ftConstants.FARMINGGAMES,
                    scope: ftConstants.FARMINGGAMES,
                });
                const confplant = yield getAllTableRows({
                    table: ftConstants.CONFPLANT_TABLE_GAME1,
                    code: ftConstants.FARMINGGAMES,
                    scope: ftConstants.FARMINGGAMES,
                });
                self.confanimal = cast(confanimal.rows);
                self.confbuildout = cast(confbuildout.rows);
                self.confflower = cast(confflower.rows);
                self.confplant = cast(confplant.rows);
            } catch (e) {
                console.log("err", e);
            }
        });

        const updateProducing = (animals: any, buildouts: any, plants: any) => {
            let producing: any = [];
            for (let animal of animals) {
                let conf = self.confanimal.find((c) => c.template_id === animal.template_id);
                if (conf) {
                    producing.push(conf.resource_type);
                }
            }

            for (let buildout of buildouts) {
                let conf = self.confbuildout.find((c) => c.template_id === buildout.template_id);
                if (conf) {
                    producing.push(conf.resource_type);
                }
            }

            for (let plant of plants) {
                let conf = self.confplant.find((c) => c.id === plant.plant_id);
                if (conf) {
                    producing.push(conf.resource_type);
                }
            }

            producing = [...new Set(producing)];

            self.producing = cast(producing);
        }

        const loadInventories = flow(function* (owner: string) {
            try {
                const animals = yield getInventories({
                    code: ftConstants.FARMINGGAMES,
                    table: ftConstants.ANIMAL_TABLE_GAME1,
                    owner: owner,
                })

                const buildouts = yield getInventories({
                    code: ftConstants.FARMINGGAMES,
                    table: ftConstants.BUILDOUT_TABLE_GAME1,
                    owner: owner,
                })

                const flowers = yield getInventories({
                    code: ftConstants.FARMINGGAMES,
                    table: ftConstants.FLOWER_TABLE_GAME1,
                    owner: owner,
                })

                const plants = yield getInventories({
                    code: ftConstants.FARMINGGAMES,
                    table: ftConstants.PLANT_TABLE_GAME1,
                    owner: owner,
                })

                scheduleAnimals(animals);
                scheduleBuildouts(buildouts);
                scheduleFlowers(flowers);
                schedulePlants(plants);

                updateProducing(animals, buildouts, plants);
            } catch (e) {
                console.log("err", e);
            }
        });

        const autoHarvest: any = flow(function* (activeUser: any) {
            try {
                let now = new Date();
                let animalJobs = self.animalJobs.filter((job) => job.date < now);
                let buildoutJobs = self.buildoutJobs.filter((job) => job.date < now);
                let flowerJobs = self.flowerJobs.filter((job) => job.date < now);
                let plantJobs = self.plantJobs.filter((job) => job.date < now);

                console.log(animalJobs.length, buildoutJobs.length, flowerJobs.length, plantJobs.length);

                for (let job of animalJobs) {
                    let { owner, asset_id, resource_count, resource_type, food, water } = job;
                    let ready = yield checkBarnResource(activeUser, owner, resource_count, food, water);
                    let itemJson = yield getTableRow({
                        table: ftConstants.ANIMAL_TABLE_GAME1,
                        code: ftConstants.FARMINGGAMES,
                        scope: ftConstants.FARMINGGAMES,
                        lower_bound: `${asset_id}`
                    })
                    if (itemJson.rows.length === 0) {
                        continue;
                    }
                    let item = itemJson.rows[0];
                    if (ready && item) {
                        console.log("harvest animal", owner, asset_id)
                        yield harvestAnimal(activeUser, owner, asset_id);
                        yield wait(5000);
                    }
                }

                for (let job of buildoutJobs) {
                    let { owner, asset_id, resource_count, resource_type, food, water } = job;
                    let ready = yield checkBarnResource(activeUser, owner, resource_count, food, water);
                    let itemJson = yield getTableRow({
                        table: ftConstants.BUILDOUT_TABLE_GAME1,
                        code: ftConstants.FARMINGGAMES,
                        scope: ftConstants.FARMINGGAMES,
                        lower_bound: `${asset_id}`
                    })
                    if (itemJson.rows.length === 0) {
                        continue;
                    }
                    let item = itemJson.rows[0];
                    if (ready && item) {
                        console.log("harvest buildout", owner, asset_id)
                        yield harvestBuildout(activeUser, owner, asset_id);
                        yield wait(5000);
                    }
                }

                for (let job of flowerJobs) {
                    let { owner, asset_id } = job;
                    let itemJson = yield getTableRow({
                        table: ftConstants.FLOWER_TABLE_GAME1,
                        code: ftConstants.FARMINGGAMES,
                        scope: ftConstants.FARMINGGAMES,
                        lower_bound: `${asset_id}`
                    })
                    if (itemJson.rows.length === 0) {
                        continue;
                    }
                    let item = itemJson.rows[0];
                    if (item) {
                        console.log("harvest flower", owner, asset_id)
                        yield harvestFlower(activeUser, owner, asset_id);
                        yield wait(5000);
                    }
                }

                for (let job of plantJobs) {
                    let { owner, id, resource_count, resource_type, food, water } = job;
                    let ready = yield checkBarnResource(activeUser, owner, resource_count, food, water);
                    let itemJson = yield getTableRow({
                        table: ftConstants.PLANT_TABLE_GAME1,
                        code: ftConstants.FARMINGGAMES,
                        scope: ftConstants.FARMINGGAMES,
                        lower_bound: `${id}`
                    })
                    if (itemJson.rows.length === 0) {
                        continue;
                    }
                    let item = itemJson.rows[0];
                    if (ready && item) {
                        console.log("harvest plant", owner, id)
                        yield harvestPlant(activeUser, owner, id);
                        yield wait(5000);
                    }
                }
            } catch (e) {
                console.log("err", e);
            }
        });

        const setReady = (ready: boolean) => {
            self.ready = ready;
        }

        const findNextTier = (previousTier: any, recipes: any, products: any) => {
            if (previousTier.length === 0) {
                return [];
            }
            let nextTier = [];
            for (let recipe of recipes) {
                let onlyPreviousTier = true;
                for (let ingredient of recipe.ingredients) {
                    let index = previousTier.findIndex((prod: any) => prod.product_name === ingredient.key);
                    if (index === -1) {
                        onlyPreviousTier = false;
                        break;
                    }
                }
                if (onlyPreviousTier) {
                    let product = products.find((prod: any) => prod.product_name === recipe.result);
                    let index = previousTier.findIndex((prod: any) => prod.product_name === recipe.result);
                    if (product && index === -1) {
                        // nextTier.push(product);
                        nextTier.push({ ...product, recipe: recipe })
                    }
                }
            }
            return nextTier;
        }

        function updateRecipesProducts(recipes: any, buildings: any, products: any) {
            let newRecipes = [];
            for (let recipe of recipes.rows) {
                let building_asset_ids = buildings.rows.filter((value: any) =>
                    value.recipes.includes(recipe.result)
                ).map((value: any) => Number(value.asset_id))
                if (building_asset_ids.length) {
                    newRecipes.push({
                        result: recipe.result,
                        ingredients: cast(recipe.ingredients),
                        building_asset_ids: building_asset_ids,
                    });
                } else {
                }
            }

            let tier1 = [];
            for (let product of products.rows) {
                if (product.sest_value === 0) {
                    tier1.push({
                        ...product, recipe: {
                            result: "",
                            ingredients: [],
                            building_asset_ids: [],
                        }
                    })
                }
            }

            let tier2 = findNextTier(tier1, newRecipes, products.rows);
            let tier3 = findNextTier([...tier1, ...tier2], newRecipes, products.rows);
            let tier4 = findNextTier([...tier1, ...tier2, ...tier3], newRecipes, products.rows);
            let tier5 = findNextTier([...tier1, ...tier2, ...tier3, ...tier4], newRecipes, products.rows);
            let tier6 = findNextTier([...tier1, ...tier2, ...tier3, ...tier4, ...tier5], newRecipes, products.rows);
            let tier7 = findNextTier([...tier1, ...tier2, ...tier3, ...tier4, ...tier5, ...tier6], newRecipes, products.rows);
            let tier8 = findNextTier([...tier1, ...tier2, ...tier3, ...tier4, ...tier5, ...tier6, ...tier7], newRecipes, products.rows);
            let tier9 = findNextTier([...tier1, ...tier2, ...tier3, ...tier4, ...tier5, ...tier6, ...tier7, ...tier8], newRecipes, products.rows);
            let tier10 = findNextTier([...tier1, ...tier2, ...tier3, ...tier4, ...tier5, ...tier6, ...tier7, ...tier8, ...tier9], newRecipes, products.rows);

            self.recipes = cast(newRecipes);
            self.products = cast(products.rows);
            self.tier1 = cast(tier1);
            self.tier2 = cast(tier2);
            self.tier3 = cast(tier3);
            self.tier4 = cast(tier4);
            self.tier5 = cast(tier5);
            self.tier6 = cast(tier6);
            self.tier7 = cast(tier7);
            self.tier8 = cast(tier8);
            self.tier9 = cast(tier9);
            self.tier10 = cast(tier10);

            self.filteredTier2 = cast(tier2);
            self.filteredTier3 = cast(tier3);
            self.filteredTier4 = cast(tier4);
            self.filteredTier5 = cast(tier5);
            self.filteredTier6 = cast(tier6);
            self.filteredTier7 = cast(tier7);
            self.filteredTier8 = cast(tier8);
            self.filteredTier9 = cast(tier9);
            self.filteredTier10 = cast(tier10);
        }

        const loadRecipesProducts = flow(function* () {
            try {
                const json = yield getAllTableRows({
                    table: ftConstants.RECIPES_TABLE_GAME2,
                    code: ftConstants.FARMINGGAME2,
                    scope: ftConstants.FARMINGGAME2,
                    limit: 200
                })

                const buildings = yield getAllTableRows({
                    table: ftConstants.BUILDINGS_TABLE_GAME2,
                    code: ftConstants.FARMINGGAME2,
                    scope: ftConstants.FARMINGGAME2,
                    limit: 100
                })

                const products = yield getAllTableRows({
                    table: ftConstants.PRODUCTS_TABLE_GAME2,
                    code: ftConstants.FARMINGGAME2,
                    scope: ftConstants.FARMINGGAME2,
                    limit: 200
                })

                updateRecipesProducts(json, buildings, products)
            } catch (err) {
                console.log("err", err);
            }
        })

        const getIngredientsString = (ingredients: any) => {
            let ingredientsString = "";
            let count = 0;
            for (let ingredient of ingredients) {
                ingredientsString += `${getName(ingredient.key)} - ${ingredient.value}${count < ingredients.length - 1 ? ", " : ""}`;
                count++;
            }
            return ingredientsString;
        }

        function updateBarnProducts(products: any) {
            self.barnProducts = products;
        }

        const loadBarnProducts = flow(function* (account: string) {
            try {
                const json = yield getTableRow({
                    table: ftConstants.USERS_TABLE_GAME2,
                    code: ftConstants.FARMINGGAME2,
                    scope: ftConstants.FARMINGGAME2,
                    lower_bound: account
                })

                if (json.rows.length > 0) {
                    updateBarnProducts(json.rows[0].products)
                }
            } catch (err) {
                console.log("err", err);
            }
        });

        const checkProductInBarn = (product: any) => {
            let found = false;
            for (let barnProduct of self.barnProducts) {
                if (barnProduct.key === product.product_name) {
                    found = true;
                    break;
                }
            }
            return found;
        }

        const checkProductInProducing = (product: any) => {
            let found = false;
            for (let producing of self.producing) {
                if (producing === product.product_name) {
                    found = true;
                    break;
                }
            }
            return found;
        }

        const filterTierFromSelectedProducts = (tier: any, selectedProducts: any) => {
            let newTier = [];
            // let selectedProducts = self.selectedProducts;
            for (let product of tier) {
                let canProduce = true;
                for (let ingredient of product.recipe.ingredients) {
                    if (!selectedProducts.includes(ingredient.key)) {
                        canProduce = false;
                        break;
                    }
                }
                if (canProduce) {
                    let newProduct = { ...product };
                    let ingredients = product.recipe.ingredients.map((ingredient: any) => {
                        return {
                            key: ingredient.key,
                            value: ingredient.value
                        }
                    })

                    let building_asset_ids = [...product.recipe.building_asset_ids]
                    newProduct.recipe = { result: product.recipe.result, ingredients, building_asset_ids };
                    // console.log({ newProduct })
                    newTier.push(newProduct);
                }
            }
            return newTier;
        }

        const cloneTier = (tier: any) => {
            return tier.map((product: any) => {
                let newProduct = { ...product };
                let ingredients = product.recipe.ingredients.map((ingredient: any) => {
                    return {
                        key: ingredient.key,
                        value: ingredient.value
                    }
                })

                let building_asset_ids = [...product.recipe.building_asset_ids]
                newProduct.recipe = { result: product.recipe.result, ingredients, building_asset_ids };
                // console.log({ newProduct })
                return newProduct
            })
        }

        const setSelectedProducts = (product: any) => {
            let selectedProducts = self.selectedProducts;
            let index = selectedProducts.indexOf(product);
            if (index > -1) {
                selectedProducts.splice(index, 1);
            } else {
                selectedProducts.push(product);
            }
            self.selectedProducts = selectedProducts;

            if (selectedProducts.length === 0) {
                self.filteredTier2 = cloneTier(self.tier2);
                self.filteredTier3 = cloneTier(self.tier3);
                self.filteredTier4 = cloneTier(self.tier4);
                self.filteredTier5 = cloneTier(self.tier5);
                self.filteredTier6 = cloneTier(self.tier6);
                self.filteredTier7 = cloneTier(self.tier7);
                self.filteredTier8 = cloneTier(self.tier8);
                self.filteredTier9 = cloneTier(self.tier9);
                self.filteredTier10 = cloneTier(self.tier10);
            } else {
                let filteredTier2 = filterTierFromSelectedProducts(self.tier2, selectedProducts);
                let filteredTier3 = filterTierFromSelectedProducts(self.tier3, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name)]);
                let filteredTier4 = filterTierFromSelectedProducts(self.tier4, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name), ...filteredTier3.map((p: any) => p.product_name)]);
                let filteredTier5 = filterTierFromSelectedProducts(self.tier5, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name), ...filteredTier3.map((p: any) => p.product_name), ...filteredTier4.map((p: any) => p.product_name)]);
                let filteredTier6 = filterTierFromSelectedProducts(self.tier6, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name), ...filteredTier3.map((p: any) => p.product_name), ...filteredTier4.map((p: any) => p.product_name), ...filteredTier5.map((p: any) => p.product_name)]);
                let filteredTier7 = filterTierFromSelectedProducts(self.tier7, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name), ...filteredTier3.map((p: any) => p.product_name), ...filteredTier4.map((p: any) => p.product_name), ...filteredTier5.map((p: any) => p.product_name), ...filteredTier6.map((p: any) => p.product_name)]);
                let filteredTier8 = filterTierFromSelectedProducts(self.tier8, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name), ...filteredTier3.map((p: any) => p.product_name), ...filteredTier4.map((p: any) => p.product_name), ...filteredTier5.map((p: any) => p.product_name), ...filteredTier6.map((p: any) => p.product_name), ...filteredTier7.map((p: any) => p.product_name)]);
                let filteredTier9 = filterTierFromSelectedProducts(self.tier9, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name), ...filteredTier3.map((p: any) => p.product_name), ...filteredTier4.map((p: any) => p.product_name), ...filteredTier5.map((p: any) => p.product_name), ...filteredTier6.map((p: any) => p.product_name), ...filteredTier7.map((p: any) => p.product_name), ...filteredTier8.map((p: any) => p.product_name)]);
                let filteredTier10 = filterTierFromSelectedProducts(self.tier10, [...selectedProducts, ...filteredTier2.map((p: any) => p.product_name), ...filteredTier3.map((p: any) => p.product_name), ...filteredTier4.map((p: any) => p.product_name), ...filteredTier5.map((p: any) => p.product_name), ...filteredTier6.map((p: any) => p.product_name), ...filteredTier7.map((p: any) => p.product_name), ...filteredTier8.map((p: any) => p.product_name), ...filteredTier9.map((p: any) => p.product_name)]);

                self.filteredTier2 = cast(filteredTier2);
                self.filteredTier3 = cast(filteredTier3);
                self.filteredTier4 = cast(filteredTier4);
                self.filteredTier5 = cast(filteredTier5);
                self.filteredTier6 = cast(filteredTier6);
                self.filteredTier7 = cast(filteredTier7);
                self.filteredTier8 = cast(filteredTier8);
                self.filteredTier9 = cast(filteredTier9);
                self.filteredTier10 = cast(filteredTier10);
            }
        }

        const getProductIngredients = (product_name: any) => {
            if (self.selectedPs[0] === product_name) {
                self.selectedPs = cast([]);
            } else {
                let items = [{ key: product_name, processed: false }];

                let ingredients = getIngredientsArrayString(items);

                // console.log({ ingredients })

                self.selectedPs = ingredients;
            }
        }

        const getIngredientsArrayString: any = (ingredients: any) => {
            let items = [];

            let has_ingredients = false;
            for (let ingre of ingredients) {
                items.push({ ...ingre, processed: true });
                if (!ingre.processed) {
                    let r = self.recipes.find((r: any) => r.result === ingre.key);
                    if (r) {
                        for (let i of r.ingredients) {
                            items.push({ key: i.key, processed: false });
                            has_ingredients = true;
                        }
                    }
                }

            }

            if (has_ingredients) {
                return getIngredientsArrayString(items);
            } else {
                return items.map((i: any) => i.key);
            }
        }

        return {
            loadConf,
            loadInventories,
            autoHarvest,
            setReady,
            loadRecipesProducts,
            getIngredientsString,
            loadBarnProducts,
            checkProductInBarn,
            checkProductInProducing,
            setSelectedProducts,
            filterTierFromSelectedProducts,
            getProductIngredients
        }
    })

export const botFTStore = Bot.create({
    confanimal: [],
    confbuildout: [],
    confflower: [],
    confplant: [],
    animalJobs: [],
    buildoutJobs: [],
    flowerJobs: [],
    plantJobs: [],
    ready: false,
    products: [],
    recipes: [],
    tier1: [],
    tier2: [],
    tier3: [],
    tier4: [],
    tier5: [],
    tier6: [],
    tier7: [],
    tier8: [],
    tier9: [],
    tier10: [],
    barnProducts: [],
    producing: [],
    selectedProducts: [],
    filteredTier2: [],
    filteredTier3: [],
    filteredTier4: [],
    filteredTier5: [],
    filteredTier6: [],
    filteredTier7: [],
    filteredTier8: [],
    filteredTier9: [],
    filteredTier10: [],
    selectedPs: []
})