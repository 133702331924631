import { types } from "mobx-state-tree";

export const SupplyRenderItem = types.model("SupplyRenderItem", {
    item_name: types.string,
    media: types.string,
    template_id: types.number,
    unit_price: types.string,
    rarity: types.string,
    available: types.number,
    deposited: types.number,
    available_asset_ids: types.array(types.string),
})
