import { Button } from "../../../../components";
import { sellbotStore } from "./controller";

interface props {
  nft: {
    image: string;
    video: string;
    name: string;
    asset_id: string;
    selected: boolean;
    template_mint: string;
    collection_name: string;
  };
}

const SelectedNFT = (props: props) => {
  let { nft } = props;
  return (
    <div className="bg-slate-700 pr-2 grid grid-cols-6 gap-4 my-2 items-center rounded-lg ">
      <div className="col-span-2 flex items-center">
        {nft.image ? (
          <img
            className="max-h-16"
            src={`https://atomichub-ipfs.com/ipfs/${nft.image}`}
            alt={nft.name}
          />
        ) : (
          <video autoPlay loop playsInline className="max-h-16">
            <source src={`https://ipfs.io/ipfs/${nft.video}`} />
          </video>
        )}
        <p className="text-white font-bold text-2xl ml-4">{nft.name}</p>
      </div>
      {/* <div className="col-span-1 p-2">
        <p className="text-white font-bold text-2xl">{nft.name}</p>
      </div> */}
      <div className="col-span-3 p-2 ">
        <p className="text-white">
          Asset_id: <b>{nft.asset_id}</b> Mint: <b>{nft.template_mint}</b>
          {"   "}
          Collection: <b>{nft.collection_name}</b>
        </p>
      </div>
      <div className="col-span-1 flex justify-end">
        <Button className="mr-2">
          <a
            href={`https://atomichub.io/explorer/asset/wax-mainnet/${nft.name.replace(
              " ",
              "-"
            )}_${nft.asset_id}`}
            target={"_blank"}
            rel={"noreferrer"}
          >
            View
          </a>
        </Button>
        <Button
          color={"failure"}
          onClick={() => {
            sellbotStore.onSelect(nft.asset_id, false);
          }}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};

export default SelectedNFT;
