const constants = {
    localStorageKeys: {
        current_RPC: "currentRPC",
        current_AA: "current_AA",
        MEMBER: "MEMBER",
    },
    ATOMICASSETS_CONTRACT: "atomicassets",
    ATOMICASSETS_TRANSFER_ACTION: "transfer",
    WAXIMUS_CENTRAL: {
        MEMBER_TABLE: "members",
        CENTRAL_ACCOUNT: "waximcentral",
        TIERS_TABLE: "tiers",
    }
}
export default constants;