import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, useEffect } from "react";
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import mainConstants from "../../../constants/index";
import { SecondaryRightContent } from "../../../layouts/SecondaryRightContent";
import { subStore } from "./controller";
interface Props {
    session: any;
}

const Subscription: FC<Props> = observer(({ ...props }) => {
    const store: any = getSnapshot(subStore);

    useEffect(() => {
        subStore.loadTiers();

        if (props.session) {
            subStore.loadMember(String(props.session.actor));
        }
    }, [props.session]);

    const tiers: any = store.tiers;
    const member: any = store.member;
    console.log("tiers", tiers);

    const handleBuySubscription = async (selected_fee_index: number) => {
        if (props.session) {

            await props.session.transact({
                actions: [
                    {
                        account: tiers[0].fee_per_month[selected_fee_index].contract,
                        name: "transfer",
                        authorization: [props.session.permissionLevel],
                        data: {
                            from: String(props.session.actor),
                            to: mainConstants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                            quantity: tiers[0].fee_per_month[selected_fee_index].quantity,
                            memo: `subscribe;${tiers[0].tier_name}`,
                        },
                    }
                ]
            }, {
                broadcast: true,
                blocksBehind: 3,
                expireSeconds: 120,
            }).then((response: any) => {
                toast.success(<div>Executed: <a href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}>{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a></div>, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                setTimeout(() => { window.location.reload(); }, 5000);
            })
                .catch((err: any) => {
                    console.log("err", err);
                    toast.warn(`${err}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                });
        } else {

            toast.warn("Please login to buy subscription!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    };

    return (
        <SecondaryRightContent>
            <div className="">
                <p className='text-2xl text-yellow-400 font-bold  text-center m-1'>Waximus Prime Membership now has the following benefits:</p>
                <div className='flex-row flex justify-center'>
                    <ul className='list-disc list-inside'>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Access to all features of the <span className='text-rose-500'>Parsec Auto</span></li>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Access to all features of the <span className='text-rose-500'>Combz Auto</span></li>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Access to all features of the <span className='text-rose-500'>Farming Tales</span></li>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Access to all features of the <span className='text-rose-500'>Auto Taco Kiosk</span></li>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Ingredient buyer: refund kiosk vendor profit (7% profit) <span className='text-rose-500'>(Only Waximus Taco Kiosk)</span> </li>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Ingredient supplier: refund kiosk system profit (3% profit) <span className='text-rose-500'>(Only Waximus Taco Kiosk)</span></li>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Able to set min price, have extra 200 deposit slots, reduce 50% system fee, extra 10 price updates, 7 additional days of storing NFT in Dapp <span className='text-rose-500'>(Auto NFT Seller)</span></li>
                        <li className='text-xl text-gray-700 dark:text-white text-left m-1'>Reduce 50% progress fee of <span className='text-rose-500'>Mars Operator Program</span></li>
                    </ul>
                </div>

                <div className='container mx-auto border-dashed border-2 border-indigo-50 mt-5' >
                    {member.isActive ? (
                        <div className="m-3">
                            <p className="text-basic text-gray-700 dark:text-white text-center">
                                Your subscription is active. You're using {member.tier_name.charAt(0).toUpperCase() + member.tier_name.slice(1)} tier. Your subscription will expire on {new Date(member.expired_at).toString()}.
                            </p>
                        </div>
                    ) : (
                        <div className="m-3">
                            <p className="text-basic text-gray-700 dark:text-white text-center">
                                You are not a standard member. Please subscribe to the tier by click one of these buttons below to enjoy 30 days of benefits.
                            </p>
                            {tiers[0]?.fee_per_month.map((fee: any, index: number) => {
                                return (
                                    <div className="flex justify-center flex-row mt-2" key={index}>
                                        <Button color={"gray"}
                                            className='basis-1/5 dark:bg-gray-900 font-bold text-red text-base  dark:border-amber-600'
                                            outline
                                            style={{ backgroundColor: "rgba(17,24,39,1)", }}
                                            fullSized
                                            onClick={() => { handleBuySubscription(index) }}>
                                            <div className='flex justify-center content-center  text-center text-amber-600 font-bold'>
                                                <div className=''>
                                                    Pay {fee.quantity}
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                );
                            })}

                        </div>

                    )}
                </div>
            </div>
        </SecondaryRightContent >
    );
});
export { Subscription };
