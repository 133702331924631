import { ComponentProps, FC } from "react";
import { AiFillDashboard } from "react-icons/ai";
import { BiStoreAlt } from "react-icons/bi";
import { FaWarehouse } from "react-icons/fa";
import { FaSpaceAwesome } from "react-icons/fa6";
import {
  Gi3DGlasses,
  GiBarn,
  GiFarmTractor,
  GiFarmer,
  GiTacos,
} from "react-icons/gi";
import { IoStorefront } from "react-icons/io5";
import { LiaRobotSolid } from "react-icons/lia";
import { MdRememberMe } from "react-icons/md";
import { SiCoinmarketcap, SiCoronaengine } from "react-icons/si";
import {
  Bot,
  Combz,
  DashBoard,
  FarmingTales,
  Kiosk,
  MarsEngine,
  MultiKiosks,
  Parsec,
  Recipes,
  Sellbot,
  SellbotWithdraw,
  Subscription,
  SupplyIngredient,
  TaskTrackerFT
} from "../features";
import { DefaultContent } from "../layouts/DefaultContent";

export type RouteProps = {
  title: string;
  icon: FC<ComponentProps<"svg">>;
  path: string;
  component?: any;
  // render?:any,
  group: boolean;
  parent?: string;
  tab?: boolean;
  children?: RouteProps[];
};
export const routes: RouteProps[] = [
  {
    title: "Dash Board",
    icon: AiFillDashboard,
    path: "/",
    component: DashBoard,
    group: false,
    tab: true,
  },
  {
    title: "Mars Engine",
    icon: SiCoronaengine,
    path: "/mars",
    group: false,
    tab: true,
    component: MarsEngine,
  },
  {
    title: "NFT Auto Seller",
    icon: SiCoinmarketcap,
    path: "/sellbot",
    component: DefaultContent,
    group: true,
    tab: true,
    children: [
      {
        title: "Deposit NFTs",
        icon: GiFarmTractor,
        path: "/",
        component: Sellbot,
        group: false,
        tab: true,
      },
      {
        title: "Withdraw NFTs",
        icon: GiBarn,
        path: "/withdraw",
        component: SellbotWithdraw,
        group: false,
        tab: true,
      }
    ],
  },
  {
    title: "Parsec Auto",
    icon: FaSpaceAwesome,
    path: "/parsec",
    group: false,
    tab: true,
    component: Parsec,
  },
  {
    title: "Taco",
    icon: GiTacos,
    path: "/taco",
    component: DefaultContent,
    group: true,
    tab: true,
    children: [
      {
        title: "Kiosks",
        icon: BiStoreAlt,
        path: "/kiosks",
        component: MultiKiosks,
        group: false,
        tab: true,
      },
      {
        title: "Combz Auto",
        icon: LiaRobotSolid,
        path: "/combz",
        component: Combz,
        group: false,
        tab: true,
      }
    ],
  },
  {
    title: "Kiosk",
    icon: IoStorefront,
    path: "taco/kiosks/:kiosk_id",
    tab: false,
    component: Kiosk,
    group: false,
  },
  {
    title: "Supply Ingredients",
    icon: FaWarehouse,
    tab: false,
    path: "taco/supply-ingredients/:kiosk_id",
    component: SupplyIngredient,
    group: false,
  },
  {
    title: "Farming Tales",
    icon: GiFarmer,
    path: "/farmingtales",
    component: DefaultContent,
    group: true,
    tab: true,
    children: [
      {
        title: "Basic",
        icon: GiBarn,
        path: "/",
        component: FarmingTales,
        group: false,
        tab: true,
      },
      {
        title: "Task Tracker",
        icon: GiFarmTractor,
        path: "/task-tracker",
        component: TaskTrackerFT,
        group: false,
        tab: true,
      },
      {
        title: "Recipes",
        icon: Gi3DGlasses,
        path: "/recipes",
        component: Recipes,
        group: false,
        tab: true,
      },
    ],
  },
  {
    title: "Auto Bot",
    icon: GiFarmTractor,
    path: "/bot",
    component: Bot,
    group: false,
    tab: false,
  },
  {
    title: "Prime Membership",
    icon: MdRememberMe,
    tab: true,
    path: "/prime-membership",
    component: Subscription,
    group: false,
  },
];
