import { types } from "mobx-state-tree";

export const WorkItem = types.model("WorkItem", {
    chainfield_id: types.number,
    spacecombs: types.array(types.model({
        asset_id: types.number,
        slot_id: types.number,
        level: types.number,
    })),
    input: types.array(types.model({
        material_id: types.number,
        quantity: types.number,
    })),
    output: types.array(types.model({
        material_id: types.number,
        quantity: types.number,
    })),
    start: types.Date,
    end: types.Date,
    boost: types.number,
    sponsor: types.array(types.model({
        first: types.number,
        second: types.array(types.number)
    })),
    event_ids: types.array(types.number),
})
