

import { flow, types } from "mobx-state-tree";
import { getTableRow } from '../../../services/api';

const MarsEngine = types
    .model("MarsEngine", {
        isLoading: true,
        wax_balance: 0,
        delegate_quantity: 0,
        is_registered: false,
        last_reward: "0.0000 MARTIA",
        total_reward: "0.0000 MARTIA",
        delegated_quantity: "0.0000 WAX",
        martia_balance: 0,
        swap_quantity: 0
    })
    .views((self) => ({

    }))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        const loadUser = flow(function* (wallet: string) {
            try {
                const json = yield getTableRow({
                    table: "accounts",
                    code: "eosio.token",
                    scope: wallet,
                    lower_bound: "WAX",
                })
                if (json.rows.length > 0) {
                    self.wax_balance = parseFloat(json.rows[0].balance.split(" ")[0]);
                } else {
                    self.wax_balance = 0;
                }

                const jsonMartia = yield getTableRow({
                    table: "accounts",
                    code: "martia",
                    scope: wallet,
                    lower_bound: "MARTIA",
                })
                if (jsonMartia.rows.length > 0) {
                    self.martia_balance = parseFloat(jsonMartia.rows[0].balance.split(" ")[0]);
                } else {
                    self.martia_balance = 0;
                }

                const staker = yield getTableRow({
                    table: "stakers",
                    code: "waximusmarpg",
                    scope: "waximusmarpg",
                    lower_bound: wallet,
                });

                if (staker.rows.length > 0) {
                    self.is_registered = true;
                    self.last_reward = staker.rows[0].last_reward;
                    self.total_reward = staker.rows[0].total_reward;
                } else {
                    self.is_registered = false;
                    self.last_reward = "0.0000 MARTIA";
                    self.total_reward = "0.0000 MARTIA";
                }

                const staker_delband = yield getTableRow({
                    table: "delband",
                    code: "eosio",
                    scope: wallet,
                    lower_bound: "waximusmarpg",
                });

                if (staker_delband.rows.length > 0) {
                    self.delegated_quantity = staker_delband.rows[0].cpu_weight;
                } else {
                    self.delegated_quantity = "0.0000 WAX";
                }

                markLoading(false)
            } catch (err) {
                console.log(err)
                markLoading(false)
            }
        });

        const onChangeDelegateQuantity = (value: any) => {
            self.delegate_quantity = value;
        }

        const onChangeSwapQuantity = (value: any) => {
            self.swap_quantity = value;
        }

        return {
            loadUser,
            onChangeDelegateQuantity,
            onChangeSwapQuantity
        }
    })


export const marsStore = MarsEngine.create({
    isLoading: true,
    wax_balance: 0,
    delegate_quantity: 0,
    is_registered: false,
    last_reward: "0.0000 MARTIA",
    total_reward: "0.0000 MARTIA",
    delegated_quantity: "0.0000 WAX",
    martia_balance: 0,
    swap_quantity: 0
})