import { toJS, values } from "mobx";
import { cast, flow, types } from "mobx-state-tree";
import CONSTANTS from "../../../../constants";
import SELL_BOT_CONSTANTS from "../../../../constants/sellbot";
import axiosInstance from "../../../../services/axiosInstance";
import * as ContractKit from '../../../../services/contractKit';
import { Accstat } from '../../domain/accstat';
import { Collection } from '../../domain/collection';
import { NFT } from "../../domain/nft";
import { Setting } from "../../domain/setting";

const wait = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

const Sellbot = types
    .model("Sellbot", {
        isLoading: true,
        nfts: types.array(NFT),
        selectedQuantity: types.number,
        collection_name: types.string,
        schema_name: types.string,
        page: types.number,
        searchLoading: false,
        listOnMarket: false,
        loadMoreLoading: false,
        system_setting: Setting,
        account_stat: Accstat,
        default_listing_price: types.number,
        min_listing_price: types.number,
        collections: types.array(Collection),
        isActiveMember: false
    })
    .views((self) => ({}))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        const setCollectionName = flow(function* (collection_name: string) {
            try {
                self.collection_name = collection_name

                let items = toJS(self.collections);

                let index = items.findIndex((collection: any) => collection.collection_name === collection_name);


                let schemas = yield loadSchemas(items[index].collection_name);

                let schemas_name = schemas.data.data.map((schema: any) => {
                    return {
                        schema_name: schema.schema_name,
                    }
                });
                items[index].schemas = schemas_name;
                self.collections = cast(items);
            }
            catch (err) {
                console.log("err", err);
            }
        });

        function setSchemaName(schema_name: string) {
            self.schema_name = schema_name
        }

        function setIsActiveMember(flag: boolean) {
            self.isActiveMember = flag;
        }

        const loadSystemSetting = flow(function* () {
            try {

                const setting = yield ContractKit.getSingleData(
                    SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                    SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                    SELL_BOT_CONSTANTS.SETTING_TABLE);
                if (setting) {
                    self.system_setting = {
                        percent_cut: parseFloat(setting.percent_cut),
                        items_cap_per_user: parseInt(setting.items_cap_per_user),
                        sale_cap_per_user: parseInt(setting.sale_cap_per_user),
                        price_update_cap: parseInt(setting.price_update_cap),
                        item_life_day: parseInt(setting.item_life_day),
                        maintenance: setting.maintenance
                    }
                }
            }
            catch (err) {
                console.log("err", err);
            }
        });

        const loadAccStat = flow(function* (owner: string) {
            try {
                const accStats = yield ContractKit.getSingleData(
                    SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                    SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                    SELL_BOT_CONSTANTS.ACCOUNT_STATS_TABLE,
                    owner);
                if (accStats) {
                    self.account_stat = {
                        account: String(accStats.account),
                        total_wax_earned: String(accStats.total_wax_earned),
                        total_sold: parseInt(accStats.total_sold),
                        total_returned: parseInt(accStats.total_returned),
                        total_items: parseInt(accStats.total_items),
                        active_sales: parseInt(accStats.active_sales)
                    }
                }
            }
            catch (err) {
                console.log("err", err);
            }
        });

        const loadCollections = flow(function* (owner: string) {
            try {
                let new_collections: any = [];
                //empty collections

                let response = yield axiosInstance.get(`/atomicassets/v1/accounts/${owner}`);
                let collections = response.data.data.collections;

                for (let item of collections) {
                    let collection = {
                        collection_name: item.collection.collection_name,
                        name: item.collection.name,
                        img: item?.collection?.img ? item.collection.img : "",
                        schemas: []
                    }
                    new_collections.push(collection);
                }
                self.collections = cast(new_collections);
                new_collections = toJS(self.collections);
                // for (let item of new_collections) {
                //     let schemas = yield loadSchemas(item.collection_name);

                //     let schemas_name = schemas.data.data.map((schema: any) => {
                //         return {
                //             schema_name: schema.schema_name,
                //         }
                //     });
                //     item.schemas = schemas_name;
                // }
                self.collections = cast(new_collections);

            }
            catch (err) {
                console.log("err", err);
            }
        });

        const loadSchemas = flow(function* (collection_name: string) {
            try {
                let params: any = {
                    collection_name: collection_name,
                }
                let response = yield axiosInstance.get(`/atomicassets/v1/schemas`, { params });
                return response;
            }
            catch (err) {
                console.log("err", err);
            }
        });

        const loadNfts = flow(function* (owner: string, loadMore: boolean) {
            try {
                if (!loadMore) {
                    self.searchLoading = true
                    self.page = 1
                    self.nfts = cast([])

                } else {
                    self.loadMoreLoading = true
                }
                const limit = 100;
                if (owner === "") {
                    return;
                }
                let params: any = {
                    collection_name: self.collection_name,
                    schema_name: self.schema_name,
                    owner,
                    page: loadMore ? self.page + 1 : self.page,
                    limit
                }
                let info = yield axiosInstance.get("/atomicmarket/v1/assets", {
                    params,
                });

                let { data } = info;
                let newNFTs = data.data.map((nft: any) => {
                    return {
                        asset_id: nft.asset_id,
                        name: nft.data.name,
                        image: nft.data.img || "",
                        video: nft.data.video || "",
                        selected: false,
                        template_mint: nft.template_mint,
                        collection_name: nft.collection.collection_name,
                    }
                })
                if (newNFTs.length > 0) {
                    if (loadMore) {
                        self.nfts = cast([...values(self.nfts), ...newNFTs])
                    } else {
                        self.nfts = cast(newNFTs)
                    }
                }
                if (loadMore) {
                    self.page += 1
                }

                if (!loadMore) {
                    self.searchLoading = false
                    self.listOnMarket = false
                    self.selectedQuantity = 0
                }
                else {
                    self.loadMoreLoading = false
                }

                if (newNFTs.length < limit) {
                    self.loadMoreLoading = false
                }
            } catch (err) {
                console.log("err", err);
                loadNfts(owner, loadMore);
            }
        })

        const sellectAll = (flag: boolean) => {
            let selectedQuantity = self.selectedQuantity;
            let nfts = toJS(self.nfts)
            if (flag) {
                let allowQuantity = 50 - selectedQuantity;
                if (allowQuantity > self.nfts.length) {
                    allowQuantity = self.nfts.length
                }
                for (let i = 0; i < allowQuantity; i++) {
                    if (nfts[i].selected) {
                        allowQuantity++;
                        continue;
                    }
                    nfts[i].selected = flag
                    selectedQuantity++;
                }
            } else {
                for (let i = 0; i < nfts.length; i++) {
                    if (nfts[i].selected) {
                        nfts[i].selected = flag
                        selectedQuantity--;
                    }
                }
            }

            self.nfts = cast(nfts)
            self.selectedQuantity = selectedQuantity
        }

        const onSelect = (asset_id: string, selected: boolean) => {
            let nfts = toJS(self.nfts)
            let index = nfts.findIndex((nft: any) => nft.asset_id === asset_id)
            if (index > -1) {
                nfts[index].selected = selected
            }
            self.nfts = cast(nfts)
            if (selected) {
                self.selectedQuantity += 1
            } else {
                self.selectedQuantity -= 1
            }

            if (self.selectedQuantity === 0) {
                self.listOnMarket = false
            }
        }

        const setListOnMarket = (listOnMarket: boolean) => {
            self.listOnMarket = listOnMarket
        }

        const setDefaultListingPrice = (price: number) => {
            // parse float and round to 8 decimal places
            self.default_listing_price = parseFloat(price.toFixed(8));
        }

        const setMinListingPrice = (price: number) => {
            // parse float and round to 8 decimal places
            self.min_listing_price = parseFloat(price.toFixed(8));
        }

        const depositNFT = flow(function* (session: any) {
            try {
                let selectedNFTs = self.nfts.filter((nft: any) => nft.selected);
                let min_listing_price = self.min_listing_price;
                if (!self.isActiveMember) {
                    min_listing_price = 0;
                }
                let res = yield session.transact(
                    {
                        actions: [
                            {
                                account: CONSTANTS.ATOMICASSETS_CONTRACT,
                                name: CONSTANTS.ATOMICASSETS_TRANSFER_ACTION,
                                authorization: [session.permissionLevel],
                                data: {
                                    from: session.actor,
                                    to: SELL_BOT_CONSTANTS.SELLBOT_CONTRACT,
                                    asset_ids: selectedNFTs.map((nft: any) => nft.asset_id),
                                    memo: `deposit;${self.default_listing_price};${min_listing_price}`,

                                },
                            },
                        ],
                    }
                );
                self.selectedQuantity = 0;
                // remove selected nfts from list
                self.nfts = cast(self.nfts.filter((nft: any) => !nft.selected));
                setListOnMarket(false);
                self.default_listing_price = 0;
                return res;

            }
            catch (err) {
                console.log("err", err);

            }
        });

        return {
            markLoading,
            setCollectionName,
            setSchemaName,
            loadNfts,
            sellectAll,
            onSelect,
            setListOnMarket,
            loadSystemSetting,
            loadAccStat,
            setDefaultListingPrice,
            setMinListingPrice,
            depositNFT,
            loadCollections,
            setIsActiveMember
        }
    })

export const sellbotStore = Sellbot.create({
    isLoading: true,
    nfts: [],
    selectedQuantity: 0,
    collection_name: "",
    schema_name: "",
    page: 1,
    searchLoading: false,
    listOnMarket: false,
    system_setting: {
        percent_cut: 0,
        items_cap_per_user: 0,
        sale_cap_per_user: 0,
        price_update_cap: 0,
        item_life_day: 0,
        maintenance: false
    },
    account_stat: {
        account: "",
        total_wax_earned: "",
        total_sold: 0,
        total_returned: 0,
        total_items: 0,
        active_sales: 0,
    },
    default_listing_price: 0,
    min_listing_price: 0,
    collections: [],
    isActiveMember: false
})