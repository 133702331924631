import { Dialog, Listbox, Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Fragment, Suspense, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineSetting, AiOutlineUserDelete } from 'react-icons/ai';
import { BsDiscord } from "react-icons/bs";
import { HiMenuAlt1 } from 'react-icons/hi';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Dropdown, Footer, Label } from '../components';
import { Navbar } from '../components/Navbar';
import { Sidebar } from '../components/Sidebar';
import { Spinner } from '../components/Spinner';
import constants from "../constants/index";
import { routes } from '../routes';
import { mapRoute } from '../utils/helpers';
import localStore from "../utils/localStore";

const MainLayout = ({ ...props }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [openSetting, setOpenSetting] = useState(false);

    const mainRef = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();

    let current_RPC: any = localStore.getItem(constants.localStorageKeys.current_RPC);
    let current_AA: any = localStore.getItem(constants.localStorageKeys.current_AA);
    const [currentRPC, setCurrentRPC] = useState(current_RPC);
    const [currentAA, setCurrentAA] = useState(current_AA);



    const changeRPC = async (selected: string) => {

        if (current_RPC !== selected) {
            setCurrentRPC(selected)
            // change RPC
            localStore.setItem(constants.localStorageKeys.current_RPC, selected);
            current_RPC = selected;
            await props.updateSessionKit(selected);
            // window.location.reload();
        }
    };

    const changeAA = (selected: string) => {
        if (current_AA !== selected) {
            setCurrentAA(selected)
            // change RPC
            localStore.setItem(constants.localStorageKeys.current_AA, selected);
            current_AA = selected;
            window.location.reload();
        }
    };


    return (
        <div className="flex h-screen overflow-hidden w-full flex-col fixed bg-white  dark:bg-slate-900">
            <Navbar fluid={true}>
                <Navbar.Brand href="#">
                    <HiMenuAlt1
                        className="mr-6 h-6 w-6 cursor-pointer text-gray-600 text-gray-400"
                        onClick={() => setCollapsed(!collapsed)}
                    />
                    <img
                        src={require('../resources/images/waximus.png')}
                        className="mr-3 h-6 sm:h-9"
                        alt="Flowbite Logo"
                    />
                    <span className="self-center whitespace-nowrap text-xl font-semibold text-white">
                        WAXIMUS
                    </span>
                </Navbar.Brand>
                <div className="flex md:order-2 lg:mt-0 mt-4">

                    <div className='flex md:order-2'>
                        {props?.session ? (
                            <Dropdown
                                trigger="hover"
                                arrowIcon={false}
                                inline={true}
                                label={<div className='flex row items-center'>
                                    <Label className='mr-2 mt-2 font-bold text-base md:inline hidden'>{String(props.session.actor)}</Label>
                                </div>}
                            >
                                <Dropdown.Header><span className="font-bold">Accounts:</span>
                                    {props.walletSessions.map((session: any, index: number) => {
                                        return (
                                            <Dropdown.Item key={`${index}`} onClick={() => { props.switchAccount(session) }}>
                                                <span>{String(session.actor)}</span>
                                                <AiOutlineUserDelete
                                                    className='ml-2 mt-1 text-red-500 text-xl'
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        props.removeAccount(session);
                                                    }} />
                                            </Dropdown.Item>
                                        )
                                    })}
                                    <Dropdown.Item onClick={props.addAccount}>
                                        <span>Add Account </span>
                                    </Dropdown.Item>
                                </Dropdown.Header>
                                {/* <Dropdown.Divider /> */}
                                <Dropdown.Item>
                                    <Link key={"Subscription"} to='/prime-membership' className="font-bold" >Subscription </Link>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={props.logout} >
                                    <span className="font-bold text-red-500">Sign out</span>
                                </Dropdown.Item>
                            </Dropdown>
                        ) :
                            (<Button className='mr-2 font-bold text-base md:inline hidden'
                                gradientDuoTone="purpleToBlue"
                                outline={true}
                                onClick={props.login}>Login</Button>)}
                        <Navbar.Toggle />

                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <Menu.Button onClick={() => setOpenSetting(true)} className="mt-2 justify-center text-sm font-medium dark:text-white">
                                    <AiOutlineSetting className='mr-2 mt-1' size={20} />

                                </Menu.Button>
                            </div>
                        </Menu>
                    </div>
                </div>
                <Navbar.Collapse className='inline md:hidden'>
                    {props?.session ? (
                        <div >
                            <p className='ml-2 mt-2 mb-2 font-bold text-white '>{String(props.session.actor)}</p>
                            <Button className='mt-2 ml-2 font-bold text-base  dark:bg-amber-600' onClick={props.logout}>Sign out</Button>
                        </div>
                    )
                        : (<div><Button className='mt-2 ml-2 font-bold text-base  dark:bg-amber-600' onClick={props.login}>Login</Button></div>)}
                </Navbar.Collapse>
            </Navbar>
            <div className="flex overflow-hidden">
                <div className='h-full'>
                    <div className='hidden md:inline w-fit h-full z-50'>
                        <Sidebar aria-label="Default sidebar" collapsed={collapsed}>
                            <Sidebar.Items>
                                <Sidebar.ItemGroup>
                                    {routes.filter(({ tab }) => tab).map(({ path, icon, title, group, children = [] }, key) => group ? (
                                        <Sidebar.Collapse
                                            key={key}
                                            icon={icon}
                                            label={title}
                                            to={path}
                                            active={Object.keys(children).some((key: any) => path + children[key].path === pathname)}
                                        >
                                            {
                                                children.map(({ path: pathC, icon, title, group, }, keyC) => (
                                                    <Sidebar.Item href="#"
                                                        key={keyC}
                                                        as={Link}
                                                        icon={icon}
                                                        to={path + pathC}
                                                        active={(path + pathC) === pathname}
                                                    >
                                                        {title}
                                                    </Sidebar.Item>
                                                ))
                                            }

                                        </Sidebar.Collapse>
                                    ) : (
                                        <Sidebar.Item
                                            key={key}
                                            icon={icon}
                                            as={Link}
                                            to={path}
                                            active={path === pathname}
                                            onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                                        >
                                            {title}
                                        </Sidebar.Item>
                                    ))}

                                </Sidebar.ItemGroup>
                            </Sidebar.Items>
                        </Sidebar>
                    </div>
                </div>
                <div className={'fixed h-full inline md:hidden ' + (collapsed ? "hidden" : "z-50")}>
                    <div className={'w-fit absolute top-0 left-0 bottom-0 '}>
                        <Sidebar aria-label="Default sidebar" collapsed={false}>
                            <Sidebar.Items>
                                <Sidebar.ItemGroup>

                                    {routes.filter(({ tab }) => tab).map(({ path, icon, title, group, children = [] }, key) => group ? (
                                        <Sidebar.Collapse
                                            key={key + "-mobile"}
                                            icon={icon}
                                            label={title}
                                        >
                                            {
                                                children.map(({ path: pathC, icon, title, group, }, keyC) => (
                                                    <Sidebar.Item href="#"
                                                        key={keyC + "-mobile"}
                                                        as={Link}
                                                        icon={icon}
                                                        to={path + pathC}
                                                        active={(path + pathC) === pathname}
                                                    >
                                                        {title}
                                                    </Sidebar.Item>
                                                ))
                                            }

                                        </Sidebar.Collapse>
                                    ) : (
                                        <Sidebar.Item
                                            key={key}
                                            icon={icon}
                                            as={Link}
                                            to={path}
                                            active={path === pathname}
                                            onClick={() => mainRef.current?.scrollTo({ top: 0 })}
                                        >
                                            {title}
                                        </Sidebar.Item>
                                    ))}
                                </Sidebar.ItemGroup>
                            </Sidebar.Items>
                        </Sidebar>
                    </div>
                </div>
                <main className="flex-1 overflow-auto p-4" ref={mainRef} >
                    <Suspense
                        fallback={
                            <div className="flex h-full items-center justify-center ">
                                <Spinner />
                            </div>
                        }
                    >
                        <Routes>
                            {mapRoute(routes).map(({ path, component: Component }) => (
                                <Route key={path} path={path} element={<Component {...props} />} />
                            ))}
                        </Routes>
                    </Suspense>
                </main>
            </div>
            <Footer container={true} className={"hidden md:block"}>
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright
                        href="#"
                        by="Waximus"
                        year={2023}
                    />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                        <div className='flex flex-row'>
                            <a className='text-sm text-gray-500 dark:text-gray-400 sm:text-center mr-4'
                                href="https://discord.gg/Uw8xcMaygP"
                                target="_blank" rel="noreferrer">
                                Join our communiy for latest updates and feature requests
                            </a>
                            <Footer.Icon
                                href="https://discord.gg/Uw8xcMaygP"
                                icon={BsDiscord}
                                title='Join our communiy for latest updates and feature requests'
                            />
                        </div>

                    </div>
                </div>
            </Footer>
            <Transition.Root show={openSetting} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpenSetting}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-slate-900 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-300 sm:duration-500"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-300 sm:duration-500"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-slate-900 shadow-xl">
                                            <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                                                <div className="flex items-start justify-between">
                                                    <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-white ">System Setting</Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                            onClick={() => setOpenSetting(false)}
                                                        >
                                                            <span className="sr-only">Close panel</span>
                                                            <AiOutlineClose />
                                                        </button>
                                                    </div>
                                                </div>
                                                <Listbox value={currentRPC} onChange={changeRPC}>
                                                    {({ open }) => (
                                                        <>
                                                            <Listbox.Label className="block text-sm font-medium text-slate-500 mt-4" >Blockchain Node</Listbox.Label>
                                                            <div className="relative mt-1">
                                                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                                    <span className="flex items-center">
                                                                        <span className="ml-3 block truncate">{currentRPC}</span>
                                                                    </span>
                                                                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    </span>
                                                                </Listbox.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={Fragment}
                                                                    leave="transition ease-in duration-100"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                        {props.rpcEndpoints.map((item: any, index: number) => (
                                                                            <Listbox.Option
                                                                                key={item.host + "-" + index}
                                                                                className={({ active }) =>
                                                                                    classNames(
                                                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                    )
                                                                                }
                                                                                value={`${item.protocol}://${item.host}`}
                                                                            >
                                                                                {({ selected, active }) => (
                                                                                    <>
                                                                                        <div className="flex items-center">
                                                                                            <span
                                                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                                                            >
                                                                                                {item.host}
                                                                                            </span>
                                                                                        </div>

                                                                                        {selected ? (
                                                                                            <span
                                                                                                className={classNames(
                                                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                )}
                                                                                            >
                                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Listbox.Option>
                                                                        ))}
                                                                    </Listbox.Options>
                                                                </Transition>
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox>
                                                <Listbox value={currentAA} onChange={changeAA}>
                                                    {({ open }) => (
                                                        <>
                                                            <Listbox.Label className="block text-sm font-medium text-slate-500 mt-4" >Atomic Node</Listbox.Label>
                                                            <div className="relative mt-1">
                                                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                                    <span className="flex items-center">
                                                                        <span className="ml-3 block truncate">{currentAA}</span>
                                                                    </span>
                                                                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    </span>
                                                                </Listbox.Button>

                                                                <Transition
                                                                    show={open}
                                                                    as={Fragment}
                                                                    leave="transition ease-in duration-100"
                                                                    leaveFrom="opacity-100"
                                                                    leaveTo="opacity-0"
                                                                >
                                                                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                        {props.aaEndpoints.map((item: any, index: number) => (
                                                                            <Listbox.Option
                                                                                key={item.host + "-" + index}
                                                                                className={({ active }) =>
                                                                                    classNames(
                                                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                    )
                                                                                }
                                                                                value={`${item.protocol}://${item.host}`}
                                                                            >
                                                                                {({ selected, active }) => (
                                                                                    <>
                                                                                        <div className="flex items-center">
                                                                                            <span
                                                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                                                            >
                                                                                                {item.host}
                                                                                            </span>
                                                                                        </div>

                                                                                        {selected ? (
                                                                                            <span
                                                                                                className={classNames(
                                                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                                )}
                                                                                            >
                                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Listbox.Option>
                                                                        ))}
                                                                    </Listbox.Options>
                                                                </Transition>
                                                            </div>
                                                        </>
                                                    )}
                                                </Listbox>

                                            </div>


                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <ToastContainer style={{ zIndex: 1000 }} />

        </div >
    )
}

export {
    MainLayout
};

