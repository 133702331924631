import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { toast } from 'react-toastify';
import { Card, Checkbox, Spinner } from '../../../../components';
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import wrapProviderHOC from "../../../../layouts/WrapProvider";
import { sellbotWithdrawStore } from "./controller";


interface Props {
  session: any;
  member: any;
}
const wait = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const isUrl = (str: string) => {
  const regex = new RegExp("http");
  return regex.test(str);
};

const SellbotWithdrawScreen: FC<Props> = observer(({ ...props }) => {


  useEffect(() => {
    if (props?.session?.actor) {
      sellbotWithdrawStore.loadItems(String(props?.session?.actor));
      // sellbotWithdrawStore.loadItems("gbal4.wam");
    }

  }, [props?.session?.actor]);

  const nfts = sellbotWithdrawStore.nfts;
  const isLoading = sellbotWithdrawStore.isLoading;
  const isSelectedAll = sellbotWithdrawStore.isSelectedAll;

  const onWithdraw = async () => {
    if (!props?.session?.actor) {
      toast.error("Please login first", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    // have to select at least 1 item
    const selectedNFTs = nfts.filter((nft: any) => nft.selected);
    if (selectedNFTs.length === 0) {
      toast.warn(`Please select at least 1 item`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    try {
      const response: any = await sellbotWithdrawStore.withdraw(props?.session);

      if (response) {
        toast.success(<div>Executed: <a href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}>{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a></div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        await wait(1000);
        sellbotWithdrawStore.loadItems(String(props?.session?.actor));
      }
    } catch (error) {
      toast.warn(`${error}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }

  return (
    <SecondaryRightContent>


      {props.session?.actor ? (
        <>
          {/* Title of the screen: "Your Deposited NFTs" */}
          <p className='text-2xl text-yellow-400 font-bold  text-center m-1 mb-6'>Your Deposited NFTs</p>

          <div className='flex'>
            <div className='flex-none w-10'></div>
            <Card className={`grow `}>
              <div className="flex flex-row border-b-2 mb-1 mt-1 items-center">
                <div className="text-lg text-gray-700 dark:text-teal-200 font-bold ">
                  Your Assets
                </div>
                <div className='grow'></div>
                <div className='flex-none'>
                  {/* withdraw button */}
                  <button
                    className="bg-blue-700 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2"
                    onClick={() => {
                      onWithdraw();
                    }}
                  >
                    Withdraw
                  </button>
                </div>
              </div>
              {isLoading ? <Spinner color={"success"} /> : (
                <table className="table-auto text-left text-gray-700 dark:text-white border border-dashed overflow-x: auto">
                  <thead >
                    <tr>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed pl-2'>
                        <Checkbox
                          checked={isSelectedAll}
                          onChange={(v) => {
                            sellbotWithdrawStore.selectAllItems();
                          }}
                        />
                      </th>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed flex justify-center'>Media</th>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Asset ID</th>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Name</th>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Status</th>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Listing Price</th>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed '>Sale</th>
                      <th className='text-gray-700 dark:text-fuchsia-200 border border-dashed'>Wax profit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nfts.map((item: any, index: any) => {
                      return (
                        <tr key={item.asset_id}>
                          <td className='pl-2'>
                            <Checkbox
                              disabled={item.status !== 0}
                              checked={item.selected}
                              onChange={(v) => {
                                sellbotWithdrawStore.selectItem(item.asset_id)

                              }}
                              name="auto-sponsor"
                            ></Checkbox>
                          </td>
                          <td className='flex justify-center'>
                            <img
                              className="max-h-16"
                              src={isUrl(item.asset_media) ? item.asset_media : `https://atomichub-ipfs.com/ipfs/${item.asset_media}`}
                              alt={index.toString()}
                            />
                          </td>
                          <td >
                            {item.asset_id}
                          </td>
                          <td >
                            {item.asset_name}
                          </td>
                          {item.status === 0 ? (
                            <td className="text-yellow-400 font-bold">
                              Listing
                            </td>
                          ) : item.status === 1 ? (
                            <td className="text-green-400 font-bold">
                              Sold
                            </td>
                          ) : (
                            <td className="text-red-400 font-bold">
                              Returned
                            </td>
                          )}
                          <td>{item.status === 0 && item.sale_id === 0 ? "Queue" : item.listed_price}</td>
                          <td>
                            <a
                              href={`https://wax.atomichub.io/market/sale/${item.sale_id}`}
                              target={"_blank"}
                              rel={"noreferrer"}
                              className='text-blue-400 font-bold'
                            >
                              View
                            </a>
                          </td>
                          <td className='w-40'>{item.wax_returned}</td>
                        </tr>
                      )
                    })}
                    {nfts.length === 0 && (
                      <tr>
                        <td colSpan={15} className="text-center text-gray-700 dark:text-white">
                          No Items
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>)}
            </Card>
            <div className='flex-none w-10'></div>
          </div>
        </>
      ) : (
        <p className="text-center text-white text-4xl">Please login first</p>
      )}
    </SecondaryRightContent>
  );
});

const SellbotWithdraw = wrapProviderHOC(SellbotWithdrawScreen);

export { SellbotWithdraw };
