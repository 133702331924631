

import _ from 'lodash';
import { values } from 'mobx';
import { cast, flow, types } from "mobx-state-tree";
import parsecConstants from "../../../constants/parsec";
import { cursorAll, getMultiDataCursor, getSingleData } from '../../../services/contractKit';
import localStore from "../../../utils/localStore";
import { City } from '../domain/city';
import { Commander } from '../domain/commander';
import { CommanderBuff } from '../domain/commanderBuff';
import { Confarm } from '../domain/confarm';
import { Conftechcost } from '../domain/conftechcost';
import { Item } from '../domain/item';
import { claimResource, finishUpgrade, startUpgrade } from './auto.controllers';
import { getBuildDefCost, getBuildShipCost, getCooldownSeconds, getNuclUpgradeCosts, getResUpgradeCosts, getSolarUpgradeCosts, getTechUpgradeCost } from './data.controllers';


const Parsec = types
    .model("Parsec", {
        isLoading: true,
        isAuto: false,
        items: types.array(Item),
        energyItems: types.array(Item),
        resourceItems: types.array(Item),
        techItems: types.array(Item),
        defenceItems: types.array(Item),
        shipyardItems: types.array(Item),
        total_energy: types.number,
        android_level: types.number,
        commanders: Commander,
        commanders_buff: CommanderBuff,
        faction: types.string,
        city: City,
        conftechcosts: types.array(Conftechcost),
        confarm: types.array(Confarm),
        log: types.string,
        allEnergyUpgrade: types.boolean,
        allResourceUpgrade: types.boolean,
        allTechUpgrade: types.boolean,
        allDefUpgrade: types.boolean,
        allShipyardUpgrade: types.boolean,
        allResClaim: types.boolean,
        activeAccount: types.string,
    })
    .views((self: any) => ({

    }))
    .actions((self: any) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        function setActiveAccount(account: string) {
            self.activeAccount = account;
        }

        function setFaction(faction: string) {
            self.faction = faction;
        }

        function setCity(item: any) {
            self.city.position = parseInt(item);
            // self.city.continent = parseInt(item.continent);
            // self.city.region = parseInt(item.region);
            // self.city.city = parseInt(item.city);
        }

        function clearLocalStorage(account: any = undefined) {
            // Keep this
            if (account !== undefined) {
                let energy_items_local: any = (localStore.getItem(parsecConstants.localStorageKeys.WALLET_ENERGY_ITEMS));
                let resource_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_RESOURCE_ITEMS);
                let tech_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_TECH_ITEMS);
                let defence_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_DEFENCE_ITEMS);
                let shipyard_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_SHIPYARD_ITEMS);

                if (energy_items_local) {
                    energy_items_local = JSON.parse(energy_items_local);
                    delete energy_items_local[account];
                    localStore.setItem(parsecConstants.localStorageKeys.WALLET_ENERGY_ITEMS, JSON.stringify(energy_items_local));
                }

                if (resource_items_local) {
                    resource_items_local = JSON.parse(resource_items_local);
                    delete resource_items_local[account];
                    localStore.setItem(parsecConstants.localStorageKeys.WALLET_RESOURCE_ITEMS, JSON.stringify(resource_items_local));
                }

                if (tech_items_local) {
                    tech_items_local = JSON.parse(tech_items_local);
                    delete tech_items_local[account];
                    localStore.setItem(parsecConstants.localStorageKeys.WALLET_TECH_ITEMS, JSON.stringify(tech_items_local));
                }

                if (defence_items_local) {
                    defence_items_local = JSON.parse(defence_items_local);
                    delete defence_items_local[account];
                    localStore.setItem(parsecConstants.localStorageKeys.WALLET_DEFENCE_ITEMS, JSON.stringify(defence_items_local));
                }

                if (shipyard_items_local) {
                    shipyard_items_local = JSON.parse(shipyard_items_local);
                    delete shipyard_items_local[account];
                    localStore.setItem(parsecConstants.localStorageKeys.WALLET_SHIPYARD_ITEMS, JSON.stringify(shipyard_items_local));
                }

            } else {
                localStore.removeItem(parsecConstants.localStorageKeys.WALLET_ENERGY_ITEMS);
                localStore.removeItem(parsecConstants.localStorageKeys.WALLET_RESOURCE_ITEMS);
                localStore.removeItem(parsecConstants.localStorageKeys.WALLET_TECH_ITEMS);
                localStore.removeItem(parsecConstants.localStorageKeys.WALLET_DEFENCE_ITEMS);
                localStore.removeItem(parsecConstants.localStorageKeys.WALLET_SHIPYARD_ITEMS);
            }
        }

        function retrieveLocalStorage() {
            let energy_items_local: any = (localStore.getItem(parsecConstants.localStorageKeys.WALLET_ENERGY_ITEMS));
            let resource_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_RESOURCE_ITEMS);
            let tech_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_TECH_ITEMS);
            let defence_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_DEFENCE_ITEMS);
            let shipyard_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_SHIPYARD_ITEMS);

            if (energy_items_local) {
                energy_items_local = JSON.parse(energy_items_local);
                if (!energy_items_local.cacheVersion || energy_items_local.cacheVersion !== parsecConstants.localStorageKeys.VERSION) {
                    localStore.removeItem(parsecConstants.localStorageKeys.WALLET_ENERGY_ITEMS);
                    energy_items_local = null;
                } else {
                    if (energy_items_local[self.activeAccount]) {
                        self.energyItems = cast(energy_items_local[self.activeAccount]);
                    }
                }
            }
            if (resource_items_local) {
                resource_items_local = JSON.parse(resource_items_local);
                if (!resource_items_local.cacheVersion || resource_items_local.cacheVersion !== parsecConstants.localStorageKeys.VERSION) {
                    localStore.removeItem(parsecConstants.localStorageKeys.WALLET_RESOURCE_ITEMS);
                    resource_items_local = null;
                } else {
                    if (resource_items_local[self.activeAccount]) {
                        self.resourceItems = cast(resource_items_local[self.activeAccount]);
                    }
                }

            }
            if (tech_items_local) {
                tech_items_local = JSON.parse(tech_items_local);

                if (!tech_items_local.cacheVersion || tech_items_local.cacheVersion !== parsecConstants.localStorageKeys.VERSION) {
                    localStore.removeItem(parsecConstants.localStorageKeys.WALLET_TECH_ITEMS);
                    tech_items_local = null;
                } else {
                    if (tech_items_local[self.activeAccount]) {
                        self.techItems = cast(tech_items_local[self.activeAccount]);
                    }
                }
            }

            if (defence_items_local) {
                defence_items_local = JSON.parse(defence_items_local);

                if (!defence_items_local.cacheVersion || defence_items_local.cacheVersion !== parsecConstants.localStorageKeys.VERSION) {
                    localStore.removeItem(parsecConstants.localStorageKeys.WALLET_DEFENCE_ITEMS);
                    defence_items_local = null;
                } else {
                    if (defence_items_local[self.activeAccount]) {
                        self.defenceItems = cast(defence_items_local[self.activeAccount]);
                    }
                }
            }

            if (shipyard_items_local) {
                shipyard_items_local = JSON.parse(shipyard_items_local);

                if (!shipyard_items_local.cacheVersion || shipyard_items_local.cacheVersion !== parsecConstants.localStorageKeys.VERSION) {
                    localStore.removeItem(parsecConstants.localStorageKeys.WALLET_SHIPYARD_ITEMS);
                    shipyard_items_local = null;
                } else {
                    if (shipyard_items_local[self.activeAccount]) {
                        self.shipyardItems = cast(shipyard_items_local[self.activeAccount]);
                    }
                }
            }
        }

        function saveLocalStorage() {
            // save local storage

            let energy_items_local: any = (localStore.getItem(parsecConstants.localStorageKeys.WALLET_ENERGY_ITEMS));
            let resource_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_RESOURCE_ITEMS);
            let tech_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_TECH_ITEMS);
            let defence_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_DEFENCE_ITEMS);
            let shipyard_items_local: any = localStore.getItem(parsecConstants.localStorageKeys.WALLET_SHIPYARD_ITEMS);

            energy_items_local = energy_items_local ? JSON.parse(energy_items_local) : {};
            resource_items_local = resource_items_local ? JSON.parse(resource_items_local) : {};
            tech_items_local = tech_items_local ? JSON.parse(tech_items_local) : {};
            defence_items_local = defence_items_local ? JSON.parse(defence_items_local) : {};
            shipyard_items_local = shipyard_items_local ? JSON.parse(shipyard_items_local) : {};

            energy_items_local[self.activeAccount] = values(self.energyItems);
            resource_items_local[self.activeAccount] = values(self.resourceItems);
            tech_items_local[self.activeAccount] = values(self.techItems);
            defence_items_local[self.activeAccount] = values(self.defenceItems);
            shipyard_items_local[self.activeAccount] = values(self.shipyardItems);
            energy_items_local.cacheVersion = parsecConstants.localStorageKeys.VERSION;
            resource_items_local.cacheVersion = parsecConstants.localStorageKeys.VERSION;
            tech_items_local.cacheVersion = parsecConstants.localStorageKeys.VERSION;
            defence_items_local.cacheVersion = parsecConstants.localStorageKeys.VERSION;
            shipyard_items_local.cacheVersion = parsecConstants.localStorageKeys.VERSION;

            localStore.setItem(parsecConstants.localStorageKeys.WALLET_ENERGY_ITEMS, JSON.stringify(energy_items_local));
            localStore.setItem(parsecConstants.localStorageKeys.WALLET_RESOURCE_ITEMS, JSON.stringify(resource_items_local));
            localStore.setItem(parsecConstants.localStorageKeys.WALLET_TECH_ITEMS, JSON.stringify(tech_items_local));
            localStore.setItem(parsecConstants.localStorageKeys.WALLET_DEFENCE_ITEMS, JSON.stringify(defence_items_local));
            localStore.setItem(parsecConstants.localStorageKeys.WALLET_SHIPYARD_ITEMS, JSON.stringify(shipyard_items_local));

        }

        function setConftechcosts(data: any) {

            for (let row of data) {
                let item_index = _.findIndex(self.conftechcosts, (item: any) => item.element === String(row.element));

                if (item_index === -1) {
                    self.conftechcosts.push({
                        element: String(row.element),
                        tit_cost: 0,
                        rut_cost: 0,
                    });
                    item_index = _.findIndex(self.conftechcosts, (item: any) => item.element === String(row.element));
                }

                let current_item = self.conftechcosts[item_index];
                current_item.tit_cost = parseInt(row.tit_cost);
                current_item.rut_cost = parseInt(row.rut_cost);
                self.conftechcosts[item_index] = current_item;
            }
        }

        function setConfarm(data: any) {

            let confarm: any = [];
            for (let item of data) {
                confarm.push({
                    element: String(item.element),
                    template_id: parseInt(item.template_id),
                    mint_cost: String(item.mint_cost),
                    tit_cost: parseInt(item.tit_cost),
                    rut_cost: parseInt(item.rut_cost),
                    he_usage: parseInt(item.he_usage),
                    food_usage: parseInt(item.food_usage),
                    speed: item.speed.map((speed: any) => { return parseInt(speed) }),
                    attack: parseInt(item.attack),
                    defence: parseInt(item.defence),
                    cargo: parseInt(item.cargo),
                });
            }
            self.confarm = confarm;

        }

        function setAndroidLevel(level: number) {
            self.android_level = level;
        }

        function setTotalEnergy(energy: number) {
            self.total_energy = energy;
        }

        function setCommanders(data: any) {
            self.commanders.general_t = parseInt(data.general_t);
            self.commanders.admiral_t = parseInt(data.admiral_t);
            self.commanders.researcher_t = parseInt(data.researcher_t);
            self.commanders.professor_t = parseInt(data.professor_t);
            self.commanders.engineer_t = parseInt(data.engineer_t);
        }

        function setCommandersBuff(data: any) {
            if (data.length > 0) {
                self.commanders_buff.general_buff = data.filter((item: any) => parseInt(item.template_id) === self.commanders.general_t).length > 0 ? parseFloat(data.filter((item: any) => parseInt(item.template_id) === self.commanders.general_t)[0].buff) : 0;
                self.commanders_buff.admiral_buff = data.filter((item: any) => parseInt(item.template_id) === self.commanders.admiral_t).length > 0 ? parseFloat(data.filter((item: any) => parseInt(item.template_id) === self.commanders.admiral_t)[0].buff) : 0;
                self.commanders_buff.researcher_buff = data.filter((item: any) => parseInt(item.template_id) === self.commanders.researcher_t).length > 0 ? parseFloat(data.filter((item: any) => parseInt(item.template_id) === self.commanders.researcher_t)[0].buff) : 0;
                self.commanders_buff.professor_buff = data.filter((item: any) => parseInt(item.template_id) === self.commanders.professor_t).length > 0 ? parseFloat(data.filter((item: any) => parseInt(item.template_id) === self.commanders.professor_t)[0].buff) : 0;
                self.commanders_buff.engineer_buff = data.filter((item: any) => parseInt(item.template_id) === self.commanders.engineer_t).length > 0 ? parseFloat(data.filter((item: any) => parseInt(item.template_id) === self.commanders.engineer_t)[0].buff) : 0;
            } else {
                self.commanders_buff.general_buff = 0;
                self.commanders_buff.admiral_buff = 0;
                self.commanders_buff.researcher_buff = 0;
                self.commanders_buff.professor_buff = 0;
                self.commanders_buff.engineer_buff = 0;
            }
        }

        function setClaimFrequency(resource_name: any, frequency: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;
            let resource_index = _.findIndex(listItems, (item: any) => item.name === resource_name);
            listItems[resource_index].claim_frequency = frequency;
            clearPauseUpgradeUntil(resource_index, list_type);
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.resourceItems = listItems;
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function setCapUpgradeLevel(resource_name: any, cap_upgrade_level: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;
            let resource_index = _.findIndex(listItems, (item: any) => item.name === resource_name);
            listItems[resource_index].cap_upgrade_level = cap_upgrade_level;
            clearPauseUpgradeUntil(resource_index, list_type);
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.resourceItems = listItems;
                    break;
                case parsecConstants.ENERGY_TABLE:
                    self.energyItems = listItems;
                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    self.techItems = listItems;
                    break;
                case parsecConstants.DEFENCE_TABLE:
                    listItems[resource_index].level = 0;
                    self.defenceItems = listItems;
                    break;
                case parsecConstants.SHIPYARD_TABLE:
                    listItems[resource_index].level = 0;
                    self.shipyardItems = listItems;
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function setPauseMinutes(resource_name: any, pause_minutes: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;
            let resource_index = _.findIndex(listItems, (item: any) => item.name === resource_name);
            listItems[resource_index].pause_minutes = pause_minutes;
            clearPauseUpgradeUntil(resource_index, list_type);
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.resourceItems = listItems;
                    break;
                case parsecConstants.ENERGY_TABLE:
                    self.energyItems = listItems;
                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    self.techItems = listItems;
                    break;
                case parsecConstants.DEFENCE_TABLE:
                    self.defenceItems = listItems;
                    break;
                case parsecConstants.SHIPYARD_TABLE:
                    self.shipyardItems = listItems;
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function clearPauseUpgradeUntil(itemIndex: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;
            listItems[itemIndex].pause_upgrade_until = new Date();
            listItems[itemIndex].fails = 0;
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.resourceItems = listItems;
                    break;
                case parsecConstants.ENERGY_TABLE:
                    self.energyItems = listItems;
                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    self.techItems = listItems;
                    break;
                case parsecConstants.DEFENCE_TABLE:
                    self.defenceItems = listItems;
                    break;
                case parsecConstants.SHIPYARD_TABLE:
                    self.shipyardItems = listItems;
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function setAutoClaimAll(is_auto_claim: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;

            listItems.forEach((item: any, index: any) => {
                setAutoClaimRes(item.name, is_auto_claim, list_type)
            });
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.allResClaim = is_auto_claim;
                    break;
                default:
                    break;
            }
        }

        function setAutoClaimRes(resource_name: any, is_auto_claim: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;
            let resource_index = _.findIndex(listItems, (item: any) => item.name === resource_name);
            listItems[resource_index].is_auto_claim = is_auto_claim;
            clearPauseUpgradeUntil(resource_index, list_type);
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    if (!is_auto_claim) {
                        self.allResClaim = false;
                    }
                    self.resourceItems = listItems;
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function setAutoUpgradeAll(is_auto_upgrade: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;

            listItems.forEach((item: any, index: any) => {
                setAutoUpgrade(item.name, is_auto_upgrade, list_type)
            });
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.allResourceUpgrade = is_auto_upgrade;
                    break;
                case parsecConstants.ENERGY_TABLE:
                    self.allEnergyUpgrade = is_auto_upgrade;
                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    self.allTechUpgrade = is_auto_upgrade;
                    break;
                case parsecConstants.DEFENCE_TABLE:
                    self.allDefUpgrade = is_auto_upgrade;
                    break;
                case parsecConstants.SHIPYARD_TABLE:
                    self.allShipyardUpgrade = is_auto_upgrade;
                    break;
                default:
                    break;
            }
        }

        function setAutoUpgrade(resource_name: any, is_auto_upgrade: any, list_type: string) {
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;
            let resource_index = _.findIndex(listItems, (item: any) => item.name === resource_name);
            listItems[resource_index].is_auto_upgrade = is_auto_upgrade;
            clearPauseUpgradeUntil(resource_index, list_type);

            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.resourceItems = listItems;
                    if (!is_auto_upgrade) {
                        self.allResourceUpgrade = false;
                    }
                    break;
                case parsecConstants.ENERGY_TABLE:
                    self.energyItems = listItems;
                    if (!is_auto_upgrade) {
                        self.allEnergyUpgrade = false;
                    }
                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    self.techItems = listItems;
                    if (!is_auto_upgrade) {
                        self.allTechUpgrade = false;
                    }
                    break;
                case parsecConstants.DEFENCE_TABLE:
                    self.defenceItems = listItems;
                    if (!is_auto_upgrade) {
                        self.allDefUpgrade = false;
                    }
                    break;
                case parsecConstants.SHIPYARD_TABLE:
                    self.shipyardItems = listItems;
                    if (!is_auto_upgrade) {
                        self.allShipyardUpgrade = false;
                    }
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function setIsAuto(is_auto: any) {
            self.isAuto = is_auto;
        }

        function moveResUp(item_name: any, list_type: string) {
            // move item up index
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;

            let item_index = _.findIndex(listItems, (item: any) => item.name === item_name);
            let item = Object.assign({}, listItems[item_index]);
            if (item_index === 0) return;
            listItems.splice(item_index, 1);
            listItems.splice(item_index - 1, 0, item);
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.resourceItems = listItems;
                    break;
                case parsecConstants.ENERGY_TABLE:
                    self.energyItems = listItems;
                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    self.techItems = listItems;
                    break;
                case parsecConstants.DEFENCE_TABLE:
                    self.defenceItems = listItems;
                    break;
                case parsecConstants.SHIPYARD_TABLE:
                    self.shipyardItems = listItems;
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function moveResDown(item_name: any, list_type: string) {
            // move item down index
            let listItems = (list_type === parsecConstants.RESOURCES_TABLE) ? self.resourceItems : (list_type === parsecConstants.ENERGY_TABLE) ? self.energyItems : (list_type === parsecConstants.DEFENCE_TABLE) ? self.defenceItems : (list_type === parsecConstants.SHIPYARD_TABLE) ? self.shipyardItems : self.techItems;
            let item_index = _.findIndex(listItems, (item: any) => item.name === item_name);
            let item = Object.assign({}, listItems[item_index]);
            if (item_index === listItems.length - 1) return;
            listItems.splice(item_index, 1);
            listItems.splice(item_index + 1, 0, item);
            switch (list_type) {
                case parsecConstants.RESOURCES_TABLE:
                    self.resourceItems = listItems;
                    break;
                case parsecConstants.ENERGY_TABLE:
                    self.energyItems = listItems;
                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    self.techItems = listItems;
                    break;
                case parsecConstants.DEFENCE_TABLE:
                    self.defenceItems = listItems;
                    break;
                case parsecConstants.SHIPYARD_TABLE:
                    self.shipyardItems = listItems;
                    break;
                default:
                    break;
            }
            saveLocalStorage();
        }

        function setItems(data: any, type: string) {

            switch (type) {
                case parsecConstants.RESOURCES_TABLE:
                    delete data.account;
                    delete data.position;

                    for (let key in data) {
                        let item_index = _.findIndex(self.resourceItems, (item: any) => item.name === key);

                        if (item_index === -1) {
                            self.resourceItems.push({
                                name: key,
                                full_name: (parsecConstants.FULL_NAME as any)[key],
                                type,
                                level: 0,
                                upgrade_cost: "",
                                start_at: new Date(),
                                finish_at: new Date(),
                                is_auto_upgrade: false,
                                claim_frequency: 10,
                                last_claim_at: new Date(),
                                last_tx: "",
                                last_action: "",
                                is_auto_claim: false,
                                fails: 0,
                                cap_upgrade_level: 0,
                                pause_upgrade_until: new Date(),
                                pause_minutes: 60,
                            });
                            item_index = _.findIndex(self.resourceItems, (item: any) => item.name === key);
                        }

                        let current_item = self.resourceItems[item_index];
                        current_item.level = parseInt(data[key].level);
                        let item_upgrade_costs = getResUpgradeCosts(data[key].level, key, self.commanders_buff.engineer_buff, self.commanders_buff.researcher_buff);
                        current_item.upgrade_cost = item_upgrade_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");
                        let item_upgrade_cooldown = Math.floor(getCooldownSeconds(item_upgrade_costs[0].upgradeCost, item_upgrade_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "building"));
                        current_item.start_at = parseInt(data[key].start_up) > 0 ? new Date(parseInt(data[key].start_up) * 1000) : new Date(0);
                        current_item.finish_at = parseInt(data[key].start_up) > 0 ? new Date(current_item.start_at.getTime() + item_upgrade_cooldown * 1000) : new Date(0);
                        current_item.last_claim_at = new Date(data[key].last_claim + "Z");
                        self.resourceItems[item_index] = current_item;
                    }
                    break;
                case parsecConstants.ENERGY_TABLE:

                    delete data.total_energy;
                    delete data.position;
                    delete data.account;

                    for (let key in data) {
                        let item_index = _.findIndex(self.energyItems, (item: any) => item.name === key);

                        if (item_index === -1) {
                            self.energyItems.push({
                                name: key,
                                full_name: (parsecConstants.FULL_NAME as any)[key],
                                type,
                                level: 0,
                                upgrade_cost: "",
                                start_at: new Date(),
                                finish_at: new Date(),
                                is_auto_upgrade: false,
                                claim_frequency: 0,
                                last_claim_at: new Date(),
                                last_tx: "",
                                last_action: "",
                                is_auto_claim: false,
                                fails: 0,
                                cap_upgrade_level: 0,
                                pause_upgrade_until: new Date(),
                                pause_minutes: 60,
                            });
                            item_index = _.findIndex(self.energyItems, (item: any) => item.name === key);
                        }

                        let current_item = self.energyItems[item_index];
                        current_item.level = parseInt(data[key].level);
                        let item_upgrade_costs = (key === "nuclear") ? getNuclUpgradeCosts(data[key].level) : getSolarUpgradeCosts();
                        current_item.upgrade_cost = item_upgrade_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");
                        let item_upgrade_cooldown = (key === "nuclear") ? Math.floor(getCooldownSeconds(item_upgrade_costs[0].upgradeCost, item_upgrade_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "building")) : parsecConstants.SOLAR_COOLDOWN_SECONDS;
                        current_item.start_at = parseInt(data[key].start_up) > 0 ? new Date(parseInt(data[key].start_up) * 1000) : new Date(0);
                        current_item.finish_at = parseInt(data[key].start_up) > 0 ? new Date(current_item.start_at.getTime() + item_upgrade_cooldown * 1000) : new Date(0);
                        self.energyItems[item_index] = current_item;
                    }
                    break;

                case parsecConstants.RESEARCH_TABLE:
                    delete data.account;
                    delete data.position;
                    for (let key in data) {
                        let item_index = _.findIndex(self.techItems, (item: any) => item.name === key);
                        if (item_index === -1) {
                            self.techItems.push({
                                name: key,
                                full_name: (parsecConstants.FULL_NAME as any)[key],
                                type,
                                level: 0,
                                upgrade_cost: "",
                                start_at: new Date(),
                                finish_at: new Date(),
                                is_auto_upgrade: false,
                                claim_frequency: 0,
                                last_claim_at: new Date(),
                                last_tx: "",
                                last_action: "",
                                is_auto_claim: false,
                                fails: 0,
                                cap_upgrade_level: 0,
                                pause_upgrade_until: new Date(),
                                pause_minutes: 60,
                            });
                            item_index = _.findIndex(self.techItems, (item: any) => item.name === key);
                        }

                        let current_item = self.techItems[item_index];

                        current_item.level = (key === "fleets" || key === "storages") ? parseInt(data[key].level) : parseInt(data[key].level);

                        let item_base_cost = _.find(self.conftechcosts, (item: any) => (item.element === key));

                        let item_upgrade_tit_cost = getTechUpgradeCost(current_item.level, item_base_cost.tit_cost, self.faction, self.commanders_buff.professor_buff);
                        let item_upgrade_rut_cost = getTechUpgradeCost(current_item.level, item_base_cost.rut_cost, self.faction, self.commanders_buff.professor_buff);
                        let item_upgrade_costs = [{
                            upgradeCost: item_upgrade_tit_cost,
                            upgradeCostType: "Titan"
                        }, {
                            upgradeCost: item_upgrade_rut_cost,
                            upgradeCostType: "Ruthen"
                        }]
                        current_item.upgrade_cost = item_upgrade_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");

                        let item_upgrade_cooldown = Math.floor(getCooldownSeconds(item_upgrade_costs[0].upgradeCost, item_upgrade_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "technology"));
                        current_item.start_at = parseInt(data[key].start_up) > 0 ? new Date(parseInt(data[key].start_up) * 1000) : new Date(0);
                        current_item.finish_at = parseInt(data[key].start_up) > 0 ? new Date(current_item.start_at.getTime() + item_upgrade_cooldown * 1000) : new Date(0);
                        self.techItems[item_index] = current_item;
                    }

                    break;
                case parsecConstants.TECHNOLOGY_TABLE:
                    delete data.account;
                    delete data.position;
                    for (let key in data) {

                        let item_index = _.findIndex(self.techItems, (item: any) => item.name === key);

                        if (item_index === -1) {
                            self.techItems.push({
                                name: key,
                                full_name: (parsecConstants.FULL_NAME as any)[key],
                                type,
                                level: 0,
                                upgrade_cost: "",
                                start_at: new Date(),
                                finish_at: new Date(),
                                is_auto_upgrade: false,
                                claim_frequency: 0,
                                last_claim_at: new Date(),
                                last_tx: "",
                                last_action: "",
                                is_auto_claim: false,
                                fails: 0,
                                cap_upgrade_level: 0,
                                pause_upgrade_until: new Date(),
                                pause_minutes: 60,
                            });
                            item_index = _.findIndex(self.techItems, (item: any) => item.name === key);
                        }

                        let current_item = self.techItems[item_index];

                        current_item.level = (key === "fleets" || key === "storages") ? parseInt(data[key].level) : parseInt(data[key].level);

                        let item_base_cost = _.find(self.conftechcosts, (item: any) => (item.element === key));

                        let item_upgrade_tit_cost = getTechUpgradeCost(current_item.level, item_base_cost.tit_cost, self.faction, self.commanders_buff.professor_buff);
                        let item_upgrade_rut_cost = getTechUpgradeCost(current_item.level, item_base_cost.rut_cost, self.faction, self.commanders_buff.professor_buff);
                        let item_upgrade_costs = [{
                            upgradeCost: item_upgrade_tit_cost,
                            upgradeCostType: "Titan"
                        }, {
                            upgradeCost: item_upgrade_rut_cost,
                            upgradeCostType: "Ruthen"
                        }]
                        current_item.upgrade_cost = item_upgrade_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");

                        let item_upgrade_cooldown = Math.floor(getCooldownSeconds(item_upgrade_costs[0].upgradeCost, item_upgrade_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "technology"));
                        current_item.start_at = parseInt(data[key].start_up) > 0 ? new Date(parseInt(data[key].start_up) * 1000) : new Date(0);
                        current_item.finish_at = parseInt(data[key].start_up) > 0 ? new Date(current_item.start_at.getTime() + item_upgrade_cooldown * 1000) : new Date(0);
                        self.techItems[item_index] = current_item;
                    }

                    break;

                case parsecConstants.WAREHOUSE_TABLE:
                    delete data.account;
                    delete data.position;
                    delete data.storages;
                    // for (let key in data) {
                    let warehouse_index = _.findIndex(self.techItems, (item: any) => item.name === "warehouse");

                    if (warehouse_index === -1) {
                        self.techItems.push({
                            name: "warehouse",
                            full_name: (parsecConstants.FULL_NAME as any)["warehouse"],
                            type,
                            level: 0,
                            upgrade_cost: "",
                            start_at: new Date(),
                            finish_at: new Date(),
                            is_auto_upgrade: false,
                            claim_frequency: 0,
                            last_claim_at: new Date(),
                            last_tx: "",
                            last_action: "",
                            is_auto_claim: false,
                            fails: 0,
                            cap_upgrade_level: 0,
                            pause_upgrade_until: new Date(),
                            pause_minutes: 60,
                        });
                        warehouse_index = _.findIndex(self.techItems, (item: any) => item.name === "warehouse");
                    }

                    let warehouse_item = self.techItems[warehouse_index];

                    warehouse_item.level = parseInt(data.level);

                    let warehouse_base_cost = _.find(self.conftechcosts, (item: any) => (item.element === "warehouse"));


                    let warehouse_upgrade_tit_cost = getTechUpgradeCost(warehouse_item.level - 1, warehouse_base_cost.tit_cost, self.faction, self.commanders_buff.professor_buff);
                    let warehouse_upgrade_rut_cost = getTechUpgradeCost(warehouse_item.level - 1, warehouse_base_cost.rut_cost, self.faction, self.commanders_buff.professor_buff);
                    let warehouse_upgrade_costs = [{
                        upgradeCost: warehouse_upgrade_tit_cost,
                        upgradeCostType: "Titan"
                    }, {
                        upgradeCost: warehouse_upgrade_rut_cost,
                        upgradeCostType: "Ruthen"
                    }]
                    warehouse_item.upgrade_cost = warehouse_upgrade_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");


                    let warehouse_upgrade_cooldown = Math.floor(getCooldownSeconds(warehouse_upgrade_costs[0].upgradeCost, warehouse_upgrade_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "technology"));
                    warehouse_item.start_at = parseInt(data.start_up) > 0 ? new Date(parseInt(data.start_up) * 1000) : new Date(0);
                    warehouse_item.finish_at = parseInt(data.start_up) > 0 ? new Date(warehouse_item.start_at.getTime() + warehouse_upgrade_cooldown * 1000) : new Date(0);
                    self.techItems[warehouse_index] = warehouse_item;
                    // }

                    break;

                case parsecConstants.SPACEPORT_TABLE:
                    delete data.account;
                    delete data.position;
                    delete data.ships;
                    // delete data.times;
                    // for (let key in data) {

                    let item_index = _.findIndex(self.techItems, (item: any) => item.name === "spaceport");

                    if (item_index === -1) {
                        self.techItems.push({
                            name: "spaceport",
                            full_name: (parsecConstants.FULL_NAME as any)["spaceport"],
                            type,
                            level: 0,
                            upgrade_cost: "",
                            start_at: new Date(),
                            finish_at: new Date(),
                            is_auto_upgrade: false,
                            claim_frequency: 0,
                            last_claim_at: new Date(),
                            last_tx: "",
                            last_action: "",
                            is_auto_claim: false,
                            fails: 0,
                            cap_upgrade_level: 0,
                            pause_upgrade_until: new Date(),
                            pause_minutes: 60,
                        });
                        item_index = _.findIndex(self.techItems, (item: any) => item.name === "spaceport");
                    }

                    let current_item = self.techItems[item_index];

                    current_item.level = parseInt(data.level);

                    let item_base_cost = _.find(self.conftechcosts, (item: any) => (item.element === "warehouse"));

                    let item_upgrade_tit_cost = getTechUpgradeCost(current_item.level, item_base_cost.tit_cost, self.faction, self.commanders_buff.professor_buff);
                    let item_upgrade_rut_cost = getTechUpgradeCost(current_item.level, item_base_cost.rut_cost, self.faction, self.commanders_buff.professor_buff);
                    let item_upgrade_costs = [{
                        upgradeCost: item_upgrade_tit_cost,
                        upgradeCostType: "Titan"
                    }, {
                        upgradeCost: item_upgrade_rut_cost,
                        upgradeCostType: "Ruthen"
                    }]
                    current_item.upgrade_cost = item_upgrade_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");

                    let item_upgrade_cooldown = Math.floor(getCooldownSeconds(item_upgrade_costs[0].upgradeCost, item_upgrade_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "technology"));
                    current_item.start_at = parseInt(data.start_up) > 0 ? new Date(parseInt(data.start_up) * 1000) : new Date(0);
                    current_item.finish_at = parseInt(data.start_up) > 0 ? new Date(current_item.start_at.getTime() + item_upgrade_cooldown * 1000) : new Date(0);
                    self.techItems[item_index] = current_item;
                    // }

                    // get all ships from confarm by getting all template_id != 1
                    let ship_items = self.confarm.filter((item: any) => item.template_id !== 1);

                    for (const ship of ship_items) {
                        if (ship.element === "support.vess") continue;
                        let item_index = _.findIndex(self.shipyardItems, (item: any) => item.name === ship.element);
                        if (item_index === -1) {
                            self.shipyardItems.push({
                                name: ship.element,
                                full_name: (parsecConstants.FULL_NAME as any)[ship.element],
                                type,
                                level: 0,
                                upgrade_cost: "",
                                start_at: new Date(),
                                finish_at: new Date(),
                                is_auto_upgrade: false,
                                claim_frequency: 0,
                                last_claim_at: new Date(),
                                last_tx: "",
                                last_action: "",
                                is_auto_claim: false,
                                fails: 0,
                                cap_upgrade_level: 0,
                                pause_upgrade_until: new Date(),
                                pause_minutes: 60,
                            });
                            item_index = _.findIndex(self.shipyardItems, (item: any) => item.name === ship.element);
                        }

                        let current_item = self.shipyardItems[item_index];

                        let item_build_tit_cost = getBuildShipCost(ship.tit_cost, self.faction, self.commanders_buff.admiral_buff);
                        let item_build_rut_cost = getBuildShipCost(ship.rut_cost, self.faction, self.commanders_buff.admiral_buff);
                        let item_build_costs = [{
                            upgradeCost: item_build_tit_cost,
                            upgradeCostType: "Titan"
                        }, {
                            upgradeCost: item_build_rut_cost,
                            upgradeCostType: "Ruthen"
                        }]
                        current_item.upgrade_cost = item_build_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");

                        let item_build_cooldown = Math.floor(getCooldownSeconds(item_build_costs[0].upgradeCost, item_build_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "ship"));
                        let time_index: any = (parsecConstants.SHIPYARD_TIME_INDEX as any)[ship.element];
                        current_item.start_at = parseInt(data.times[time_index]) > 0 ? new Date(parseInt(data.times[time_index]) * 1000) : new Date(0);
                        current_item.finish_at = parseInt(data.times[time_index]) > 0 ? new Date(current_item.start_at.getTime() + item_build_cooldown * 1000) : new Date(0);
                        self.shipyardItems[item_index] = current_item;
                    }

                    break;

                case parsecConstants.DEFENCE_TABLE:
                    // get all defence items from confarm by getting all template_id == 1
                    let defence_items = self.confarm.filter((item: any) => item.template_id === 1);

                    for (const defence of defence_items) {

                        let item_index = _.findIndex(self.defenceItems, (item: any) => item.name === defence.element);
                        if (item_index === -1) {
                            self.defenceItems.push({
                                name: defence.element,
                                full_name: (parsecConstants.FULL_NAME as any)[defence.element],
                                type,
                                level: 0,
                                upgrade_cost: "",
                                start_at: new Date(),
                                finish_at: new Date(),
                                is_auto_upgrade: false,
                                claim_frequency: 0,
                                last_claim_at: new Date(),
                                last_tx: "",
                                last_action: "",
                                is_auto_claim: false,
                                fails: 0,
                                cap_upgrade_level: 0,
                                pause_upgrade_until: new Date(),
                                pause_minutes: 60,
                            });
                            item_index = _.findIndex(self.defenceItems, (item: any) => item.name === defence.element);
                        }

                        let current_item = self.defenceItems[item_index];

                        let item_build_tit_cost = getBuildDefCost(defence.tit_cost, self.faction, self.commanders_buff.general_buff);
                        let item_build_rut_cost = getBuildDefCost(defence.rut_cost, self.faction, self.commanders_buff.general_buff);
                        let item_build_costs = [{
                            upgradeCost: item_build_tit_cost,
                            upgradeCostType: "Titan"
                        }, {
                            upgradeCost: item_build_rut_cost,
                            upgradeCostType: "Ruthen"
                        }]
                        current_item.upgrade_cost = item_build_costs.reduce((acc: any, item: any) => { return acc + ` ${item.upgradeCost} ${item.upgradeCostType}` }, "");

                        let item_build_cooldown = Math.floor(getCooldownSeconds(item_build_costs[0].upgradeCost, item_build_costs[1].upgradeCost, 0, self.android_level, parsecConstants.UNIVERSE_SPEED, "defence"));
                        let time_index: any = (parsecConstants.DEFENCE_TIME_INDEX as any)[defence.element];
                        current_item.start_at = parseInt(data.times[time_index]) > 0 ? new Date(parseInt(data.times[time_index]) * 1000) : new Date(0);
                        current_item.finish_at = parseInt(data.times[time_index]) > 0 ? new Date(current_item.start_at.getTime() + item_build_cooldown * 1000) : new Date(0);
                        self.defenceItems[item_index] = current_item;
                    }

                    break;

                default:
                    break;

            }

        }

        const loadData = flow(function* (account) {
            try {
                setActiveAccount(account);
                // clearLocalStorage();
                retrieveLocalStorage();

                // load user info
                const user_info = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.USERS_TABLE,
                    account
                );


                if (!user_info) {
                    setFaction("");
                    setCity(0);
                    setCommanders({
                        general_t: 0,
                        engineer_t: 0,
                        researcher_t: 0,
                        admiral_t: 0,
                        professor_t: 0,
                    });
                    setCommandersBuff([]);
                    setAndroidLevel(0);
                    setTotalEnergy(0);
                    self.resourceItems = [];
                    self.energyItems = [];
                    self.techItems = [];

                    markLoading(false);
                    return;
                }

                // set faction and cities
                setFaction(user_info.faction);
                setCity(user_info.cities[0]);

                // get tech costs
                const tech_cost_cursor = yield getMultiDataCursor(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.CONFIG_TECH_COST_TABLE,
                );
                const tech_cost = yield cursorAll(tech_cost_cursor);

                // set tech costs
                setConftechcosts(tech_cost);

                // get conf arm
                const conf_arm_cursor = yield getMultiDataCursor(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.CONFIG_ARM_TABLE,
                );
                const conf_arm = yield cursorAll(conf_arm_cursor);

                // set conf arm
                setConfarm(conf_arm);

                // load Energy
                const energy = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.ENERGY_TABLE,
                    self.city.position
                );


                // load Resources
                const resources = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.RESOURCES_TABLE,
                    self.city.position
                );


                // load Research
                const research = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.RESEARCH_TABLE,
                    self.city.position
                );


                // load Technology

                const technology = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.TECHNOLOGY_TABLE,
                    self.city.position
                );


                // load Spaceport

                const spaceport = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.SPACEPORT_TABLE,
                    self.city.position
                );


                // load Warehouse

                const warehouse = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.WAREHOUSE_TABLE,
                    self.city.position
                );

                // load defence

                const defence = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.DEFENCE_TABLE,
                    self.city.position
                );


                // get commanders
                const commanders = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.COMMANDERS_TABLE,
                    account
                );

                if (commanders) {
                    // get commander config
                    const commander_config_cursor = yield getMultiDataCursor(
                        parsecConstants.GAME_CONTRACT,
                        parsecConstants.GAME_CONTRACT,
                        parsecConstants.COMMANDER_CONFIG_TABLE,
                    );
                    const commander_config = yield cursorAll(commander_config_cursor);

                    // set commanders
                    setCommanders(commanders);

                    // set commanders buff
                    setCommandersBuff(commander_config);

                }



                // set android level
                setAndroidLevel(parseInt(research.androids.level));

                // set total energy
                setTotalEnergy(parseFloat(energy.total_energy));

                // set energy
                setItems(energy, parsecConstants.ENERGY_TABLE);

                // set resources
                setItems(resources, parsecConstants.RESOURCES_TABLE);

                // set research and technology
                setItems(research, parsecConstants.RESEARCH_TABLE);
                setItems(technology, parsecConstants.TECHNOLOGY_TABLE);

                // set spaceport
                setItems(spaceport, parsecConstants.SPACEPORT_TABLE);

                // set warehouse
                setItems(warehouse, parsecConstants.WAREHOUSE_TABLE);

                // set defence
                setItems(defence, parsecConstants.DEFENCE_TABLE);

                // reset all fails to 0
                for (let item of self.resourceItems) {
                    item.fails = 0;
                    // item.pause_upgrade_until = new Date();
                }
                for (let item of self.energyItems) {
                    item.fails = 0;
                    // item.pause_upgrade_until = new Date();
                }
                for (let item of self.techItems) {
                    item.fails = 0;
                    // item.pause_upgrade_until = new Date();
                }

                // save local storage
                saveLocalStorage();

                markLoading(false);
            } catch (err) {
                console.error("Failed to load Data", err)
            }
        });

        const autoBotUpgrade = flow(function* (session: any, isActiveMember: boolean) {
            try {
                // Energy
                for (let item of self.energyItems) {
                    if (item.is_auto_upgrade && item.pause_upgrade_until.getTime() <= new Date().getTime()) {
                        if (item.start_at.getTime() !== new Date(0).getTime() && item.finish_at.getTime() < new Date().getTime()) {
                            // ready to finish upgrade

                            let res = yield finishUpgrade(session, self.city.position, item.name, parsecConstants.FINISH_UPGRADE_ENERGY_ACTION);
                            if (!res) {
                                // disable auto upgrade
                                item.fails++;
                                if (item.fails > 2) {
                                    item.is_auto_upgrade = false;
                                    item.fails = 0;
                                }
                            } else {
                                // save last tx
                                item.fails = 0;
                                item.last_tx = res.response.transaction_id;
                                item.last_action = "Finish upgrade";
                                self.log = `Finish upgrade ${item.full_name} at ${new Date().toLocaleString()}`;
                            }

                        } else if (item.finish_at.getTime() === item.start_at.getTime() && (item.level < item.cap_upgrade_level || item.cap_upgrade_level === 0)) {

                            // ready to start upgrade
                            let res = yield startUpgrade(session, self.city.position, item.name, parsecConstants.START_UPGRADE_ENERGY_ACTION);
                            if (!res) {
                                // disable auto upgrade
                                item.fails++;
                                if (item.fails > 2) {
                                    // item.is_auto_upgrade = false;
                                    item.fails = 0;
                                    item.pause_upgrade_until = new Date(new Date().getTime() + item.pause_minutes * 60 * 1000);
                                }
                            } else {
                                // save last tx
                                item.fails = 0;
                                item.pause_upgrade_until = new Date()
                                item.last_tx = res.response.transaction_id;
                                item.last_action = "Start upgrade";
                                self.log = `Start upgrade ${item.full_name} at ${new Date().toLocaleString()}`;
                            }


                        }
                    }
                }
                yield loadEnergy();
                saveLocalStorage();

                // Resources

                for (let item of self.resourceItems) {
                    if (item.is_auto_upgrade && item.pause_upgrade_until.getTime() <= new Date().getTime()) {
                        if (item.start_at.getTime() !== new Date(0).getTime() && item.finish_at.getTime() < new Date().getTime()) {
                            // ready to finish upgrade

                            let res = yield finishUpgrade(session, self.city.position, item.name, parsecConstants.FINISH_UPGRADE_RESOURCE_ACTION);
                            if (!res) {
                                // disable auto upgrade
                                item.fails++;
                                if (item.fails > 2) {
                                    item.is_auto_upgrade = false;
                                    item.fails = 0;
                                }
                            } else {
                                // save last tx
                                item.fails = 0;
                                item.last_tx = res.response.transaction_id;
                                item.last_action = "Finish upgrade";
                                self.log = `Finish upgrade ${item.full_name} at ${new Date().toLocaleString()}`;
                            }

                        } else if (item.finish_at.getTime() === item.start_at.getTime() && (item.level < item.cap_upgrade_level || item.cap_upgrade_level === 0)) {

                            // ready to start upgrade
                            let res = yield startUpgrade(session, self.city.position, item.name, parsecConstants.START_UPGRADE_RESOURCE_ACTION);
                            if (!res) {
                                // disable auto upgrade
                                item.fails++;
                                if (item.fails > 2) {
                                    // item.is_auto_upgrade = false;
                                    item.fails = 0;
                                    item.pause_upgrade_until = new Date(new Date().getTime() + item.pause_minutes * 60 * 1000);
                                }
                            } else {
                                // save last tx
                                item.fails = 0;
                                item.pause_upgrade_until = new Date();
                                item.last_tx = res.response.transaction_id;
                                item.last_action = "Start upgrade";
                                self.log = `Start upgrade ${item.full_name} at ${new Date().toLocaleString()}`;
                            }
                        }
                    }
                }
                yield loadResources();
                saveLocalStorage();


                // Research & Technology & Spaceport & Warehouse
                for (let item of self.techItems) {
                    if (item.is_auto_upgrade && item.pause_upgrade_until.getTime() <= new Date().getTime()) {
                        if (item.start_at.getTime() !== new Date(0).getTime() && item.finish_at.getTime() < new Date().getTime()) {
                            // ready to finish upgrade

                            let action_name = item.type === parsecConstants.RESEARCH_TABLE ||
                                item.type === parsecConstants.WAREHOUSE_TABLE ||
                                item.type === parsecConstants.SPACEPORT_TABLE ?
                                parsecConstants.FINISH_UPGRADE_BUILD_ACTION :
                                parsecConstants.FINISH_UPGRADE_TECH_ACTION;
                            let res = yield finishUpgrade(session, self.city.position, item.name, action_name);
                            if (!res) {
                                // disable auto upgrade
                                item.fails++;
                                if (item.fails > 2) {
                                    item.is_auto_upgrade = false;
                                    item.fails = 0;
                                }
                            } else {
                                // save last tx
                                item.fails = 0;
                                item.last_tx = res.response.transaction_id;
                                item.last_action = "Finish upgrade";
                                self.log = `Finish upgrade ${item.full_name} at ${new Date().toLocaleString()}`;
                            }


                        } else if (item.finish_at.getTime() === item.start_at.getTime() && (item.level < item.cap_upgrade_level || item.cap_upgrade_level === 0)) {

                            let action_name = item.type === parsecConstants.RESEARCH_TABLE ||
                                item.type === parsecConstants.WAREHOUSE_TABLE ||
                                item.type === parsecConstants.SPACEPORT_TABLE ?
                                parsecConstants.START_UPGRADE_BUILD_ACTION :
                                parsecConstants.START_UPGRADE_TECH_ACTION;
                            // ready to start upgrade
                            let res = yield startUpgrade(session, self.city.position, item.name, action_name);
                            if (!res) {
                                // disable auto upgrade
                                item.fails++;
                                if (item.fails > 2) {
                                    // item.is_auto_upgrade = false;
                                    item.fails = 0;
                                    item.pause_upgrade_until = new Date(new Date().getTime() + item.pause_minutes * 60 * 1000);
                                }
                            } else {
                                // save last tx
                                item.fails = 0;
                                item.pause_upgrade_until = new Date();
                                item.last_tx = res.response.transaction_id;
                                item.last_action = "Start upgrade";
                                self.log = `Start upgrade ${item.full_name} at ${new Date().toLocaleString()}`;
                            }
                        }
                    }
                }

                if (isActiveMember) {
                    // Defence
                    for (let item of self.defenceItems) {
                        if (item.is_auto_upgrade && item.pause_upgrade_until.getTime() <= new Date().getTime()) {
                            if (item.start_at.getTime() !== new Date(0).getTime() && item.finish_at.getTime() < new Date().getTime()) {
                                // ready to finish upgrade
                                let action_name = parsecConstants.FINISH_BUILD_DEFENCE_ACTION;
                                let res = yield finishUpgrade(session, self.city.position, item.name, action_name);
                                if (!res) {
                                    // disable auto upgrade
                                    item.fails++;
                                    if (item.fails > 2) {
                                        item.is_auto_upgrade = false;
                                        item.fails = 0;
                                    }
                                } else {
                                    // save last tx
                                    item.fails = 0;
                                    item.last_tx = res.response.transaction_id;
                                    item.last_action = "Finish build";
                                    self.log = `Finish build ${item.full_name} at ${new Date().toLocaleString()}`;
                                }


                            } else if (item.finish_at.getTime() === item.start_at.getTime() && (item.level < item.cap_upgrade_level || item.cap_upgrade_level === 0)) {

                                let action_name = parsecConstants.START_BUILD_DEFENCE_ACTION;
                                // ready to start upgrade
                                let res = yield startUpgrade(session, self.city.position, item.name, action_name);
                                if (!res) {
                                    // disable auto upgrade
                                    item.fails++;
                                    if (item.fails > 2) {
                                        // item.is_auto_upgrade = false;
                                        item.fails = 0;
                                        item.pause_upgrade_until = new Date(new Date().getTime() + item.pause_minutes * 60 * 1000);
                                    }
                                } else {
                                    // save last tx
                                    item.fails = 0;
                                    item.pause_upgrade_until = new Date();
                                    item.last_tx = res.response.transaction_id;
                                    item.last_action = "Start build";
                                    self.log = `Start build ${item.full_name} at ${new Date().toLocaleString()}`;
                                }
                            }
                        }
                    }
                    // Shipyard
                    for (let item of self.shipyardItems) {
                        if (item.is_auto_upgrade && item.pause_upgrade_until.getTime() <= new Date().getTime()) {
                            if (item.start_at.getTime() !== new Date(0).getTime() && item.finish_at.getTime() < new Date().getTime()) {
                                // ready to finish upgrade
                                let action_name = parsecConstants.FINISH_BUILD_SHIP_ACTION;
                                let res = yield finishUpgrade(session, self.city.position, item.name, action_name);
                                if (!res) {
                                    // disable auto upgrade
                                    item.fails++;
                                    if (item.fails > 2) {
                                        item.is_auto_upgrade = false;
                                        item.fails = 0;
                                    }
                                } else {
                                    // save last tx
                                    item.level++;
                                    item.fails = 0;
                                    item.last_tx = res.response.transaction_id;
                                    item.last_action = "Finish build";
                                    self.log = `Finish build ${item.full_name} at ${new Date().toLocaleString()}`;
                                }


                            } else if (item.finish_at.getTime() === item.start_at.getTime() && (item.level < item.cap_upgrade_level || item.cap_upgrade_level === 0)) {

                                let action_name = parsecConstants.START_BUILD_SHIP_ACTION;
                                // ready to start upgrade
                                let res = yield startUpgrade(session, self.city.position, item.name, action_name);
                                if (!res) {
                                    // disable auto upgrade
                                    item.fails++;
                                    if (item.fails > 2) {
                                        // item.is_auto_upgrade = false;
                                        item.fails = 0;
                                        item.pause_upgrade_until = new Date(new Date().getTime() + item.pause_minutes * 60 * 1000);
                                    }
                                } else {
                                    // save last tx
                                    item.fails = 0;
                                    item.pause_upgrade_until = new Date();
                                    item.last_tx = res.response.transaction_id;
                                    item.last_action = "Start build";
                                    self.log = `Start build ${item.full_name} at ${new Date().toLocaleString()}`;
                                }
                            }
                        }
                    }
                }

                yield loadResearch();
                yield loadTechnology();
                yield loadSpaceport();
                yield loadDefence();
                yield loadWarehouse();
                saveLocalStorage();



            } catch (err) {
                console.error("Failed to run autoBotUpgrade", err)
            }
        });

        const autoBotClaim = flow(function* (session: any) {
            try {

                // Resources
                for (let item of self.resourceItems) {
                    if (item.is_auto_claim) {

                        if (new Date(item.last_claim_at.getTime() + item.claim_frequency * 60 * 1000) < new Date(Date.now())) {
                            // ready to claim
                            let res = yield claimResource(session, self.city.position, item.name);
                            if (!res) {
                                // disable auto upgrade
                                item.fails++;
                                if (item.fails > 2) {
                                    item.is_auto_claim = false;
                                    item.fails = 0;
                                }
                            } else {
                                // save last tx
                                item.fails = 0;
                                item.last_tx = res.response.transaction_id;
                                item.last_action = "Claim resource";
                                self.log = `Claim resource ${item.full_name} at ${new Date().toLocaleString()}`;
                            }
                        }
                    }
                }
                yield loadResources();
                saveLocalStorage();


            } catch (err) {
                console.error("Failed to run autoBotUpgrade", err)
            }
        });

        const loadEnergy = flow(function* () {
            try {
                const energy = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.ENERGY_TABLE,
                    self.city.position
                )

                // set total energy
                setTotalEnergy(parseFloat(energy.total_energy));

                // set energy
                setItems(energy, parsecConstants.ENERGY_TABLE);
            } catch (err) {
                console.error("Failed to load Energy", err)
            }
        });

        const loadResources = flow(function* () {
            try {
                const res = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.RESOURCES_TABLE,
                    self.city.position
                )


                // set resources
                setItems(res, parsecConstants.RESOURCES_TABLE);

            } catch (err) {
                console.error("Failed to load Resources", err)
            }
        });

        const loadResearch = flow(function* () {
            try {
                const res = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.RESEARCH_TABLE,
                    self.city.position
                )

                setItems(res, parsecConstants.RESEARCH_TABLE);


            } catch (err) {
                console.error("Failed to load Research", err)
            }
        });

        const loadTechnology = flow(function* () {
            try {
                const res = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.TECHNOLOGY_TABLE,
                    self.city.position
                )

                setItems(res, parsecConstants.TECHNOLOGY_TABLE);

            } catch (err) {
                console.error("Failed to load Technology", err)
            }
        });

        const loadSpaceport = flow(function* () {
            try {
                const res = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.SPACEPORT_TABLE,
                    self.city.position
                )

                setItems(res, parsecConstants.SPACEPORT_TABLE);

            } catch (err) {
                console.error("Failed to load SpacePort", err)
            }
        });

        const loadDefence = flow(function* () {
            try {
                const defence = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.DEFENCE_TABLE,
                    self.city.position
                );

                setItems(defence, parsecConstants.DEFENCE_TABLE);

            } catch (err) {
                console.error("Failed to load SpacePort", err)
            }
        });

        const loadWarehouse = flow(function* () {
            try {
                const res = yield getSingleData(
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.GAME_CONTRACT,
                    parsecConstants.WAREHOUSE_TABLE,
                    self.city.position
                )


                setItems(res, parsecConstants.WAREHOUSE_TABLE);


            } catch (err) {
                console.error("Failed to load Warehouse", err)
            }
        });

        const resetShipyardLevel = () => {
            for (let item of self.shipyardItems) {
                item.level = 0;
            }
        }


        return {
            markLoading,
            loadData,
            loadEnergy,
            loadResources,
            loadResearch,
            loadTechnology,
            loadSpaceport,
            loadWarehouse,
            setClaimFrequency,
            setCapUpgradeLevel,
            setPauseMinutes,
            setAutoClaimRes,
            setAutoClaimAll,
            setAutoUpgrade,
            setAutoUpgradeAll,
            setIsAuto,
            moveResUp,
            moveResDown,
            clearLocalStorage,
            autoBotUpgrade,
            autoBotClaim,
            resetShipyardLevel
        }
    })


export const parsecStore = Parsec.create({
    isLoading: false,
    isAuto: false,
    items: [],
    total_energy: 0,
    android_level: 0,
    commanders: {
        general_t: 0,
        engineer_t: 0,
        researcher_t: 0,
        admiral_t: 0,
        professor_t: 0,
    },
    commanders_buff: {
        general_buff: 0,
        engineer_buff: 0,
        researcher_buff: 0,
        admiral_buff: 0,
        professor_buff: 0,
    },
    faction: "",
    city: {
        position: 0,
    },
    conftechcosts: [],
    confarm: [],
    energyItems: [],
    resourceItems: [],
    techItems: [],
    defenceItems: [],
    shipyardItems: [],
    log: "",
    allEnergyUpgrade: false,
    allResourceUpgrade: false,
    allTechUpgrade: false,
    allDefUpgrade: false,
    allShipyardUpgrade: false,
    allResClaim: false,
    activeAccount: "",
})