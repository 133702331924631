// define the controller which uses mobx for the multiKiosks page with selectedKiosk, kiosks
import { values } from "mobx";
import { flow, types } from "mobx-state-tree";
import tacoConstants from "../../../../constants/taco/index";
import { getTableRows } from '../../../../services/api';
import { Kiosk } from "../../domain/kiosk";
import { kioskStore } from '../kiosk/kiosk.controller';

const MultiKiosks = types
    .model("MultiKiosks", {
        isLoading: true,
        kiosks: types.array(Kiosk),
        selectedKiosk: types.maybeNull(Kiosk),
    })
    .views((self) => ({
        get listAllKiosks() {
            return values(self.kiosks);
        },
        get listSelectedKiosk() {
            return values(self.selectedKiosk);
        },
    }))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        // update all kiosks
        function updateKiosks(data: any) {
            self.kiosks = data;
        }

        // update selected kiosk
        function updateSelectedKiosk(data: any) {
            self.selectedKiosk = { ...data };
        }

        // load all kiosks
        const loadKiosks = flow(function* () {
            markLoading(true);
            try {
                const json = yield getTableRows({
                    code: tacoConstants.AUTO_KIOSK_CONTRACT,
                    scope: tacoConstants.AUTO_KIOSK_CONTRACT,
                    table: tacoConstants.KIOSKS_TABLE,
                });
                updateKiosks(json.rows);

                markLoading(false);
            } catch (error) {
                console.log("Failed to load Kiosks", error);
            }
        })

        function setSelectedKioskById(kiosk_id: string) {
            const kiosk = self.kiosks.find((kiosk) => kiosk.kiosk_id === kiosk_id);
            if (kiosk?.kiosk_id !== self.selectedKiosk?.kiosk_id) {
                kioskStore.clearCart();
                updateSelectedKiosk(kiosk);
            }
        }

        return {
            loadKiosks,
            updateKiosks,
            updateSelectedKiosk,
            setSelectedKioskById
        };
    })

export const MultiKiosksStore = MultiKiosks.create({
    isLoading: false,
    selectedKiosk: null,
    kiosks: [],
})