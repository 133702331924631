import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, useEffect, useRef } from "react";
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  TextInput
} from "../../../../components";
import { SecondaryRightContent } from "../../../../layouts/SecondaryRightContent";
import wrapProviderHOC from "../../../../layouts/WrapProvider";
import { sellbotStore } from "./controller";
import NFT from "./nft";
import SelectedNFT from "./selectedNFT";

interface Props {
  session: any;
  member: any;
}
const wait = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const SellbotScreen: FC<Props> = observer(({ ...props }) => {
  let store: any = getSnapshot(sellbotStore);
  let isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      sellbotStore.loadSystemSetting();
      isMounted.current = true;
    }
    if (props?.session?.actor) {
      sellbotStore.loadCollections(String(props.session.actor));
      sellbotStore.loadAccStat(String(props.session.actor));
      sellbotStore.loadNfts(String(props.session.actor), false);
      if (props?.member?.isActive) {
        sellbotStore.setIsActiveMember(true);
      } else {
        sellbotStore.setIsActiveMember(false);
      }

    }
  }, [props?.session?.actor, props?.member?.isActive]);

  let account_stat: any = store.account_stat;
  let system_setting: any = store.system_setting;
  let searchLoading: boolean = store.searchLoading;
  let nfts: any = store.nfts;
  let selectedQuantity: number = store.selectedQuantity;
  let listOnMarket: boolean = store.listOnMarket;
  let loadMoreLoading: boolean = store.loadMoreLoading;
  let default_listing_price: number = store.default_listing_price;
  let min_listing_price: number = store.min_listing_price;
  let collections: any = store.collections;
  let collection_name: string = store.collection_name;
  let schema_name: string = store.schema_name;
  let isActiveMember: boolean = store.isActiveMember;

  const handleDepositNFT = async () => {
    try {
      // reject if default_listing_price = 0

      if (default_listing_price === 0) {
        toast.warn(`Please enter default listing price`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }

      const response = await sellbotStore.depositNFT(props.session);
      if (response) {
        toast.success(<div>Executed: <a href={`https://wax.bloks.io/transaction/${response.response.transaction_id}`}>{`https://wax.bloks.io/transaction/${response.response.transaction_id}`}</a></div>, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

    } catch (error) {
      toast.warn(`${error}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <SecondaryRightContent>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col col-span-1 bg-gray-800 rounded-lg p-8 items-center">
          <h1 className="text-center text-yellow-400 text-4xl font-bold mb-4">
            NFT auto seller
          </h1>
          <ul className="text-justify text-white text-xl w-2/3 list-disc">
            <li>Price will be updated automatically</li>
            <li>Instant profit when sold</li>
            <li>Auto return to owner if unsold after 7 days</li>
            <li>System Fee = {system_setting.percent_cut * 100}% of AtomicMarket Sale Payout</li>
            <li>Auto remove expired items or sold items out of dapp when you deposit more items</li>
            <li className='italic'>Subscribe to our Prime Membership to be able to set min price, have extra 200 deposit slots, reduce 50% of system fee, extra 10 price updates, 7 additional days of storing NFT in Dapp.</li>
          </ul>
        </div>
        <div className="flex flex-col col-span-1 bg-gray-800 rounded-lg p-8 items-center">
          <h1 className="text-center text-yellow-400 text-3xl font-bold mb-4 ">
            Your stats
          </h1>
          <ul className="text-justify text-white text-xl  list-disc">
            <li>Total Earned: {account_stat.total_wax_earned}</li>
            <li>
              In-Dapp items: {account_stat.active_sales}/
              {props?.member.isActive
                ? system_setting.items_cap_per_user + 200
                : system_setting.items_cap_per_user}
            </li>
            {/* <li>Active sales: {account_stat.active_sales}</li> */}
            <li>Total sold: {account_stat.total_sold}</li>
            <li>Total returned: {account_stat.total_returned}</li>
          </ul>
        </div>
      </div>

      {props.session?.actor ? (
        <>
          <div className="flex justify-center mt-8 mb-8">
            <TextInput
              size={30}
              placeholder="Collection"
              value={collection_name}
              className="mr-4"
              onChange={async (e) => {
                sellbotStore.setCollectionName(e.target.value)
                sellbotStore.setSchemaName('')
              }}
              list="collectionOptions"
            />
            <datalist id="collectionOptions">
              {collections.map((collection: any) => (
                <option key={collection.collection_name}
                  value={collection.collection_name}
                  label={collection.name} >
                </option>
              ))}

            </datalist>
            <TextInput
              size={30}
              disabled={collection_name === ""}
              placeholder={collection_name === "" ? "Select a Collection First" : schema_name === "" ? "Schema" : "Schema"}
              value={schema_name}
              className="mr-4"
              onChange={(e) => sellbotStore.setSchemaName(e.target.value)}
              list="schemaOptions"
            />
            <datalist id="schemaOptions">
              {collections.filter((collection: any) => collection.collection_name === collection_name)[0]?.schemas.map((schema: any) => (
                <option key={schema.schema_name}
                  value={schema.schema_name}
                  label={schema.schema_name} ></option>
              ))}
            </datalist>
            <Button
              className=""
              disabled={searchLoading || !props.session}
              onClick={() => sellbotStore.loadNfts(props.session.actor, false)}
            >
              {searchLoading ? "Loading" : "Search"}
            </Button>
          </div>

          {listOnMarket ? (
            <div className="border border-gray-300 rounded p-4 relative">
              <div className="absolute top-4 left-4">
                <Button
                  onClick={() => {
                    sellbotStore.setListOnMarket(false);
                  }}
                  className="text-white bg-gray-800 rounded"
                >
                  BACK
                </Button>
              </div>
              <p className="text-center text-yellow-700 text-3xl">CONFIRM LISTING</p>


              <div className="grid grid-cols-6 gap-4 mt-8 ">
                <div className="flex flex-col col-span-4 rounded border p-8">
                  <p className="text-white text-xl">
                    TOTAL: {selectedQuantity}
                  </p>
                  {nfts
                    .filter((nft: any) => nft.selected)
                    .map((nft: any) => (
                      <SelectedNFT key={nft.asset_id} nft={nft} />
                    ))}
                  <div className='grow'>
                    <Button
                      onClick={() => {
                        handleDepositNFT();
                      }}
                      color={"purple"}
                      className="text-white bg-gray-800 rounded mt-4 mx-auto"
                    >
                      Submit
                    </Button>
                  </div>

                </div>
                <div className="col-span-1 rounded border flex flex-col items-center ">
                  <p className="text-white text-xl self-start ml-6 mt-6 ">
                    Default Listing Price (WAX)<br />
                  </p>
                  <TextInput
                    type="number"
                    id="defaultPrice"
                    className="w-3/5 rounded-lg bg-gray-700 text-white mt-2"
                    placeholder="Enter default price"
                    onChange={(e) => {
                      // Handle the input value change here
                      sellbotStore.setDefaultListingPrice(parseFloat(e.target.value));
                      // Do something with the price value
                    }}
                  />
                  <p className='text-white self-start ml-6 mt-2 mr-2 mb-2 italic text-sm'>If the system can't find the lowest listing price for your item, it automatically uses this default price to list your items.</p>


                </div>
                <div className="col-span-1 rounded border flex flex-col items-center ">
                  <p className="text-white text-xl self-start ml-6 mt-6 ">
                    Min Listing Price (WAX)<br />
                  </p>
                  <TextInput
                    type="number"
                    id="minPrice"
                    className="w-3/5 rounded-lg bg-gray-700 text-white mt-2"
                    placeholder="Enter min price"
                    value={min_listing_price}
                    onChange={(e) => {
                      if (isActiveMember) {
                        sellbotStore.setMinListingPrice(parseFloat(e.target.value));
                      } else {
                        sellbotStore.setMinListingPrice(0);
                        toast.warn("Please subscribe to use this feature!", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          className: "z-50",
                          theme: "dark",
                        });
                      }
                    }}
                  />
                  <p className='text-white self-start ml-6 mt-2 mr-2 mb-2 italic text-sm'>If the lowest listing price discovered is lower than the minimum price you have set, the system automatically uses this minimum price to list your items instead. This feature will be ignored if mininum price is 0 WAX.</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="border border-gray-300 rounded p-4">
              <div className="flex justify-center mb-8">
                <div className="flex items-center mr-4">
                  <Checkbox
                    className="mr-2"
                    onChange={(v) => {
                      sellbotStore.sellectAll(v.target.checked);
                    }}
                  />
                  <p className="text-white text-xl">Select all</p>
                </div>
                <Button
                  className="mr-4"
                  disabled={selectedQuantity === 0}
                  onClick={() => {
                    sellbotStore.setListOnMarket(true);
                  }}
                >
                  List on market
                </Button>
                <p className="text-white text-xl border rounded-full pt-2 pb-2 pr-4 pl-4">
                  {selectedQuantity}/50
                </p>
              </div>

              <div className='flex'>
                <div className="flex-none w-10" />
                <div className="grid grid-cols-6 gap-4">
                  {nfts.map((nft: any, index: number) => (
                    <NFT key={nft.asset_id} nft={nft} />
                  ))}
                </div>
                <div className="flex-none w-10" />
              </div>


              {nfts.length > 0 && (
                <div className="flex justify-center mt-8">
                  <Button
                    disabled={loadMoreLoading}
                    onClick={() =>
                      sellbotStore.loadNfts(props.session.actor, true)
                    }
                  >
                    Load More
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <p className="text-center text-white text-4xl">Please login first</p>
      )}
    </SecondaryRightContent>
  );
});

const Sellbot = wrapProviderHOC(SellbotScreen);

export { Sellbot };
