import ftConstants from "../../../../constants/farmingtales/index";
import { getTableRow } from "../../../../services/api";

export const startTransaction = async (activeUser: any, actions: any) => {
    try {
        await activeUser.transact(
            {
                actions,
            },
            {
                broadcast: true,
                blocksBehind: 3,
                expireSeconds: 30,
            }
        );
    } catch (e) {
        console.log("start transaction error", e)
    }
};

export const wait = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const harvestAnimal = async (activeUser: any, account: string, asset_id: number) => {
    let actions = [
        {
            account: ftConstants.FARMINGGAMES,
            name: "harvestanim",
            authorization: [
                {
                    actor: account,
                    permission: "active",
                },
            ],
            data: {
                account,
                asset_id,
            },
        },
    ];
    await startTransaction(activeUser, actions);
};

export const harvestBuildout = async (activeUser: any, account: string, asset_id: number) => {
    let actions = [
        {
            account: ftConstants.FARMINGGAMES,
            name: "harvestbuild",
            authorization: [
                {
                    actor: account,
                    permission: "active",
                },
            ],
            data: {
                account,
                asset_id,
            },
        },
    ];
    await startTransaction(activeUser, actions);
};

export const harvestFlower = async (activeUser: any, account: string, asset_id: number) => {
    let actions = [
        {
            account: ftConstants.FARMINGGAMES,
            name: "hvstflower",
            authorization: [
                {
                    actor: account,
                    permission: "active",
                },
            ],
            data: {
                account,
                asset_id,
            },
        },
    ];
    await startTransaction(activeUser, actions);
};

export const harvestPlant = async (activeUser: any, account: string, plant_id: number) => {
    let actions = [
        {
            account: ftConstants.FARMINGGAMES,
            name: "harvestplant",
            authorization: [
                {
                    actor: account,
                    permission: "active",
                },
            ],
            data: {
                account,
                plant_id,
            },
        },
    ];
    await startTransaction(activeUser, actions);
};

export const refillFood = async (activeUser: any, account: string) => {
    try {
        let actions = [
            {
                account: ftConstants.FARMINGGAMES,
                name: "refillfood",
                authorization: [
                    {
                        actor: account,
                        permission: "active",
                    },
                ],
                data: {
                    account,
                },
            },
        ];
        // console.log("refill food", { actions })
        await startTransaction(activeUser, actions);
    } catch (e) {
        console.log("refill food error", e)
    }
};

export const refillWater = async (activeUser: any, account: string) => {
    try {
        let actions = [
            {
                account: ftConstants.FARMINGGAMES,
                name: "refillwater",
                authorization: [
                    {
                        actor: account,
                        permission: "active",
                    },
                ],
                data: {
                    account,
                },
            },
        ];
        // console.log("refill water", { actions })
        await startTransaction(activeUser, actions);
    } catch (e) {
        console.log("refill water error", e)
    }
};

const getBalance = (balances: any, symbol_name: string) => {
    let balance = balances.find((b: any) => b.key === symbol_name);
    return balance ? Number(balance.value) : 0;
};

export const checkBarnResource = async (activeUser: any, account: string, resource_count: number, food: number, water: number) => {
    // ===== BARN ====
    let barnJson = await getTableRow({
        table: ftConstants.USERS_TABLE_GAME2,
        code: ftConstants.FARMINGGAME2,
        scope: ftConstants.FARMINGGAME2,
        lower_bound: account,
    });
    if (barnJson.rows.length === 0) {
        return false;
    }
    let barn = barnJson.rows[0];
    console.log({ barn })

    // check enough space
    if (
        Number(barn.max_capacity) - Number(barn.products_tot_sum) <
        resource_count
    ) {
        return false; // harvest next time
    }

    // ===== RESOURCES ====
    let resourcesJson = await getTableRow({
        table: ftConstants.RESOURCES_TABLE_GAME1,
        code: ftConstants.FARMINGGAMES,
        scope: ftConstants.FARMINGGAMES,
        lower_bound: account,
    });
    if (resourcesJson.rows.length === 0) {
        return false;
    }
    let resources = resourcesJson.rows[0];
    // console.log({ resources });
    if (resources.food < food || resources.water < water) {
        // check auto refill
        let walletJson = await getTableRow({
            table: ftConstants.WALLET_TABLE_GAME1,
            code: ftConstants.FARMINGGAMES,
            scope: ftConstants.FARMINGGAMES,
            lower_bound: account,
        })
        if (walletJson.rows.length === 0) {
            return false;
        }
        let wallet = walletJson.rows[0];
        let confresourceJson = await getTableRow({
            table: ftConstants.CONFRESOURCE_TABLE_GAME1,
            code: ftConstants.FARMINGGAMES,
            scope: ftConstants.FARMINGGAMES,
            lower_bound: "0",
        })
        if (confresourceJson.rows.length === 0) {
            return false;
        }
        let confresource = confresourceJson.rows[0];
        if (!confresource) return false;
        let cbit = getBalance(wallet.balances, "CBIT");
        if (resources.food < food) {
            if (cbit / 10000 < Number(confresource.food_refill_cost) / 10000) {
                return false;
            } else {
                await refillFood(activeUser, account);
                cbit -= confresource.food_refill_cost;

                await wait(5000);
                // let updated_resources = await getResouces(account);
                // if (updated_resources && updated_resources.food < food) {
                //     return false;
                // }
            }
        }
        if (resources.water < water) {
            if (cbit / 10000 < Number(confresource.water_refill_cost) / 10000) {
                return false;
            } else {
                await refillWater(activeUser, account);

                await wait(5000);
                // let updated_resources = await getResouces(account);
                // if (updated_resources && updated_resources.water < water) {
                //     return false;
                // }
            }
        }
    }

    return true;
};