import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { FC, useEffect } from "react";
import { BsArrowDown, BsArrowUp, BsInfoCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Button, Card, Checkbox, Spinner, TextInput } from '../../../components';
import parsecConstants from "../../../constants/parsec";
import { SecondaryRightContent } from "../../../layouts/SecondaryRightContent";
import wrapProviderHOC from "../../../layouts/WrapProvider";
import { parsecStore } from "./controller";

interface Props {
    session: any;
    member: any;
}
const wait = async (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

const ParsecScreen: FC<Props> = observer(({ ...props }) => {
    const store: any = getSnapshot(parsecStore);
    useEffect(() => {
        if (props?.session) {
            parsecStore.markLoading(true);
            parsecStore.loadData(String(props.session.actor));
        }

    }, [props.session]);
    let isAuto: boolean = store.isAuto;

    useEffect(() => {
        let intervalId: any;
        let working = false;
        if (props?.session) {
            let isActiveMember = props?.member.isActive;
            // let isActiveMember = true;

            const startCronJob = () => {
                intervalId = setInterval(async () => {
                    if (!working) {
                        // Your cron job logic goes here
                        working = true;
                        console.log("run auto process")
                        await parsecStore.autoBotClaim(props.session);
                        await wait(3000);
                        await parsecStore.autoBotUpgrade(props.session, isActiveMember);
                        await wait(15000);
                        working = false;
                    } else {
                        console.log("Skip");
                    }
                }, 5000); // Adjust the interval as needed (e.g., 1000 ms = 1 second)
            };

            if (isAuto) {
                // reset shipyard level to 0
                parsecStore.resetShipyardLevel();
                startCronJob();
            } else {
                clearInterval(intervalId);
            }

        }
        return () => {
            clearInterval(intervalId); // Clean up the interval when the component unmounts
        };
    }, [isAuto, props.session, props.member.isActive]);

    let isLoading: boolean = store.isLoading;


    let energyItems: any = store.energyItems;
    let resourceItems: any = store.resourceItems;
    let techItems: any = store.techItems;
    let defenceItems: any = store.defenceItems;
    let shipyardItems: any = store.shipyardItems;
    let log: string = store.log;
    let allEnergyUpgrade: boolean = store.allEnergyUpgrade;
    let allResourceUpgrade: boolean = store.allResourceUpgrade;
    let allTechUpgrade: boolean = store.allTechUpgrade;
    let allDefUpgrade: boolean = store.allDefUpgrade;
    let allShipyardUpgrade: boolean = store.allShipyardUpgrade;
    let allResClaim: boolean = store.allResClaim;


    return (
        <SecondaryRightContent>
            <div className="">
                <p className='text-2xl text-yellow-400 font-bold  text-center m-1 mb-6'>Parsec Auto</p>
                <div className='flex flex-row mb-2'>
                    <div className="flex-none w-10"></div>
                    {props?.session && (<Button
                        className=''
                        disabled={isLoading}
                        color={!isAuto ? "info" : "failure"}
                        onClick={() => {
                            parsecStore.setIsAuto(!isAuto);
                        }}
                    >{!isAuto ? "Start" : "Stop"}</Button>)}
                    {isAuto && (<div className='place-self-center ml-2 text-gray-700 dark:text-white'>{log}</div>)}
                </div>

                {props?.session ? (
                    <div className="flex ">
                        <div className="flex-none w-10">

                        </div>
                        <Card className={`grow `}>
                            <div className="text-lg text-gray-700 dark:text-teal-200 font-bold border-b-2 mb-1 mt-1">
                                <div>
                                    Energy
                                </div>
                            </div>
                            {isLoading ? <Spinner color={"success"} /> : (<table className="table-auto text-left text-gray-700 dark:text-white border border-dashed overflow-x: auto">
                                <thead >
                                    <tr>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Priority</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Name</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Level</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Upgrade Cost</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Start At</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Finish At</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 relative group'>
                                            Cap Level
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Auto Upgrade will be disabled when the item reaches this level. Set to 0 to ignore this setting.
                                                </div>
                                            </div>
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 relative group'>
                                            Pause
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Auto Upgrade will be paused for this amount of minutes after 2 failed attempts. Change any config of the item to clear pause time.
                                                </div>
                                            </div>
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Last Action</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Auto Upgrade
                                            <Checkbox
                                                className='ml-2'
                                                checked={allEnergyUpgrade}
                                                onChange={(v) => {
                                                    parsecStore.setAutoUpgradeAll(v.target.checked, parsecConstants.ENERGY_TABLE);
                                                }}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {energyItems.map((item: any) => {
                                        return (

                                            <tr key={item.name}>
                                                <td >
                                                    <button
                                                        disabled={isAuto}
                                                        type="button"
                                                        className="-m-2 p-2 text-lime-500 ml-0"
                                                        onClick={() => { parsecStore.moveResUp(item.name, parsecConstants.ENERGY_TABLE) }}
                                                    >
                                                        <BsArrowUp className="h-6 w-6" />
                                                    </button>
                                                    <button
                                                        disabled={isAuto}
                                                        type="button"
                                                        className="-m-2 p-2 text-rose-500"
                                                        onClick={() => { parsecStore.moveResDown(item.name, parsecConstants.ENERGY_TABLE) }}
                                                    >
                                                        <BsArrowDown className="h-6 w-6" />
                                                    </button>
                                                </td>
                                                <td>{item.full_name}</td>
                                                <td>{item.level}</td>
                                                <td>{item.upgrade_cost}</td>
                                                <td>{new Date(item.start_at).toLocaleDateString() + " " + new Date(item.start_at).toLocaleTimeString()}</td>
                                                <td>{new Date(item.finish_at).toLocaleDateString() + " " + new Date(item.finish_at).toLocaleTimeString()}</td>
                                                <td className='w-40'>
                                                    <div className="w-2/3 items-center">
                                                        <TextInput
                                                            disabled={isAuto}
                                                            size={4}
                                                            placeholder="0"
                                                            value={item.cap_upgrade_level}
                                                            type='number'
                                                            classNameInputText="text-center"
                                                            onChange={(e) => {
                                                                parsecStore.setCapUpgradeLevel(item.name, parseInt(e.target.value), parsecConstants.ENERGY_TABLE)
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className='w-40'>
                                                    <div className="w-2/3 items-center">
                                                        <TextInput
                                                            disabled={isAuto}
                                                            size={4}
                                                            placeholder="0"
                                                            value={item.pause_minutes}
                                                            type='number'
                                                            classNameInputText="text-center"
                                                            onChange={(e) => {
                                                                parsecStore.setPauseMinutes(item.name, parseInt(e.target.value), parsecConstants.ENERGY_TABLE)
                                                            }}
                                                        />
                                                    </div>
                                                </td>

                                                <td>
                                                    {item.last_tx ? (<a className='text-green-400 ml-1' href={`https://wax.bloks.io/transaction/${item.last_tx}`} target='_blank' rel="noreferrer" >{item.last_action} (Tx)</a>) : (<span className='text-red-400 ml-1'>No action</span>)}

                                                </td>
                                                <td >
                                                    {((isAuto && ((new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime()) || (item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0)))) ?
                                                        (<span className='text-red-400 ml-1 relative group'>Paused
                                                            <div className="w-32 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                                {new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime() && `Pause Until: ${new Date(item.pause_upgrade_until).toLocaleDateString() + " " + new Date(item.pause_upgrade_until).toLocaleTimeString()}`}
                                                                {(item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0) && `Reached Cap Upgrade Level`}
                                                            </div>
                                                        </span>) :
                                                        (<Checkbox
                                                            disabled={isAuto}
                                                            checked={item.is_auto_upgrade}
                                                            onChange={(v) => {
                                                                parsecStore.setAutoUpgrade(item.name, v.target.checked, parsecConstants.ENERGY_TABLE);
                                                            }}
                                                            name="auto-upgrade"
                                                            className='ml-2'
                                                        ></Checkbox>)}

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>)}

                            <div className="text-lg text-gray-700 dark:text-teal-200 font-bold border-b-2 mb-1 mt-2">
                                <div>
                                    Resources
                                </div>
                            </div>

                            {isLoading ? <Spinner color={"success"} /> : (<table className="table-auto text-left text-gray-700 dark:text-white border border-dashed overflow-x: auto">
                                <thead >
                                    <tr>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Priority</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Name</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Level</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Upgrade Cost</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Start At</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Finish At</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 relative group'>Cap Level
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Auto Upgrade will be disabled when the item reaches this level. Set to 0 to ignore this setting.
                                                </div>
                                            </div>

                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 relative group'>
                                            Pause
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Auto Upgrade will be paused for this amount of minutes after 2 failed attempts. Change any config of the item to clear pause time.
                                                </div>
                                            </div>
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200 relative group'>
                                            Claim
                                            <div className="relative inline-block">
                                                <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                    Claim resource after each period in minutes
                                                </div>
                                            </div>
                                            {/* <div className="absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block  transform z-50 ">
                                                
                                            </div> */}
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Last Claim</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Last Action</th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Auto Upgrade
                                            <Checkbox
                                                className='ml-2'
                                                checked={allResourceUpgrade}
                                                onChange={(v) => {
                                                    parsecStore.setAutoUpgradeAll(v.target.checked, parsecConstants.RESOURCES_TABLE);
                                                }}
                                            />
                                        </th>
                                        <th className='text-gray-700 dark:text-fuchsia-200'>Auto Claim
                                            <Checkbox
                                                className='ml-2'
                                                checked={allResClaim}
                                                onChange={(v) => {
                                                    parsecStore.setAutoClaimAll(v.target.checked, parsecConstants.RESOURCES_TABLE);
                                                }}
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resourceItems.map((item: any) => {
                                        return (

                                            <tr key={item.name}>
                                                <td >
                                                    <button
                                                        disabled={isAuto}
                                                        type="button"
                                                        className="-m-2 p-2 text-lime-500 ml-0"
                                                        onClick={() => { parsecStore.moveResUp(item.name, parsecConstants.RESOURCES_TABLE) }}
                                                    >
                                                        <BsArrowUp className="h-6 w-6" />
                                                    </button>
                                                    <button
                                                        disabled={isAuto}
                                                        type="button"
                                                        className="-m-2 p-2 text-rose-500"
                                                        onClick={() => { parsecStore.moveResDown(item.name, parsecConstants.RESOURCES_TABLE) }}
                                                    >
                                                        <BsArrowDown className="h-6 w-6" />
                                                    </button>
                                                </td>
                                                <td>{item.full_name}</td>
                                                <td>{item.level}</td>
                                                <td>{item.upgrade_cost}</td>
                                                <td>{new Date(item.start_at).toLocaleDateString() + " " + new Date(item.start_at).toLocaleTimeString()}</td>
                                                <td>{new Date(item.finish_at).toLocaleDateString() + " " + new Date(item.finish_at).toLocaleTimeString()}</td>
                                                <td className='w-32'>
                                                    <div className="w-2/3 items-center">
                                                        <TextInput
                                                            disabled={isAuto}
                                                            size={4}
                                                            placeholder="0"
                                                            value={item.cap_upgrade_level}
                                                            type='number'
                                                            classNameInputText="text-center"
                                                            onChange={(e) => {
                                                                parsecStore.setCapUpgradeLevel(item.name, parseInt(e.target.value), parsecConstants.RESOURCES_TABLE)
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className='w-32'>
                                                    <div className="w-2/3 items-center">
                                                        <TextInput
                                                            disabled={isAuto}
                                                            size={4}
                                                            placeholder="0"
                                                            value={item.pause_minutes}
                                                            type='number'
                                                            classNameInputText="text-center"
                                                            onChange={(e) => {
                                                                parsecStore.setPauseMinutes(item.name, parseInt(e.target.value), parsecConstants.RESOURCES_TABLE)
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className='w-32'>
                                                    <div className="w-2/3 items-center">
                                                        <TextInput
                                                            disabled={isAuto}
                                                            size={4}
                                                            placeholder="0"
                                                            value={item.claim_frequency}
                                                            type='number'
                                                            classNameInputText="text-center"
                                                            onChange={(e) => {
                                                                parsecStore.setClaimFrequency(item.name, parseInt(e.target.value), parsecConstants.RESOURCES_TABLE)
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>{new Date(item.last_claim_at).toLocaleDateString() + " " + new Date(item.last_claim_at).toLocaleTimeString()}</td>
                                                <td>
                                                    {item.last_tx ? (<a className='text-green-400 ml-1' href={`https://wax.bloks.io/transaction/${item.last_tx}`} target='_blank' rel="noreferrer" >{item.last_action} (Tx)</a>) : (<span className='text-red-400 ml-1'>No action</span>)}

                                                </td>
                                                <td >
                                                    {((isAuto && ((new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime()) || (item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0)))) ?
                                                        (<span className='text-red-400 ml-1 relative group'>Paused
                                                            <div className="w-32 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                                {new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime() && `Pause Until: ${new Date(item.pause_upgrade_until).toLocaleDateString() + " " + new Date(item.pause_upgrade_until).toLocaleTimeString()}`}
                                                                {(item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0) && `Reached Cap Upgrade Level`}
                                                            </div>
                                                        </span>) :
                                                        (<Checkbox
                                                            disabled={isAuto}
                                                            checked={item.is_auto_upgrade}
                                                            onChange={(v) => {
                                                                parsecStore.setAutoUpgrade(item.name, v.target.checked, parsecConstants.RESOURCES_TABLE);
                                                            }}
                                                            name="auto-upgrade"
                                                            className='ml-2'
                                                        ></Checkbox>)}

                                                </td>
                                                <td >
                                                    <Checkbox
                                                        disabled={isAuto}
                                                        checked={item.is_auto_claim}
                                                        onChange={(v) => {
                                                            parsecStore.setAutoClaimRes(item.name, v.target.checked, parsecConstants.RESOURCES_TABLE);
                                                        }}
                                                        name="Auto Claim"
                                                        className='ml-2'
                                                    ></Checkbox>
                                                </td>
                                            </tr>

                                        )
                                    })}
                                </tbody>
                            </table>)}

                            <div className="text-lg text-gray-700 dark:text-teal-200 font-bold border-b-2 mb-1 mt-2">
                                <div>
                                    Research
                                </div>
                            </div>

                            {isLoading ? <Spinner color={"success"} /> : (
                                <div className="relative">
                                    <table className="table-auto w-full text-left text-gray-700 dark:text-white border border-dashed overflow-x: auto">
                                        <thead >
                                            <tr>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Priority</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Name</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Level</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Upgrade Cost</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Start At</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Finish At</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200 relative group'>Cap Level
                                                    <div className="relative inline-block">
                                                        <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                            Auto Upgrade will be disabled when the item reaches this level. Set to 0 to ignore this setting.
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className='text-gray-700 dark:text-fuchsia-200 relative group'>
                                                    Pause minutes
                                                    <div className="relative inline-block">
                                                        <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                            Auto Upgrade will be paused for this amount of minutes after 2 failed attempts. Change any config of the item to clear pause time.
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Last Action</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Auto Upgrade
                                                    <Checkbox
                                                        className='ml-2'
                                                        checked={allTechUpgrade}
                                                        onChange={(v) => {
                                                            parsecStore.setAutoUpgradeAll(v.target.checked, parsecConstants.TECHNOLOGY_TABLE);
                                                        }}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        {/* <tbody className={`${!props.member.isActive && "blur-sm"}`}> */}
                                        <tbody >
                                            {techItems.map((item: any) => {
                                                return (

                                                    <tr key={item.name}>
                                                        <td >
                                                            <button
                                                                disabled={isAuto}
                                                                type="button"
                                                                className="-m-2 p-2 text-lime-500 ml-0"
                                                                onClick={() => { parsecStore.moveResUp(item.name, parsecConstants.TECHNOLOGY_TABLE) }}
                                                            >
                                                                <BsArrowUp className="h-6 w-6" />
                                                            </button>
                                                            <button
                                                                disabled={isAuto}
                                                                type="button"
                                                                className="-m-2 p-2 text-rose-500"
                                                                onClick={() => { parsecStore.moveResDown(item.name, parsecConstants.TECHNOLOGY_TABLE) }}
                                                            >
                                                                <BsArrowDown className="h-6 w-6" />
                                                            </button>
                                                        </td>
                                                        <td>{item.full_name}</td>
                                                        <td>{item.level}</td>
                                                        <td>{item.upgrade_cost}</td>
                                                        <td>{new Date(item.start_at).toLocaleDateString() + " " + new Date(item.start_at).toLocaleTimeString()}</td>
                                                        <td>{new Date(item.finish_at).toLocaleDateString() + " " + new Date(item.finish_at).toLocaleTimeString()}</td>
                                                        <td className='w-40'>
                                                            <div className="w-2/3 items-center">
                                                                <TextInput
                                                                    disabled={isAuto}
                                                                    size={4}
                                                                    placeholder="0"
                                                                    value={item.cap_upgrade_level}
                                                                    type='number'
                                                                    classNameInputText="text-center"
                                                                    onChange={(e) => {
                                                                        parsecStore.setCapUpgradeLevel(item.name, parseInt(e.target.value), parsecConstants.TECHNOLOGY_TABLE)
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>

                                                        <td className='w-40'>
                                                            <div className="w-2/3 items-center">
                                                                <TextInput
                                                                    disabled={isAuto}
                                                                    size={4}
                                                                    placeholder="0"
                                                                    value={item.pause_minutes}
                                                                    type='number'
                                                                    classNameInputText="text-center"
                                                                    onChange={(e) => {
                                                                        parsecStore.setPauseMinutes(item.name, parseInt(e.target.value), parsecConstants.TECHNOLOGY_TABLE)
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {item.last_tx ? (<a className='text-green-400 ml-1' href={`https://wax.bloks.io/transaction/${item.last_tx}`} target='_blank' rel="noreferrer" >{item.last_action} (Tx)</a>) : (<span className='text-red-400 ml-1'>No action</span>)}

                                                        </td>
                                                        <td >
                                                            {((isAuto && ((new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime()) || (item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0)))) ?
                                                                (<span className='text-red-400 ml-1 relative group'>Paused
                                                                    <div className="w-32 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                                        {new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime() && `Pause Until: ${new Date(item.pause_upgrade_until).toLocaleDateString() + " " + new Date(item.pause_upgrade_until).toLocaleTimeString()}`}
                                                                        <br />
                                                                        {(item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0) && `Reached Cap Upgrade Level`}
                                                                    </div>
                                                                </span>) : (<Checkbox
                                                                    disabled={isAuto}
                                                                    checked={item.is_auto_upgrade}
                                                                    onChange={(v) => {
                                                                        parsecStore.setAutoUpgrade(item.name, v.target.checked, parsecConstants.TECHNOLOGY_TABLE);
                                                                    }}
                                                                    name="auto-upgrade"
                                                                    className='ml-2'
                                                                ></Checkbox>)}

                                                        </td>
                                                    </tr>

                                                )
                                            })}

                                        </tbody>

                                    </table>

                                    {/* {
                                        !props.member.isActive && (<div className="absolute inset-0 bg-gray-800 bg-opacity-50 z-10 flex items-start justify-center pt-16"
                                        >
                                            <Link className="bg-white bg-opacity-50 rounded-lg p-5"
                                                to="/prime-membership">
                                                <p className="text-white text-2xl font-bold">
                                                    Let's be our Prime Membership to use full features!
                                                </p>
                                            </Link>
                                        </div>)
                                    } */}
                                </div>)}
                            {/* </div> */}

                            <div className="text-lg text-gray-700 dark:text-teal-200 font-bold border-b-2 mb-1 mt-2">
                                <div>
                                    Defence
                                </div>
                            </div>

                            {isLoading ? <Spinner color={"success"} /> : (
                                <div className="relative">
                                    <table className="table-auto w-full text-left text-gray-700 dark:text-white border border-dashed overflow-x: auto">
                                        <thead >
                                            <tr>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Priority</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Name</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Build Cost</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Start At</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Finish At</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200 relative group'>Cap Qty
                                                    <div className="relative inline-block">
                                                        <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                            Auto Build will be disabled when the item reaches this cap. And it'll be reset when you start/stop auto. Set to 0 to ignore this setting.
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className='text-gray-700 dark:text-fuchsia-200 relative group'>
                                                    Pause minutes
                                                    <div className="relative inline-block">
                                                        <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                            Auto Upgrade will be paused for this amount of minutes after 2 failed attempts. Change any config of the item to clear pause time.
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Last Action</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Auto Build
                                                    <Checkbox
                                                        className='ml-2'
                                                        checked={allDefUpgrade}
                                                        onChange={(v) => {
                                                            parsecStore.setAutoUpgradeAll(v.target.checked, parsecConstants.DEFENCE_TABLE);
                                                        }}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={`${!props?.member.isActive && "blur-sm"}`}>
                                            {/* <tbody > */}
                                            {defenceItems.map((item: any) => {
                                                return (

                                                    <tr key={item.name}>
                                                        <td >
                                                            <button
                                                                disabled={isAuto}
                                                                type="button"
                                                                className="-m-2 p-2 text-lime-500 ml-0"
                                                                onClick={() => { parsecStore.moveResUp(item.name, parsecConstants.DEFENCE_TABLE) }}
                                                            >
                                                                <BsArrowUp className="h-6 w-6" />
                                                            </button>
                                                            <button
                                                                disabled={isAuto}
                                                                type="button"
                                                                className="-m-2 p-2 text-rose-500"
                                                                onClick={() => { parsecStore.moveResDown(item.name, parsecConstants.DEFENCE_TABLE) }}
                                                            >
                                                                <BsArrowDown className="h-6 w-6" />
                                                            </button>
                                                        </td>
                                                        <td>{item.full_name}</td>
                                                        <td>{item.upgrade_cost}</td>
                                                        <td>{new Date(item.start_at).toLocaleDateString() + " " + new Date(item.start_at).toLocaleTimeString()}</td>
                                                        <td>{new Date(item.finish_at).toLocaleDateString() + " " + new Date(item.finish_at).toLocaleTimeString()}</td>
                                                        <td className='w-40'>
                                                            <div className="w-2/3 items-center">
                                                                <TextInput
                                                                    disabled={isAuto}
                                                                    size={4}
                                                                    placeholder="0"
                                                                    value={item.cap_upgrade_level}
                                                                    type='number'
                                                                    classNameInputText="text-center"
                                                                    onChange={(e) => {
                                                                        parsecStore.setCapUpgradeLevel(item.name, parseInt(e.target.value), parsecConstants.DEFENCE_TABLE)
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className='w-40'>
                                                            <div className="w-2/3 items-center">
                                                                <TextInput
                                                                    disabled={isAuto}
                                                                    size={4}
                                                                    placeholder="0"
                                                                    value={item.pause_minutes}
                                                                    type='number'
                                                                    classNameInputText="text-center"
                                                                    onChange={(e) => {
                                                                        parsecStore.setPauseMinutes(item.name, parseInt(e.target.value), parsecConstants.DEFENCE_TABLE)
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {item.last_tx ? (<a className='text-green-400 ml-1' href={`https://wax.bloks.io/transaction/${item.last_tx}`} target='_blank' rel="noreferrer" >{item.last_action} (Tx)</a>) : (<span className='text-red-400 ml-1'>No action</span>)}

                                                        </td>
                                                        <td >
                                                            {((isAuto && ((new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime()) || (item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0)))) ?
                                                                (<span className='text-red-400 ml-1 relative group'>Paused
                                                                    <div className="w-32 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                                        {new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime() && `Pause Until: ${new Date(item.pause_upgrade_until).toLocaleDateString() + " " + new Date(item.pause_upgrade_until).toLocaleTimeString()}`}
                                                                        <br />
                                                                        {(item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0) && `Reached Cap Upgrade Level`}
                                                                    </div>
                                                                </span>) : (<Checkbox
                                                                    disabled={isAuto}
                                                                    checked={item.is_auto_upgrade}
                                                                    onChange={(v) => {
                                                                        parsecStore.setAutoUpgrade(item.name, v.target.checked, parsecConstants.DEFENCE_TABLE);
                                                                    }}
                                                                    name="auto-upgrade"
                                                                    className='ml-2'
                                                                ></Checkbox>)}

                                                        </td>
                                                    </tr>

                                                )
                                            })}

                                        </tbody>

                                    </table>

                                    {
                                        !props?.member.isActive && (<div className="absolute inset-0 bg-gray-800 bg-opacity-50 z-10 flex items-start justify-center pt-16"
                                        >
                                            <Link className="bg-white bg-opacity-50 rounded-lg p-5"
                                                to="/prime-membership">
                                                <p className="text-white text-2xl font-bold">
                                                    Let's be our Prime Membership to use full features!
                                                </p>
                                            </Link>
                                        </div>)
                                    }
                                </div>)}
                            {/* </div> */}

                            <div className="text-lg text-gray-700 dark:text-teal-200 font-bold border-b-2 mb-1 mt-2">
                                <div>
                                    Shipyard
                                </div>
                            </div>

                            {isLoading ? <Spinner color={"success"} /> : (
                                <div className="relative">
                                    <table className="table-auto w-full text-left text-gray-700 dark:text-white border border-dashed overflow-x: auto">
                                        <thead >
                                            <tr>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Priority</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Name</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Build Cost</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Start At</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Finish At</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200 relative group'>Cap Qty
                                                    <div className="relative inline-block">
                                                        <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                            Auto Build will be disabled when the item reaches this cap. And it'll be reset when you start/stop auto. Set to 0 to ignore this setting.
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className='text-gray-700 dark:text-fuchsia-200 relative group'>
                                                    Pause minutes
                                                    <div className="relative inline-block">
                                                        <BsInfoCircle className="text-blue-500 ml-1 cursor-pointer hover:text-blue-600 transition-colors duration-300" />
                                                        <div className="w-64 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                            Auto Upgrade will be paused for this amount of minutes after 2 failed attempts. Change any config of the item to clear pause time.
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Last Action</th>
                                                <th className='text-gray-700 dark:text-fuchsia-200'>Auto Build
                                                    <Checkbox
                                                        className='ml-2'
                                                        checked={allShipyardUpgrade}
                                                        onChange={(v) => {
                                                            parsecStore.setAutoUpgradeAll(v.target.checked, parsecConstants.SHIPYARD_TABLE);
                                                        }}
                                                    />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className={`${!props?.member.isActive && "blur-sm"}`}>
                                            {/* <tbody > */}
                                            {shipyardItems.map((item: any) => {
                                                return (

                                                    <tr key={item.name}>
                                                        <td >
                                                            <button
                                                                disabled={isAuto}
                                                                type="button"
                                                                className="-m-2 p-2 text-lime-500 ml-0"
                                                                onClick={() => { parsecStore.moveResUp(item.name, parsecConstants.SHIPYARD_TABLE) }}
                                                            >
                                                                <BsArrowUp className="h-6 w-6" />
                                                            </button>
                                                            <button
                                                                disabled={isAuto}
                                                                type="button"
                                                                className="-m-2 p-2 text-rose-500"
                                                                onClick={() => { parsecStore.moveResDown(item.name, parsecConstants.SHIPYARD_TABLE) }}
                                                            >
                                                                <BsArrowDown className="h-6 w-6" />
                                                            </button>
                                                        </td>
                                                        <td>{item.full_name}</td>
                                                        <td>{item.upgrade_cost}</td>
                                                        <td>{new Date(item.start_at).toLocaleDateString() + " " + new Date(item.start_at).toLocaleTimeString()}</td>
                                                        <td>{new Date(item.finish_at).toLocaleDateString() + " " + new Date(item.finish_at).toLocaleTimeString()}</td>
                                                        <td className='w-40'>
                                                            <div className="w-2/3 items-center">
                                                                <TextInput
                                                                    disabled={isAuto}
                                                                    size={4}
                                                                    placeholder="0"
                                                                    value={item.cap_upgrade_level}
                                                                    type='number'
                                                                    classNameInputText="text-center"
                                                                    onChange={(e) => {
                                                                        parsecStore.setCapUpgradeLevel(item.name, parseInt(e.target.value), parsecConstants.SHIPYARD_TABLE)
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className='w-40'>
                                                            <div className="w-2/3 items-center">
                                                                <TextInput
                                                                    disabled={isAuto}
                                                                    size={4}
                                                                    placeholder="0"
                                                                    value={item.pause_minutes}
                                                                    type='number'
                                                                    classNameInputText="text-center"
                                                                    onChange={(e) => {
                                                                        parsecStore.setPauseMinutes(item.name, parseInt(e.target.value), parsecConstants.SHIPYARD_TABLE)
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>

                                                        <td>
                                                            {item.last_tx ? (<a className='text-green-400 ml-1' href={`https://wax.bloks.io/transaction/${item.last_tx}`} target='_blank' rel="noreferrer" >{item.last_action} (Tx)</a>) : (<span className='text-red-400 ml-1'>No action</span>)}

                                                        </td>
                                                        <td >
                                                            {((isAuto && ((new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime()) || (item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0)))) ?
                                                                (<span className='text-red-400 ml-1 relative group'>Paused
                                                                    <div className="w-32 absolute hidden bg-gray-600 border border-grey-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 rounded-md p-2 mt-1 group-hover:block transform z-50">
                                                                        {new Date(item.pause_upgrade_until).getTime() > new Date(Date.now()).getTime() && `Pause Until: ${new Date(item.pause_upgrade_until).toLocaleDateString() + " " + new Date(item.pause_upgrade_until).toLocaleTimeString()}`}
                                                                        <br />
                                                                        {(item.level >= item.cap_upgrade_level && item.cap_upgrade_level !== 0) && `Reached Cap Upgrade Level`}
                                                                    </div>
                                                                </span>) : (<Checkbox
                                                                    disabled={isAuto}
                                                                    checked={item.is_auto_upgrade}
                                                                    onChange={(v) => {
                                                                        parsecStore.setAutoUpgrade(item.name, v.target.checked, parsecConstants.SHIPYARD_TABLE);
                                                                    }}
                                                                    name="auto-upgrade"
                                                                    className='ml-2'
                                                                ></Checkbox>)}

                                                        </td>
                                                    </tr>

                                                )
                                            })}

                                        </tbody>

                                    </table>

                                    {
                                        !props?.member.isActive && (<div className="absolute inset-0 bg-gray-800 bg-opacity-50 z-10 flex items-start justify-center pt-16"
                                        >
                                            <Link className="bg-white bg-opacity-50 rounded-lg p-5"
                                                to="/prime-membership">
                                                <p className="text-white text-2xl font-bold">
                                                    Let's be our Prime Membership to use full features!
                                                </p>
                                            </Link>
                                        </div>)
                                    }
                                </div>)}
                            {/* </div> */}

                        </Card>

                        <div className="flex-none w-10">

                        </div>

                        {/* <Card>


                    </Card> */}
                    </div>) :
                    (<div className="flex justify-center items-center h-96 text-gray-700 dark:text-white">
                        Please login to use this feature
                    </div>)}


            </div>
        </SecondaryRightContent >
    );
});

const Parsec = wrapProviderHOC(ParsecScreen);
export { Parsec };

