

import { flow, types } from "mobx-state-tree";
import constants from '../../../constants/index';
import { getTableRow, getTableRows } from '../../../services/api';
import { MembershipItem } from '../domain/membership';
import { TierItem } from '../domain/tierItem';

const Subscription = types
    .model("Subscription", {
        isLoading: true,
        tiers: types.array(TierItem),
        member: MembershipItem
    })
    .views((self) => ({

    }))
    .actions((self) => {
        function markLoading(loading: boolean) {
            self.isLoading = loading
        }

        function updateTiers(json: any) {
            self.tiers = json.rows;
        }

        function updateMember(json: any) {
            let member_info = Object.assign({}, json.rows[0]);
            if (member_info.wallet) {
                member_info.isActive = Date.parse(member_info.expired_at + "Z") > Date.now() ? true : false;
            } else {
                member_info = {
                    wallet: '',
                    tier_name: '',
                    expired_at: '',
                    isActive: false
                }
            }
            self.member = member_info;
        }

        // get tier from on-chain table row
        const loadTiers = flow(function* () {

            try {
                const json = yield getTableRows({
                    table: constants.WAXIMUS_CENTRAL.TIERS_TABLE,
                    code: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                    scope: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                })

                updateTiers(json)
                markLoading(false)
            } catch (err) {
                console.log(err)
                markLoading(false)
            }
        });


        // get member from on-chain table row
        const loadMember = flow(function* (wallet: string) {
            try {
                const json = yield getTableRow({
                    table: constants.WAXIMUS_CENTRAL.MEMBER_TABLE,
                    code: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                    scope: constants.WAXIMUS_CENTRAL.CENTRAL_ACCOUNT,
                    lower_bound: wallet,
                })
                updateMember(json)
                markLoading(false)
            } catch (err) {
                console.log(err)
                markLoading(false)
            }
        });

        return {
            loadTiers,
            loadMember,
        }
    })


export const subStore = Subscription.create({
    isLoading: true,
    tiers: [],
    member: { wallet: "", tier_name: "", expired_at: "", isActive: false }
})