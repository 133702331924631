import { types } from "mobx-state-tree";

export const Stock = types.model("Stock", {
    item_name: types.string,
    media: types.string,
    quantity: types.number,
    template_id: types.number,
    unit_price: types.string,
    rarity: types.string,
})
