import parsecConstants from "../../../constants/parsec";
const get_upgrade_nucl_cost_by_res = (current_level: number, resource: string) => {
    return "titanium" === resource ? 300 * Math.pow(1.3, current_level) : "ruthenium" === resource ? 120 * Math.pow(1.3, current_level) : 60 * Math.pow(1.2, 2 * current_level);
};

const get_upgrade_res_cost_by_res = (current_level: number, target_resource: string, resource: string, engineer_buff: number, researcher_buff: number) => {
    var cost = 0;
    return cost = "titanium" === target_resource ? "titanium" === resource ? 18 * Math.pow(1.5, current_level) : 4 * Math.pow(1.5, current_level) : "ruthenium" === target_resource ? "titanium" === resource ? 16 * Math.pow(1.5, current_level) : 4 * Math.pow(1.5, current_level) : "helium" === target_resource ? "titanium" === resource ? 80 * Math.pow(1.5, current_level) : 20 * Math.pow(1.5, current_level) : "titanium" === resource ? 19 * Math.pow(1.5, current_level) : 5 * Math.pow(1.5, current_level),
        apply_buff_comm(cost, target_resource, engineer_buff, researcher_buff);
};

const apply_buff_comm = (cost: number, target_resource: string, engineer_buff: number, researcher_buff: number) => {
    "titanium" === target_resource || "ruthenium" === target_resource ? engineer_buff > 0 && (cost *= (100 + engineer_buff) / 100) : researcher_buff > 0 && (cost *= (100 + researcher_buff) / 100);
    return cost
}

export const getNuclUpgradeCosts = (current_level: number) => {
    let upgrade_costs = ["titanium", "ruthenium", "helium"].map((resource) => {
        return {
            upgradeCost: Math.floor(get_upgrade_nucl_cost_by_res(current_level, resource)),
            upgradeCostType: resource === "titanium" ? "Titan" : resource === "ruthenium" ? "Ruthen" : "Helium"
        };
    });
    return upgrade_costs;

};

export const getSolarUpgradeCosts = () => {
    let upgrade_costs = [];
    upgrade_costs.push({
        upgradeCost: parsecConstants.PANEL_COSTS.PANEL_COST_TIT,
        upgradeCostType: "Titan"
    });
    upgrade_costs.push({
        upgradeCost: parsecConstants.PANEL_COSTS.PANEL_COST_RUT,
        upgradeCostType: "Ruthen"
    });
    return upgrade_costs;
};

export const getResUpgradeCosts = (current_level: number, target_resource: string, engineer_buff: number, researcher_buff: number) => {
    let upgrade_costs = ["titanium", "ruthenium"].map((resource) => {
        return {
            upgradeCost: Math.floor(get_upgrade_res_cost_by_res(current_level, target_resource, resource, engineer_buff, researcher_buff)),
            upgradeCostType: resource === "titanium" ? "Titan" : "Ruthen"
        };
    });
    return upgrade_costs;

};

export const getTechUpgradeCost = (current_level: number, base_cost: number, faction: string, professor_buff: number,) => {
    var r = Math.pow(2, current_level) * base_cost;
    ("rebels" === faction && (r *= .9),
        0 !== professor_buff) && (r *= (100 + professor_buff) / 100);
    return r
};

export const getBuildDefCost = (base_cost: number, faction: string, general_buff: number,) => {
    let cost = base_cost;
    ("empire" === faction && (cost *= .94),
        0 !== general_buff) && (cost *= (100 + general_buff) / 100);
    return cost;
};

export const getBuildShipCost = (base_cost: number, faction: string, admiral_buff: number,) => {
    let cost = base_cost;
    ("empire" === faction && (cost *= .94),
        0 !== admiral_buff) && (cost *= (100 + admiral_buff) / 100);
    return cost;
};

export const getCooldownSeconds = (titanium: any, ruthenium: any, helium: any, android_level: any, universe_speed: any, type: any) => {
    return "building" === type ? 3600 * (titanium + ruthenium) / (2500 * (1 + android_level) * universe_speed) : "technology" === type ? 3600 * (titanium + ruthenium) / (1e3 * (1 + android_level) * universe_speed) : 3600 * (titanium + ruthenium + helium / 2) / (1e3 * (1 + android_level) * universe_speed)
};


