// Multi kiosks screen: show all kiosks and user can select one to go to the kiosk screen
// Path: src/features/taco/presentation/multiKiosks/multiKiosks.screen.tsx
// Compare this snippet from src/features/taco/presentation/kiosk/kiosk.screen.tsx:
import { observer } from "mobx-react-lite";
import { getSnapshot } from 'mobx-state-tree';
import { FC, useEffect } from "react";
import LazyLoad from 'react-lazy-load';
import { Link } from "react-router-dom";
import { Card } from '../../../../components';
import { SecondaryRightContent } from '../../../../layouts/SecondaryRightContent';
import { MultiKiosksStore } from "./multiKiosks.controller";

const Loading = () => (
    <div className="post loading">
        <h5>Loading...</h5>
    </div>
);

interface GlobalProps {
    ual: any;
}

const MultiKiosks: FC<GlobalProps> = observer(({ ...props }) => {
    const store: any = getSnapshot(MultiKiosksStore);
    useEffect(() => {
        MultiKiosksStore.loadKiosks();
    }, []);
    let items: any = store.kiosks;

    return (
        <SecondaryRightContent>
            <div className="">
                <div className="c">
                    <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:gap-x-8 md:p-16 p-4">
                        {items.map((item: any) => {
                            return (
                                <LazyLoad
                                    key={item?.kiosk_id}
                                    height={500}
                                >
                                    <Link className="lg:max-w-sm relative" key={item?.kiosk_id} to={`/taco/kiosks/${item?.kiosk_id}`} onClick={() => { MultiKiosksStore.updateSelectedKiosk(item) }} >
                                        <Card
                                            imgSrc={require(`../../../../resources/images/${item?.kiosk_id}.png`)}
                                        >
                                            <div className="text-lg text-gray-700 dark:text-white font-bold text-center">
                                                <div>
                                                    <span
                                                        aria-hidden="true"
                                                        className="absolute inset-0"
                                                    ></span>
                                                    {item.kiosk_name}
                                                </div>
                                                <div></div>
                                            </div>
                                        </Card>
                                    </Link>
                                </LazyLoad>
                            );
                        })}
                    </div>
                </div>


            </div>
            {/* <Link key={"kiosk"} to={`/taco/kiosks/123`}>Open kiosk </Link> */}
        </SecondaryRightContent >
    );
});

export { MultiKiosks };

