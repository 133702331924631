import { types } from "mobx-state-tree";

export const BarnProduct = types.model("FarmingTales", {
    key: types.string,
    value: types.number,
    sell: types.boolean,
    craft1: types.boolean,
    craft2: types.boolean,
    craft3: types.boolean,
})
